/* eslint-disable max-len */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import SVGIcon from '../../../../../assets/images/svg/SVGIcon';
import {
  Group,
  LeftBar,
  Header,
  HeaderBlock,
  HeaderTitle,
  IconPoint,
  Activity,
  ActivityTitle,
  ItemBlock,
  ItemChange,
  SubTitle,
  ActivityContentView,
  ActivitySubContent,
  SubText,
  LeftBarView,
  Projectname,
  OverLayDiv,
  TaskDetailContainer,
  TaskContainer,
  RightSection,
  ReactionDiv,
  Portion,
  ReactionShow,
  Undomessage,
  FileSection,
  Reactiondiv,
  Editicon,
  Div,
  Div2,
  Borderdiv,
  Statusicon,
  Text,
  Replyicon,
  Svgfile,
  BackIcon,
  Icons,
  ActivityView,
  FileIconDiv,
  FileType
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  addReactionOnCommect,
  createNewComment,
  getFigmaPreviewUrl,
  getMyTaskDetailsData,
  getSubTasksList,
  getTaskGroupList,
  getTimelogData,
  updateTaskComment,
  updateTaskDetails
} from '../../../../../services/taskServices';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { StatusListInterface } from '../../../../../interfaces/ProjectInterface';
import { getCurrentProjectDetails, getMilestoneList, getProjectFiles } from '../../../../../services/projectServices';
import {
  floatToHHMM,
  isEmpty,
  convertBase64ToFile,
  getFirebaseUrlFromFile,
  categorizeFiles,
  isMediaRecordingSupported,
  getUTCDate,
  extensionName,
  isEmptyMessage
} from '../../../../../helpers/common';
import Avatar from '../../../../../component/avatar/Avatar';
import UserPreferenceSingleton from '../../../../../helpers/userPreferenceSingleton';
import {
  clearCreateTaskInput,
  clearMyTaskDetailsData,
  clearSubTaskList,
  setCreateTaskInput,
  setProjectTaskGroupsList,
  setTaskGroupsList,
  setTimelogData,
  updatSubTaskList,
  updateCreateTaskInput,
  updateMyTaskDetailsData,
  updateTaskCommentItem
} from '../../../../../actions/taskActions';
import moment from 'moment';
import { ConfigProvider, Drawer } from 'antd';
import {
  addTimeLogDetails,
  getActiveTime,
  startTracker,
  updateTimeLogDetails
} from '../../../../../services/timeTrackingServices';
import Deletemodal from '../../../../../component/models/deleteModel';
import CreateTaskModal from '../../../../../component/models/createTaskModal';
import { nanoid } from 'nanoid';
import {
  COMMENT_TYPE,
  DRAFT_MESSAGE_TYPE,
  dropdownMenuItems,
  FILE_EXTENTIONS,
  FILE_TYPES,
  LINK_FILE_DATA,
  MENU_OPTIONS,
  STATUS_TYPE,
  TASK_PRIORITY,
  TASK_PRIORITY_LABELS
} from '../../../../../global/constants';
import ModalCustom from '../../../../../component/models/modal';
import { setErrorMessage, setSuccessMessage } from '../../../../../actions/messageActions';
import Commentmodal from '../../../../../component/models/commentmodal/Commentmodal';
import { setMilestoneList, setProjectAllFiles, setProjectFiles } from '../../../../../actions/projectActions';
import { EmojiClickData } from 'emoji-picker-react';
import {
  CommentFilesInterface,
  CommentReactionsInterface,
  DataUpdatedCommentsInterface,
  TaskDetailsInterface,
  UpdateCommentReducer
} from '../../../../../interfaces/TaskInterface';
import MilestoneModal from '../../../../../component/models/milestoneModal';
import { trackAnalyticActivity } from '../../../../../services/analyticsService';
import RenderHtml from '../../../../../component/renderHtml';
import RenderVideoFile from '../../../../../component/renderVideoFile';
import ConfirmationModal from '../../../../../component/models/confirmationModal';
import { captureException } from '../../../../../services/logService';
import { RootReducerInterface } from '../../../../../interfaces/RootReducerInterface';
import TasksCacheService from '../../../../../services/tasksCatchServices';
import SnapRecordModal from '../../../../../component/models/snapRecordModal';
import { useMobileDevice } from '../../../../../global/useMobile';
import RenderExistingFile from '../../../../../component/renderExistingFile';
import { ReplyMessageDataInterface } from '../../../../../interfaces/MessageInterface';
import RightBlock from './rightBlock';
import SubtaskPopup from '../../../../../component/subTaskPopup';
import AppLoading from '../../../../../component/loading/appLoading';
import Emoji from '../../../../../component/emojiPicker/emojiPicker';
import CommentInputBox from '../../../../../component/inputBox/commentInputBox';
import { TASK_ANALYTICS } from '../../../../../global/analyticsConstants';
import { RenderFilePreview } from '../../../../../component/renderFilePreview';
import copy from 'clipboard-copy';
import TaskInfo from './taskInfo';
import { Dropdown, DropdownItem } from '../../../../../component/Dropdown';
import { RenderImagePreview } from '../../../../../component/renderImagePreview';
import FileSVGIcon from '../../../../../assets/images/svg/filesIconSvg';
import DraftMessageCacheService from '../../../../../services/draftMessageCacheService';
import { LinkFileModal } from '../../../../../component/models/linkFileModal';
import Tooltip from '../../../../../component/Tooltip';
interface Props {
  taskId?: string;
  projectId?: string;
  isTaskDetailPreview?: boolean;
  onClosePreviewModal?: () => void;
}

const TaskDetail: React.FC<Props> = (props) => {
  const { isTaskDetailPreview, onClosePreviewModal } = props;

  // Refs
  const inlineScrollRef = useRef<HTMLDivElement>(null);
  const buttonDropdownRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  const inlineRef = useRef<any>(null);
  const emojiButtonRef = useRef<React.RefObject<HTMLDivElement>[]>([]);

  // States
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<CommentFilesInterface[]>([]);
  const [hoveredReaction, setHoveredReaction] = useState<string>();
  const [comment, setComment] = useState<string>('');
  const [alertModelOpen, setAlertModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alertLoading, setAlertLoading] = useState(false);
  const [isEstimateDropdownOpen, setIsEstimateDropdownOpen] = useState(false);
  const [isOpenComment, setIsOpenComment] = useState(false);
  const [attachment, setAttachment] = useState(false);
  const [selectedMesaage, setSelectedMessage] = useState<string | null>(null);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [figmaRefreshLoading, setFigmaRefreshLoading] = useState(false);
  const [deletedMessage, setDeletedMessage] = useState<DataUpdatedCommentsInterface[]>([]);
  const [isProjectChanged, setIsProjectChanged] = useState(false);
  const [isMilestonemodelopen, setIsMilestonmodelopen] = useState(false);
  const [deleteModelopen, setDeleteModelopen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isFromSubList, setIsFromSubList] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState('');
  const [parentTask, setParentTask] = useState<TaskDetailsInterface>();
  const [isConfirmationModelOpen, setIsConfirmationModelOpen] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [status, setStatus] = useState<StatusListInterface>();
  const [previousStatus, setPreviousStatus] = useState<StatusListInterface>();
  const [isCheckBox, setIsCheckBox] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [currentTask, setCurrentTask] = useState<TaskDetailsInterface>();
  const [selectFile, setSelectFile] = useState<boolean>(false);
  const [replyMessage, setReplyMessage] = useState<ReplyMessageDataInterface>();
  const [isCommentModal, setIsCommentModal] = useState<boolean>(false);
  const [subTaskOpen, setSubTaskOpen] = useState<boolean>(false);
  const [editComment, setEditComment] = useState(false);
  const [commentId, setCommentId] = useState<string>('');
  const [isCommentDropdownOpen, setIsCommentDropdownOpen] = useState(false);
  const [emojiLoading, setEmojiLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    project: projectSelector,
    workspace: workspaceSelector,
    task: taskSelector,
    timeTracking: timeTrackingSelector,
    auth: authSelector,
    app: appSelector
  } = stateSelector || {};
  const { taskDetails, taskGroups, subTaskList } = taskSelector;
  const { workspace } = workspaceSelector;
  const { id: workspace_id, customerRole } = workspace;
  const { trackingActivity } = timeTrackingSelector;
  const { currentProject, projectAllFiles } = projectSelector;
  const { userDetails } = authSelector;
  const { isBubbleMenuOpen } = appSelector;

  // Other variables
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const taskId = isEmpty(props?.taskId) ? new URLSearchParams(location?.search).get('task') : props?.taskId;
  const projectId = isEmpty(props?.projectId) ? params?.id : props?.projectId;
  const mobile = useMobileDevice();
  const currentUser = UserPreferenceSingleton.getInstance().getCurrentUser();
  const currentEmoji = UserPreferenceSingleton.getInstance().getUserUseEmoji();

  //dropdown menu items

  const menuItems = [
    {
      key: MENU_OPTIONS.EDIT,
      label: 'Edit',
      iconName: 'more-menu-edit-icon'
    },
    {
      key: MENU_OPTIONS.ADDLINK,
      label: 'Add link',
      iconName: 'copy-link-icon'
    },
    {
      key: MENU_OPTIONS.CLONE,
      label: 'Clone',
      iconName: 'copy-icon-16x16'
    },
    {
      key: MENU_OPTIONS.DELETE,
      label: 'Delete',
      tone: 'critical',
      iconName: 'more-menu-delete-icon'
    }
  ];

  // className list for threaded messages
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const classList = [
    'figma-main-div',
    'youtube-preview-main-div',
    'screen-recording-custom-main-div',
    'e-rte-image e-imginline'
  ];

  const Cancel = useCallback(() => {
    setIsOpenComment(false);
    setEditComment(false);
    if (editComment) {
      setUploadedFiles([]);
      setComment('');
      setCommentId('');
    }
  }, [editComment]);

  const loadData = useCallback(
    async () => {
      if (!isEmpty(workspace?.id) && !isEmpty(projectId) && !isEmpty(taskId)) {
        let projectDetails = currentProject;
        if (projectDetails?.id !== projectId) {
          projectDetails = await dispatch(getCurrentProjectDetails(workspace?.id, projectId));
        }
        const validUser = projectDetails?.users?.find((user) => user?.id === currentUser?.id);
        if (!validUser) {
          history.push('/');
          return;
        }
        const draftMessage = await DraftMessageCacheService.getInstance().getCurrentDraftMessage(taskId);
        if (inlineRef.current) {
          if (draftMessage) {
            inlineRef.current.addContent(draftMessage.message);
            setComment(draftMessage.message);
            setUploadedFiles(draftMessage.file);
          } else {
            inlineRef.current.addContent('');
            setUploadedFiles([]);
          }
        }
        let promises = [
          dispatch(getMyTaskDetailsData(taskId)),
          dispatch(getTaskGroupList(projectId)),
          dispatch(getProjectFiles(projectId, projectDetails?.users)),
          dispatch(getSubTasksList(projectId, taskId))
        ];
        if (isEmpty(customerRole)) {
          promises.push(dispatch(getTimelogData(projectId, taskId)));
        }
        const result = await Promise.all(promises);
        if (!isEmpty(result[0]?.parentTaskId)) {
          const parentTask = await TasksCacheService.getInstance()?.getItem(result[0]?.parentTaskId);
          setParentTask(parentTask);
        }

        if (result[0]?.projectId !== projectId || isEmpty(result[0])) {
          dispatch(setErrorMessage('Task not found.'));
          history.push('/');
        }
        if (projectDetails?.isMilestone) {
          await dispatch(getMilestoneList(projectId, false, false));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workspace?.id, projectId, dispatch, taskId, history]
  );

  const loadSubTasksData = useCallback(async () => {
    await dispatch(getSubTasksList(projectId, taskId));
  }, [dispatch, projectId, taskId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => {
      dispatch(clearSubTaskList());
      dispatch(clearMyTaskDetailsData());
      dispatch(setTimelogData([]));
      dispatch(setMilestoneList([]));
      dispatch(setProjectTaskGroupsList([]));
      dispatch(setProjectAllFiles([]));
      dispatch(setTaskGroupsList([]));
    };
  }, [dispatch]);

  // Helper function to update comments in Redux
  const updateCommentInState = useCallback(
    (updatedHtmlString: string, commentId: string) => {
      dispatch(
        updateTaskCommentItem({
          commentId,
          value: { comment: updatedHtmlString }
        })
      );
    },
    [dispatch]
  );

  // Helper function to parse comment HTML
  const getCommentBody = useCallback((commentHtml: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(commentHtml, 'text/html');
    return doc.querySelector('body');
  }, []);

  // Helper function to add/remove animation
  const toggleRefreshAnimation = useCallback((body: HTMLElement, isSpin: boolean) => {
    const refreshIcon = body.querySelector('.refresh-icon');
    if (refreshIcon) {
      if (isSpin) {
        refreshIcon.classList.add('refresh-icon-spin');
      } else {
        refreshIcon.classList.remove('refresh-icon-spin');
      }
    }
  }, []);

  // for open model
  const openModel = useCallback(() => {
    setIsOpen(true);
  }, []);

  // for cancel model
  const cancelModel = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onClickCheckBox = useCallback(
    async (body: HTMLBodyElement | null, htmlElement: HTMLElement | null | undefined, commentId: string) => {
      if (!htmlElement || !body) return;
      // Extract div content from htmlElement
      const htmlElementDiv = htmlElement.querySelector('div');
      if (!htmlElementDiv) return;

      const htmlElementDivHTML = htmlElementDiv.innerHTML.trim();

      // Find matching li in the parsed body
      const matchingLi = Array.from(body.querySelectorAll('li[data-type="taskItem"]')).find((li) => {
        const liDiv = li.querySelector('div');
        return liDiv && liDiv.innerHTML.trim() === htmlElementDivHTML;
      });

      if (matchingLi) {
        const input = matchingLi.querySelector('input[type="checkbox"]');
        if (input) {
          if (input.hasAttribute('checked')) {
            matchingLi.setAttribute('data-checked', 'false');
            input.removeAttribute('checked'); // Remove checked if it exists
          } else {
            matchingLi.setAttribute('data-checked', 'true');
            input.setAttribute('checked', 'true'); // Add checked without a value
          }
        }
      }
      const updatedHtmlString = body.innerHTML;
      updateCommentInState(updatedHtmlString, commentId);
      await dispatch(updateTaskComment(taskId, commentId, { Comment: updatedHtmlString }));
    },
    [dispatch, taskId, updateCommentInState]
  );

  // Helper function to extract Figma fileId and nodes
  const extractFigmaUrlData = useCallback((figmaUrl: string) => {
    const url = new URL(figmaUrl);
    return { fileId: url.pathname.split('/')[2], nodes: url.searchParams.get('node-id') };
  }, []);

  const onClickHandleRefreshFigma = useCallback(
    async (event: React.MouseEvent, item: DataUpdatedCommentsInterface) => {
      try {
        setFigmaRefreshLoading(true);
        const clickedElement = event.target as HTMLElement;
        const body = getCommentBody(item?.comment || '');
        if (!body) return;
        const subParentElement = clickedElement.parentElement;
        const parentElement = subParentElement?.parentElement;
        if (clickedElement.id === 'figma-refresh-ref') {
          const figmaUrlDiv = body.querySelector('.figma-link-div') as HTMLAnchorElement;
          if (!figmaUrlDiv) return;

          toggleRefreshAnimation(body, true);

          updateCommentInState(body.innerHTML, item?.id);

          const { fileId, nodes } = extractFigmaUrlData(figmaUrlDiv.href);
          const figmaData = await dispatch(getFigmaPreviewUrl({ fileId, nodes }));
          if (!figmaData) return;

          const previewImage = body.querySelector('.figma-preview-image');
          if (previewImage instanceof HTMLImageElement) {
            previewImage.src = figmaData?.filePreviewUrl || '';
          }

          const description = body.querySelector('.figma-description');
          if (description) {
            description.textContent = figmaData?.frameNames;
          }

          toggleRefreshAnimation(body, false);

          const filename = body.querySelector('.figma-filename');
          if (filename) {
            filename.textContent = figmaData?.projectName || '';
          }

          const updatedHtmlString = body.innerHTML;
          updateCommentInState(updatedHtmlString, item.id);
          const result = await dispatch(updateTaskComment(taskId, item?.id, { Comment: updatedHtmlString }));
          if (result) await dispatch(getMyTaskDetailsData(taskId));
        } else if (
          parentElement &&
          parentElement.tagName.toLowerCase() === 'li' &&
          parentElement.getAttribute('data-type') === 'taskItem'
        ) {
          onClickCheckBox(body, parentElement, item.id);
        }
      } catch (e) {
        captureException(e);
      } finally {
        setFigmaRefreshLoading(false);
      }
    },
    [
      dispatch,
      extractFigmaUrlData,
      getCommentBody,
      onClickCheckBox,
      taskId,
      toggleRefreshAnimation,
      updateCommentInState
    ]
  );

  const Opendeletemodel = (taskId: string, subTask = false) => {
    setDeleteModelopen(true);
    setSelectedTaskId(taskId);
    setIsFromSubList(subTask);
  };

  const close = () => {
    setDeleteModelopen(false);
    setSelectedTaskId('');
    setIsFromSubList(false);
  };

  const closeAlertModal = () => {
    setAlertModalOpen(false);
  };

  const isClosemodel = () => {
    setIsMilestonmodelopen(false);
  };

  const handleCancel = useCallback(() => {
    setOpen(false);
    dispatch(clearCreateTaskInput());
    setIsFromSubList(false);
  }, [dispatch]);

  //find updated priority
  const updatedPriority = useCallback(
    (taskDetails: TaskDetailsInterface) => {
      if (taskDetails?.priority !== undefined) return taskDetails?.priority;
      if (currentProject?.priority && currentProject?.priority?.default !== undefined)
        return currentProject?.priority?.default;
      return 0;
    },
    [currentProject?.priority]
  );

  const onClickEdit = useCallback(
    async (taskDetails: TaskDetailsInterface, subList = false) => {
      setIsFromSubList(subList);
      dispatch(setCreateTaskInput({ ...(taskDetails || {}), description: `${taskDetails?.description || ''}<p></p>` }));
      if (taskDetails?.groupId) {
        const currentGroup = taskGroups?.find((item) => item?.id === taskDetails?.groupId);
        dispatch(updateCreateTaskInput({ propsName: 'groupId', value: currentGroup }));
      }
      await dispatch(getTaskGroupList(taskDetails?.projectId, true));
      dispatch(updateCreateTaskInput({ propsName: 'projectId', value: currentProject }));
      dispatch(
        updateCreateTaskInput({
          propsName: 'priority',
          value: updatedPriority(taskDetails)
        })
      );
      setOpen(true);
    },
    [dispatch, currentProject, updatedPriority, taskGroups]
  );

  const deleteTask = useCallback(async () => {
    try {
      setLoading(true);
      const payloadTask = { isArchived: true, projectId: currentProject?.id };
      const response = await dispatch(updateTaskDetails(selectedTaskId, payloadTask));
      setDeleteModelopen(false);
      if (response && !isFromSubList) {
        history.goBack();
      } else if (response && isFromSubList) {
        loadSubTasksData();
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [currentProject?.id, dispatch, selectedTaskId, isFromSubList, history, loadSubTasksData]);

  //update comment
  const updateSortedComment = useCallback(
    (newComment: UpdateCommentReducer) => {
      const userData = workspace?.users?.find((user) => user?.id === currentUser?.id);
      const commentDetail = {
        id: newComment?.id,
        createdOn: new Date().toISOString(),
        createdBy: currentUser?.id,
        updatedBy: currentUser?.id,
        updatedTime: new Date().toISOString(),
        user: userData,
        isArchived: false
      };
      const updatedComments = [
        ...(taskDetails?.updatedComments || []),
        { ...(newComment || {}), ...(commentDetail || {}) }
      ];
      const sortedUpdatedComments = updatedComments?.toSorted(
        (a, b) => new Date(a?.createdOn).getTime() - new Date(b?.createdOn).getTime()
      );
      return sortedUpdatedComments || [];
    },
    [currentUser?.id, taskDetails?.updatedComments, workspace?.users]
  );

  //process image links
  const processImageLinks = async (commentDesc: string) => {
    let commentData = commentDesc;
    const tempElement = document.createElement('div');
    tempElement.innerHTML = commentData || '';
    const linkElements = tempElement.querySelectorAll('img');
    const promises: Promise<void>[] = [];
    linkElements.forEach((linkElement) => {
      const myPromise = new Promise<void>((resolve, reject) => {
        (async () => {
          try {
            const srclink = linkElement.getAttribute('src');
            if (
              srclink &&
              !srclink.startsWith('https://firebasestorage') &&
              !srclink.startsWith('https://figma') &&
              !srclink.startsWith('/static') &&
              !srclink.startsWith('https://')
            ) {
              const fileData = await convertBase64ToFile(srclink);
              const fileUrl = await getFirebaseUrlFromFile({ file: fileData }, 'files/', dispatch);
              if (fileUrl) {
                commentData = commentData.replace(srclink, fileUrl);
              }
            }
            resolve();
          } catch (error) {
            reject(error);
          }
        })();
      });
      promises.push(myPromise);
    });
    await Promise.all(promises);
    return commentData;
  };

  //create comment object
  const createNewCommentObject = useCallback(
    (uploadedFiles: CommentFilesInterface[], commentDesc: string, id: string, isEdit?: boolean, userData?: any) => {
      const newComment: DataUpdatedCommentsInterface = {
        createdOn: new Date().toISOString(),
        id,
        comment: commentDesc,
        user: userData,
        updatedBy: currentUser?.id,
        updatedTime: new Date().toISOString(),
        type: COMMENT_TYPE.COMMENT,
        isArchived: false,
        Files: uploadedFiles || [],
        Reactions: [],
        reply: isEmpty(replyMessage) ? undefined : replyMessage
      };
      let sortedUpdatedComments;
      if (!isEdit) {
        // Update Redux state immediately
        sortedUpdatedComments = updateSortedComment(newComment);
      } else {
        const updatedComments = [...(taskDetails?.updatedComments || [])];
        const index = updatedComments?.findIndex((comment) => comment?.id === commentId);
        if (index > -1) {
          updatedComments[index] = {
            ...(taskDetails?.updatedComments[index] || {}),
            comment: commentDesc,
            reply: isEmpty(replyMessage) ? undefined : replyMessage,
            Files: uploadedFiles || []
          };
          sortedUpdatedComments = updatedComments?.toSorted(
            (a, b) => new Date(a?.createdOn).getTime() - new Date(b?.createdOn).getTime()
          );
        }
      }
      dispatch(
        updateMyTaskDetailsData({
          propsName: 'updatedComments',
          value: sortedUpdatedComments
        })
      );
      setAttachment(false);
      setComment('');
      inlineRef.current.clearContent();
      setLoading(true);
      Cancel();
      setReplyMessage({});
    },
    [Cancel, commentId, currentUser?.id, dispatch, replyMessage, taskDetails?.updatedComments, updateSortedComment]
  );

  //comment submission
  const handleCommentSubmission = async (commentData: string, uploadedFiles: CommentFilesInterface[], id: string) => {
    const updatedFiles = uploadedFiles.map(({ __typename, isCreatedDoc, ...other }) => other);
    const replyMessagePayload = {
      CreatedTime: replyMessage?.CreatedTime,
      id: replyMessage?.id,
      Sender: replyMessage?.Sender,
      Sender_id: replyMessage?.Sender_id,
      Message: replyMessage?.Message,
      Files: replyMessage?.Files
    };
    const editorElement: HTMLElement | null = document.getElementById('inlineRTE_rte-edit-view');
    if (editorElement) {
      editorElement.innerHTML = '<p><br /></p>';
    }
    let result;
    if (!commentId) {
      result = await dispatch(
        createNewComment(taskId, {
          Comment: commentData,
          Files: !isEmpty(updatedFiles) ? updatedFiles : [],
          Type: COMMENT_TYPE.COMMENT,
          Reply: replyMessagePayload,
          id
        })
      );
    } else {
      result = await dispatch(
        updateTaskComment(taskId, commentId, {
          Comment: commentData,
          Files: !isEmpty(updatedFiles) ? updatedFiles : []
        })
      );
    }
    if (result) {
      trackAnalyticActivity(TASK_ANALYTICS.COMMENT_ADDED);
    }
  };

  const handleValueChange = useCallback(async (text: string) => {
    setComment(text);
    // Add a delay of 1 second before calling onFileChange
    const currentTaskId = new URLSearchParams(window.location.search).get('task');
    if (text !== '<p></p>') {
      setTimeout(async () => {
        const draftMessage = await DraftMessageCacheService.getInstance().getCurrentDraftMessage(currentTaskId);
        const message = {
          id: currentTaskId,
          type: DRAFT_MESSAGE_TYPE.COMMENT,
          message: text,
          file: [...(draftMessage?.file || [])]
        };
        await DraftMessageCacheService.getInstance().addBulk([message]);
      }, 300);
    } else if (text === '<p></p>') {
      const draftMessage = await DraftMessageCacheService.getInstance().getCurrentDraftMessage(currentTaskId);
      if (isEmpty(draftMessage?.file)) {
        await DraftMessageCacheService.getInstance().removeBulk([currentTaskId]);
      }
    }
  }, []);

  const onFileChange = useCallback(
    async (file: CommentFilesInterface[]) => {
      const currentTaskId = new URLSearchParams(window.location.search).get('task');
      setUploadedFiles(file);
      const message = {
        id: currentTaskId,
        type: DRAFT_MESSAGE_TYPE.COMMENT,
        message: comment,
        file: file || []
      };
      await DraftMessageCacheService.getInstance().addBulk([message]);
      if ((isEmpty(comment) || comment === '<p></p>') && isEmpty(file)) {
        await DraftMessageCacheService.getInstance().removeBulk([currentTaskId]);
      }
    },
    [comment]
  );

  const onSendComment = useCallback(
    async (uploadedFiles: CommentFilesInterface[], commentDesc: string, isEdit?: boolean) => {
      try {
        const pattern = /<p><span>​<\/span><\/p>|<p><br><\/p>|<p><br><br><\/p>/g;
        if (isEmptyMessage(commentDesc) && isEmpty(uploadedFiles)) {
          return dispatch(setErrorMessage("Comment can't be empty."));
        }
        if (!isEmpty(commentDesc)) {
          while (pattern.test(commentDesc)) {
            commentDesc = commentDesc.replace(pattern, '');
          }
          if (commentDesc.match(/<br\s*>\s*<\/p>$/)) commentDesc = commentDesc.replace(/<br\s*>\s*<\/p>$/, '</p>');
        }
        const userData = workspace?.users?.find((user) => user?.id === currentUser?.id);
        if ((!isEmpty(commentDesc) || uploadedFiles?.length > 0) && userData) {
          const id = nanoid();
          createNewCommentObject(uploadedFiles, commentDesc, id, isEdit, userData);
          const processedCommentData = await processImageLinks(commentDesc);
          await handleCommentSubmission(processedCommentData, uploadedFiles, id);
        }
        await DraftMessageCacheService.getInstance().removeBulk([taskDetails?.id]);
      } catch (e) {
        captureException(e);
        console.log('Error', e);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser?.id, dispatch, taskDetails?.updatedComments, replyMessage, taskId, loadData, commentId]
  );

  const onReactOnMessage = useCallback(
    async (emojiData: { emoji: string }, item: { id: string }) => {
      setEmojiLoading(true);
      closeEmojiDropdown();
      const updatedEmojis = [
        emojiData?.emoji,
        ...(Array.isArray(currentEmoji) ? currentEmoji.filter((e) => e !== emojiData?.emoji) : [])
      ].slice(0, 2);
      UserPreferenceSingleton.getInstance().setUserUseEmoji(updatedEmojis);
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const payload = {
        reaction: emojiData?.emoji,
        commentId: item?.id,
        taskId: taskId,
        userId: userDetails?.id,
        companyId: workspace_id
      };
      const response = await dispatch(addReactionOnCommect(payload));
      if (response) {
        trackAnalyticActivity(TASK_ANALYTICS.COMMENT_REACTED);
        dispatch(getMyTaskDetailsData(taskId));
        setEmojiLoading(false);
      }
    },
    [currentEmoji, dispatch, taskId, workspace_id]
  );

  const editCommentData = useCallback(async (commentData: DataUpdatedCommentsInterface) => {
    setCommentId(commentData?.id);
    setUploadedFiles(commentData?.Files || []);
    setComment(`${commentData?.comment || ''} <p><br /></p>`);
    setEditComment(true);
    setIsOpenComment(true);
  }, []);

  const removeComment = useCallback(
    async (commentId: string) => {
      const deletedComment = taskDetails?.updatedComments.find((item) => item?.id === commentId);
      if (deletedComment) {
        const payload = { IsArchived: true };
        // Update the Redux store immediately
        setDeletedMessage((prevDeletedMessages) => [...prevDeletedMessages, deletedComment]);
        const result = await dispatch(updateTaskComment(taskDetails?.id, commentId, payload));
        if (result) {
          // Update Redux store with updated comments
          trackAnalyticActivity(TASK_ANALYTICS.COMMENT_DELETED);
        }
      }
    },
    [taskDetails, dispatch]
  );

  const onClickClone = useCallback(
    async (task: TaskDetailsInterface) => {
      const taskDetails = task;
      const taskFiles = taskDetails?.Files?.map((item) => {
        const { __typename, ...other } = item;
        return other;
      });
      const cloneTaskdetails = {
        Files: taskFiles,
        description: `${taskDetails?.description || ''}<p></p>`,
        name: taskDetails?.name,
        groupId: taskDetails?.groupId,
        projectId: taskDetails?.projectId,
        priority: taskDetails?.priority,
        users: taskDetails?.users,
        labels: taskDetails?.labels,
        estimate: taskDetails?.estimate,
        milestoneId: taskDetails?.milestoneId,
        statusId: taskDetails?.statusId
      };
      dispatch(setCreateTaskInput(cloneTaskdetails));
      if (!isEmpty(taskDetails?.groupId)) {
        const group = taskGroups.find((item) => item?.id === taskDetails?.groupId);
        dispatch(updateCreateTaskInput({ propsName: 'groupId', value: group }));
      }
      dispatch(updateCreateTaskInput({ propsName: 'projectId', value: currentProject }));
      await dispatch(getTaskGroupList(currentProject?.id, true));
      if (taskDetails?.parentTaskId) {
        dispatch(updateCreateTaskInput({ propsName: 'parentTaskId', value: taskDetails?.parentTaskId }));
      }
      dispatch(
        updateCreateTaskInput({
          propsName: 'priority',
          value:
            taskDetails?.priority !== undefined
              ? taskDetails.priority
              : currentProject?.priority?.default !== undefined
              ? currentProject.priority.default
              : 0
        })
      );
      setOpen(true);
    },
    [dispatch, currentProject, taskGroups]
  );

  const onClickUndo = useCallback(
    async (undoCommentId: string) => {
      const undoneComment = deletedMessage?.find((item) => item?.id === undoCommentId);
      if (undoneComment) {
        const updatedDeletedMessages = deletedMessage?.filter((item) => item?.id !== undoCommentId);
        const payload = { IsArchived: false };
        setDeletedMessage(updatedDeletedMessages);
        await dispatch(updateTaskComment(taskDetails?.id, undoCommentId, payload));
      }
    },
    [deletedMessage, taskDetails, dispatch]
  );
  const getFilePath = useCallback((x: CommentFilesInterface, taskDetails: TaskDetailsInterface | undefined) => {
    const fileName = x?.name?.replaceAll(' ', '_');
    if (x?.href?.startsWith('/projects/files')) return x?.href;
    if (x?.href?.startsWith('https://') && x?.uploadFileType !== FILE_TYPES.UPLOAD && x?.isCreatedDoc) return x?.href;
    if (
      x?.href?.startsWith('https://') &&
      (x?.uploadFileType === FILE_TYPES.DOCS ||
        x?.uploadFileType === FILE_TYPES.DRIVE ||
        x?.uploadFileType === FILE_TYPES.DROPBOX ||
        x?.uploadFileType === FILE_TYPES.FIGMA ||
        x?.uploadFileType === FILE_TYPES.ONEDRIVE)
    ) {
      return x?.href;
    }
    return `/projects/details/${taskDetails?.projectId}/files/file-view/${fileName}?fileUrl=${x?.href}`;
  }, []);

  // handle File card click in activity
  const handleActivityFileCard = useCallback(
    (e: React.SyntheticEvent, x: CommentFilesInterface) => {
      e.preventDefault();
      const path = getFilePath(x, taskDetails);
      if (
        x?.uploadFileType &&
        [
          FILE_TYPES.DRIVE,
          FILE_TYPES.DROPBOX,
          FILE_TYPES.NOTION,
          FILE_TYPES.FIGMA,
          FILE_TYPES.ONEDRIVE,
          FILE_TYPES.DOCS,
          FILE_TYPES.WHITEBOARD
        ].includes(x?.uploadFileType)
      ) {
        window.open(path, '_blank');
        return;
      } else {
        history.push(path);
      }
    },
    [getFilePath, history, taskDetails]
  );
  const renderActivityStatusIcon = useCallback((type: number) => {
    switch (type) {
      case STATUS_TYPE.BACKLOG:
        return (
          <SVGIcon
            name='task-activity-backlog-icon'
            width='14'
            height='14'
            viewBox='0 0 14 14'
            className='task-status-icon'
          />
        );

      case STATUS_TYPE.UNSTARTED:
        return <SVGIcon name='task-todo-icon' width='16' height='16' viewBox='0 0 16 16' className='task-todo-icon' />;
      case STATUS_TYPE.IN_PROGRESS:
        return (
          <SVGIcon
            name='task-inprogress-icon'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            className='task-inprogress-icon'
          />
        );
      case STATUS_TYPE.IN_REVIEW:
        return (
          <SVGIcon
            name='task-inreview-icon'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            className='task-inreview-icon'
          />
        );
      case STATUS_TYPE.COMPLETED:
        return (
          <SVGIcon
            name='task-completed-icon'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            className='task-completed-icon'
          />
        );
      case STATUS_TYPE.CANCELLED:
        return (
          <SVGIcon name='task-cancelled-icon' width='16' height='16' viewBox='0 0 16 16' className='cancelled-icon' />
        );
      default:
        return <></>;
    }
  }, []);

  const getReactionCounts = useCallback(
    (reactions: CommentReactionsInterface[] = []) =>
      reactions.reduce((acc: { [key: string]: number }, { ReactionType }) => {
        acc[ReactionType] = (acc[ReactionType] || 0) + 1;
        return acc;
      }, {}),
    []
  );

  const RenderCommentHeader = useCallback(
    ({ item, dueTime }: { item: DataUpdatedCommentsInterface; dueTime: string }) => (
      <Div isComment>
        <Avatar imgSrc={item?.user?.profile_photo} name={item?.user?.name || 'U N'} size={24} isNotBorder />
        <ActivityContentView>
          <ActivitySubContent isComment>
            <SubTitle>{item?.user?.name}</SubTitle>
            <Tooltip
              title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}
              placement='top'
              maxWidth={'max-content'}>
              <Text isComment>{dueTime}</Text>
            </Tooltip>
          </ActivitySubContent>
        </ActivityContentView>
      </Div>
    ),
    [userDetails?.dateFormat]
  );

  const renderFiles = (files: CommentFilesInterface[] | undefined) => {
    if (!files || files.length === 0) return null;

    return (
      <div className='filediv'>
        {files.map((file) => {
          const extension = extensionName(file).toLowerCase();
          const isUploadedFile = !file.uploadFileType || file.uploadFileType === FILE_TYPES.UPLOAD;
          const isGenericFile = isUploadedFile && !FILE_EXTENTIONS.includes(extension);
          let iconName: string;
          if (isGenericFile) {
            iconName = 'blank';
          } else if (isUploadedFile) {
            iconName = extension;
          } else {
            iconName = `icon_${file.uploadFileType}`;
          }
          return (
            <div key={file.href} className='imagediv'>
              <Svgfile>
                {isGenericFile ? (
                  <FileIconDiv>
                    <FileSVGIcon name='blank' height='32' width='32' viewBox='0 0 60 60' />
                    <FileType style={{ fontSize: extension.length === 4 ? 7 : 5 }} className='file-text'>
                      {extension.length <= 5 && extension.split('.').pop()?.toUpperCase()}
                    </FileType>
                  </FileIconDiv>
                ) : (
                  <FileSVGIcon name={iconName} height='32' width='32' viewBox='0 0 60 60' />
                )}
              </Svgfile>
              <div className='filetext'>{file.name}</div>
            </div>
          );
        })}
      </div>
    );
  };

  // Usage

  const RenderReply = useCallback(
    ({ item }: { item: DataUpdatedCommentsInterface }) => {
      if (!item?.reply?.id || deletedMessage?.some((d) => d?.id === item?.id)) return null;
      const isShowInBlock = classList.some((className) => item?.reply?.Message?.includes(className));
      const classForReply = isShowInBlock ? 'textblock' : 'text';
      const handleReplyClick = () => {
        const repliedComment = document.getElementById(item?.reply?.id || '');
        if (repliedComment) {
          repliedComment.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };
      return (
        <div className='threaddiv' onClick={handleReplyClick}>
          <div className='content'>
            <div className='line' />
            <div className='rightdiv'>
              <div className='header'>
                <div className='leftheader'>
                  <div className='name'>{item?.reply?.Sender}</div>
                  <div className='time'>{moment(item?.reply?.CreatedTime).fromNow()}</div>
                </div>
              </div>
              {!isEmpty(item?.reply?.Message) && (
                <div className={classForReply}>
                  <RenderHtml htmlstring={item?.reply?.Message || ''} isReplyText={true} />
                </div>
              )}
              {item?.reply?.Files && renderFiles(item.reply.Files)}
            </div>
          </div>
        </div>
      );
    },
    [classList, deletedMessage]
  );

  const RenderFileList = useCallback(
    ({ files }: { files: CommentFilesInterface[] }) => {
      if (!files.length) return null;

      const { imageFiles, videoFiles, audioFiles, otherFiles } = categorizeFiles(files);

      return (
        <FileSection>
          {imageFiles.map((item) => (
            <RenderImagePreview key={item?.href} imageUrl={item?.href} altText={item?.name} />
          ))}
          {videoFiles.map((item) => (
            <RenderVideoFile key={item?.href} videoUrl={item?.href} videoName={item?.name} />
          ))}
          {audioFiles.map((item) => (
            <audio controls key={item?.href}>
              <source type='audio/mp3' src={item?.href} />
            </audio>
          ))}
          {otherFiles.map((file) => (
            <RenderFilePreview
              key={file?.size}
              file={file}
              maxWidth={240}
              onClickUpdate={(e) => handleActivityFileCard(e, file)}
            />
          ))}
        </FileSection>
      );
    },
    [handleActivityFileCard]
  );

  const RenderCommentBody = useCallback(
    ({ item }: { item: DataUpdatedCommentsInterface }) => {
      const emptyDescPattern = /^<p>(?:\s|<br\s*\/?>)*<\/p>$/;
      if (deletedMessage?.some((d) => d?.id === item?.id)) {
        return (
          <Undomessage>
            <p>This message has been deleted.</p>
            <a onClick={() => onClickUndo(item?.id)}>Undo</a>
          </Undomessage>
        );
      }

      return (
        <Div2>
          {!emptyDescPattern.test(item?.comment || '') && !isEmpty(item?.comment) && (
            <SubText
              figmaRefreshLoading={figmaRefreshLoading}
              onClick={(e) => onClickHandleRefreshFigma(e, item)}
              className='tiptap'>
              <RenderHtml htmlstring={item?.comment || ''} />
            </SubText>
          )}
          <RenderFileList files={item?.Files || []} />
        </Div2>
      );
    },
    [RenderFileList, deletedMessage, figmaRefreshLoading, onClickHandleRefreshFigma, onClickUndo]
  );

  const RenderReactions = useCallback(
    ({
      item,
      reactionCounts
    }: {
      item: DataUpdatedCommentsInterface;
      reactionCounts: {
        [key: string]: number;
      };
    }) => {
      if (!item?.Reactions?.length) return null;

      return (
        <Dropdown
          content={
            <Reactiondiv>
              {Object.entries(reactionCounts).map(([type, count]) => (
                <ReactionShow
                  key={`reactions_${type}_${count}`}
                  onClick={() => onReactOnMessage({ emoji: type }, item)}
                  onMouseOver={() => setHoveredReaction(type)}
                  isDisabled={emojiLoading}>
                  <div className='reaction-icon'>{type}</div>
                  <p>{count}</p>
                </ReactionShow>
              ))}
            </Reactiondiv>
          }
          trigger='hover'>
          {Object.entries(reactionCounts).map(([type]) => (
            <div key={type}>
              {type === hoveredReaction &&
                item?.Reactions?.filter((reaction) => reaction.ReactionType === hoveredReaction).map((reactedUser) => {
                  const user = workspace?.users?.find((u) => u?.id === reactedUser?.UserId);
                  return (
                    <DropdownItem
                      key={`Reactions_type_${reactedUser?.UserId}`}
                      label={user?.name}
                      isAvatar={true}
                      avtarSrc={user?.profile_photo || ''}
                    />
                  );
                })}
            </div>
          ))}
        </Dropdown>
      );
    },
    [emojiLoading, hoveredReaction, onReactOnMessage, workspace?.users]
  );

  const RenderSetDueDate = useCallback(
    ({ item, dueTime }: { item: DataUpdatedCommentsInterface; dueTime: string }) => (
      <>
        <Editicon>
          <SVGIcon name='activity-calendar-icon' viewBox='0 0 14 14' width='14' height='14' className='calendar-icon' />
        </Editicon>
        <ActivityContentView>
          <ActivitySubContent>
            <SubTitle>
              {item?.user?.name}
              <Text>
                set due date to <Text>{moment(item?.dueDate).format(userDetails?.dateFormat)}</Text>.
              </Text>
              <Tooltip
                placement='bottom'
                title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}
                maxWidth={'max-content'}>
                <Text>{dueTime}</Text>
              </Tooltip>
            </SubTitle>
          </ActivitySubContent>
        </ActivityContentView>
      </>
    ),
    [userDetails?.dateFormat]
  );

  const RenderChangeDueDate = useCallback(
    ({ item, dueTime }: { item: DataUpdatedCommentsInterface; dueTime: string }) => (
      <>
        <Editicon>
          <SVGIcon name='activity-calendar-icon' viewBox='0 0 14 14' width='14' height='14' className='calendar-icon' />
        </Editicon>
        <ActivityContentView>
          <ActivitySubContent>
            <SubTitle>
              {item?.user?.name}
              <Text>
                changed due date from <span>{moment(item?.previousDueDate).format(userDetails?.dateFormat)}</span> to{' '}
                <Text>{moment(item?.dueDate).format(userDetails?.dateFormat)}</Text>.
              </Text>
              <Tooltip
                placement='bottom'
                title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}
                maxWidth={'max-content'}>
                <Text>{dueTime}</Text>
              </Tooltip>
            </SubTitle>
          </ActivitySubContent>
        </ActivityContentView>
      </>
    ),
    [userDetails?.dateFormat]
  );

  const RenderAddAssignee = useCallback(
    ({ item, dueTime }: { item: DataUpdatedCommentsInterface; dueTime: string }) => (
      <>
        <Avatar
          imgSrc={item?.user?.profile_photo}
          name={item?.user?.name ? item?.user.name : 'U N'}
          size={24}
          isNotBorder={true}
          isZindex={true}
        />
        <ActivityContentView>
          <ActivitySubContent>
            <SubTitle>
              {item?.user?.name}
              <Text>
                assigned task to <Text>{item?.assignee?.name}</Text>.
              </Text>
              <Tooltip
                placement='bottom'
                maxWidth={'max-content'}
                title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}>
                <Text>{dueTime}</Text>
              </Tooltip>
            </SubTitle>
          </ActivitySubContent>
        </ActivityContentView>
      </>
    ),
    [userDetails?.dateFormat]
  );

  const RenderRemoveAssignee = useCallback(
    ({ item, dueTime }: { item: DataUpdatedCommentsInterface; dueTime: string }) => (
      <>
        <Avatar
          imgSrc={item?.user?.profile_photo}
          name={item?.user?.name ? item?.user.name : 'U N'}
          size={24}
          isNotBorder={true}
          isZindex={true}
        />
        <ActivityContentView>
          <ActivitySubContent>
            <SubTitle>
              {item?.user?.name}
              <Text>
                unassigned task to <Text>{item?.assignee?.name}</Text>.
              </Text>
              <Tooltip
                placement='bottom'
                maxWidth={'max-content'}
                title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}>
                <Text>{dueTime}</Text>
              </Tooltip>
            </SubTitle>
          </ActivitySubContent>
        </ActivityContentView>
      </>
    ),
    [userDetails?.dateFormat]
  );

  const RenderUnassign = useCallback(
    ({ item, dueTime }: { item: DataUpdatedCommentsInterface; dueTime: string }) => (
      <>
        <Avatar
          imgSrc={item?.user?.profile_photo}
          name={item?.user?.name ? item?.user.name : 'U N'}
          size={24}
          isNotBorder={true}
          isZindex={true}
        />
        <ActivityContentView>
          <ActivitySubContent>
            <SubTitle>
              {item?.user?.name} <Text>removed assignee.</Text>
              <Tooltip
                placement='bottom'
                maxWidth={'max-content'}
                title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}>
                <Text>{dueTime}</Text>
              </Tooltip>
            </SubTitle>
          </ActivitySubContent>
        </ActivityContentView>
      </>
    ),
    [userDetails?.dateFormat]
  );

  const getPriorityIcon = useCallback((priority: number | undefined) => {
    if (!priority) return;
    const iconMap = {
      [TASK_PRIORITY.NO_PRIORITY]: 'Priority-No-priority-icon',
      [TASK_PRIORITY.URGENT]: 'Priority-Urgent-icon',
      [TASK_PRIORITY.HIGH]: 'Priority-High-icon',
      [TASK_PRIORITY.MEDIUM]: 'Priority-Medium-icon',
      [TASK_PRIORITY.LOW]: 'Priority-low-icon'
    };
    return iconMap[priority] || 'activity-no-priority-icon';
  }, []);

  const RenderChangePriority = useCallback(
    ({ item, dueTime }: { item: DataUpdatedCommentsInterface; dueTime: string }) => (
      <>
        <Editicon>
          <SVGIcon
            name={getPriorityIcon(item?.priority)}
            width='14'
            height='14'
            viewBox='0 0 16 16'
            className={getPriorityIcon(item?.priority) === 'activity-no-priority-icon' ? 'noicon' : ''}
          />
        </Editicon>
        <ActivityContentView>
          <ActivitySubContent>
            <SubTitle>
              {item?.user?.name}
              <Text>
                changed priority from{' '}
                <Text>
                  {item?.previousPriority !== undefined &&
                    item?.previousPriority !== null &&
                    TASK_PRIORITY_LABELS[Number(item?.previousPriority)]}
                </Text>{' '}
                to{' '}
                <Text>
                  {item?.priority !== undefined && item?.priority !== null && TASK_PRIORITY_LABELS[item?.priority]}
                </Text>
                .
              </Text>
              <Tooltip
                placement='bottom'
                maxWidth={'max-content'}
                title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}>
                <Text>{dueTime}</Text>
              </Tooltip>
            </SubTitle>
          </ActivitySubContent>
        </ActivityContentView>
      </>
    ),
    [getPriorityIcon, userDetails?.dateFormat]
  );

  const RenderChangeGroup = useCallback(
    ({ item, dueTime }: { item: DataUpdatedCommentsInterface; dueTime: string }) => (
      <>
        <Avatar
          imgSrc={item?.user?.profile_photo}
          name={item?.user?.name ? item?.user.name : 'U N'}
          size={24}
          isNotBorder={true}
          isZindex={true}
        />
        <ActivityContentView>
          <ActivitySubContent>
            <SubTitle>
              {item?.user?.name}
              <Text>
                changed group from <Text>{item?.previousGroup}</Text> to <Text>{item?.group}</Text>.
              </Text>
              <Tooltip
                placement='bottom'
                maxWidth={'max-content'}
                title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}>
                <Text>{dueTime}</Text>
              </Tooltip>
            </SubTitle>
          </ActivitySubContent>
        </ActivityContentView>
      </>
    ),
    [userDetails?.dateFormat]
  );
  const RenderUpdateTask = useCallback(
    ({ item, dueTime }: { item: DataUpdatedCommentsInterface; dueTime: string }) => (
      <>
        <Editicon>
          <SVGIcon
            name='activity-edit-icon'
            width='14'
            height='14'
            viewBox='0 0 14 14'
            stroke='var(--text-secondary)'
          />
        </Editicon>
        <ActivityContentView>
          <ActivitySubContent>
            <SubTitle>
              {item?.user?.name}
              <Text>updated task.</Text>
              <Tooltip
                placement='bottom'
                maxWidth={'max-content'}
                title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}>
                <Text>{dueTime}</Text>
              </Tooltip>
            </SubTitle>
          </ActivitySubContent>
        </ActivityContentView>
      </>
    ),
    [userDetails?.dateFormat]
  );
  const RenderRemoveDueDate = useCallback(
    ({ item, dueTime }: { item: DataUpdatedCommentsInterface; dueTime: string }) => (
      <>
        <Editicon>
          <SVGIcon name='activity-calendar-icon' viewBox='0 0 14 14' width='14' height='14' className='calendar-icon' />
        </Editicon>
        <ActivityContentView>
          <ActivitySubContent>
            <SubTitle>
              {item?.user?.name}
              <Text>removed due date.</Text>
              <Tooltip
                placement='bottom'
                maxWidth={'max-content'}
                title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}>
                <Text>{dueTime}</Text>
              </Tooltip>
            </SubTitle>
          </ActivitySubContent>
        </ActivityContentView>
      </>
    ),
    [userDetails?.dateFormat]
  );
  const RenderStatusChange = useCallback(
    ({ item, dueTime }: { item: DataUpdatedCommentsInterface; dueTime: string }) => (
      <>
        <Editicon>
          {item?.status ? (
            <SVGIcon
              name='activity-completedtask-icon'
              width='14'
              height='14'
              viewBox='0 0 14 14'
              className='completedtask-icon'
            />
          ) : (
            <SVGIcon
              name='activity-reopentask-icon'
              width='14'
              height='14'
              viewBox='0 0 14 14'
              className='reopentask-icon'
            />
          )}
        </Editicon>
        <ActivityContentView>
          <ActivitySubContent>
            <SubTitle>
              {item?.user?.name}
              <Text>{item?.status ? 'completed this task.' : 're‑opened this task.'}</Text>
              <Tooltip
                placement='bottom'
                maxWidth={'max-content'}
                title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}>
                <Text>{dueTime}</Text>
              </Tooltip>
            </SubTitle>
          </ActivitySubContent>
        </ActivityContentView>
      </>
    ),
    [userDetails?.dateFormat]
  );

  const RenderSetEstimate = useCallback(
    ({ item, dueTime }: { item: DataUpdatedCommentsInterface; dueTime: string }) => (
      <>
        <Editicon>
          <SVGIcon name='activity-estimate-icon' width='14' height='14' viewBox='0 0 14 14' className='estimate-icon' />
        </Editicon>
        <ActivityContentView>
          <ActivitySubContent>
            <SubTitle>
              {item?.user?.name}
              <Text>
                set estimate time to <Text>{item?.estimateTime && floatToHHMM(item?.estimateTime)} hours.</Text>
              </Text>
              <Tooltip
                placement='bottom'
                maxWidth={'max-content'}
                title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}>
                <Text>{dueTime}</Text>
              </Tooltip>
            </SubTitle>
          </ActivitySubContent>
        </ActivityContentView>
      </>
    ),
    [userDetails?.dateFormat]
  );

  const RenderAudio = useCallback(
    ({ item, dueTime }: { item: DataUpdatedCommentsInterface; dueTime: string }) => (
      <>
        <Avatar
          imgSrc={item?.user?.profile_photo}
          name={item?.user?.name ? item?.user.name : 'U N'}
          size={24}
          isNotBorder={true}
          isZindex={true}
        />
        <ActivityContentView>
          <ActivitySubContent>
            <SubTitle>
              {item?.user?.name}
              <Tooltip
                placement='bottom'
                maxWidth={'max-content'}
                title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}>
                <Text>{dueTime}</Text>
              </Tooltip>
            </SubTitle>
          </ActivitySubContent>
          <SubText>
            <audio controls>
              <source type='audio/mp3' src={item?.comment} />
            </audio>
          </SubText>
        </ActivityContentView>
      </>
    ),
    [userDetails?.dateFormat]
  );

  const RenderSetMilestone = useCallback(
    ({ item, dueTime }: { item: DataUpdatedCommentsInterface; dueTime: string }) => (
      <>
        <Editicon>
          <SVGIcon
            name='activity-milestone-icon'
            width='14'
            height='14'
            viewBox='0 0 14 14'
            className='milestone-icon'
          />
        </Editicon>
        <ActivityContentView>
          <ActivitySubContent>
            <SubTitle>
              {item?.user?.name}
              <Text>
                added this task in milestone <Text>{item?.group}.</Text>
              </Text>
              <Tooltip
                placement='bottom'
                maxWidth={'max-content'}
                title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}>
                <Text>{dueTime}</Text>
              </Tooltip>
            </SubTitle>
          </ActivitySubContent>
        </ActivityContentView>
      </>
    ),
    [userDetails?.dateFormat]
  );

  const RenderAddLabel = useCallback(
    ({ item, dueTime }: { item: DataUpdatedCommentsInterface; dueTime: string }) => (
      <>
        <Editicon>
          <SVGIcon name='activity-label-icon' width='14' height='14' viewBox='0 0 14 14' className='label-icon' />
        </Editicon>
        <ActivityContentView>
          <ActivitySubContent>
            <SubTitle>
              {item?.user?.name}
              <Text>
                added label <Text>{item?.group}</Text>.
              </Text>
              <Tooltip
                placement='bottom'
                maxWidth={'max-content'}
                title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}>
                <Text>{dueTime}</Text>
              </Tooltip>
            </SubTitle>
          </ActivitySubContent>
        </ActivityContentView>
      </>
    ),
    [userDetails?.dateFormat]
  );

  const RenderRemoveLabel = useCallback(
    ({ item, dueTime }: { item: DataUpdatedCommentsInterface; dueTime: string }) => (
      <>
        <Editicon>
          <SVGIcon name='activity-label-icon' width='14' height='14' viewBox='0 0 14 14' className='label-icon' />
        </Editicon>
        <ActivityContentView>
          <ActivitySubContent>
            <SubTitle>
              {item?.user?.name}
              <Text>
                removed label <Text>{item?.group}</Text>.
              </Text>
              <Tooltip
                placement='bottom'
                maxWidth={'max-content'}
                title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}>
                <Text>{dueTime}</Text>
              </Tooltip>
            </SubTitle>
          </ActivitySubContent>
        </ActivityContentView>
      </>
    ),
    [userDetails?.dateFormat]
  );

  const RenderChangeStatusId = useCallback(
    ({ item, dueTime }: { item: DataUpdatedCommentsInterface; dueTime: string }) => (
      <>
        <Statusicon>{renderActivityStatusIcon(item?.priority || 1)}</Statusicon>
        <ActivityContentView>
          <ActivitySubContent>
            <SubTitle>
              {item?.isGithub ? 'Github' : item?.user?.name}
              <Text>
                changed status from <Text>{item?.previousGroup}</Text> to <Text>{item?.group}</Text>.
              </Text>
              <Tooltip
                placement='bottom'
                maxWidth={'max-content'}
                title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}>
                <Text>{dueTime}</Text>
              </Tooltip>
            </SubTitle>
          </ActivitySubContent>
        </ActivityContentView>
      </>
    ),
    [renderActivityStatusIcon, userDetails?.dateFormat]
  );

  const renderActivity = useCallback(
    (item: DataUpdatedCommentsInterface, dueTime: string) => {
      if (!item) return null;

      const reactionCounts = getReactionCounts(item?.Reactions);

      const commentTypeToComponent = {
        [COMMENT_TYPE.SET_DUE_DATE]: <RenderSetDueDate item={item} dueTime={dueTime} />,
        [COMMENT_TYPE.CHANGE_DUE_DATE]: <RenderChangeDueDate item={item} dueTime={dueTime} />,
        [COMMENT_TYPE.ADD_ASSIGNEE]: <RenderAddAssignee item={item} dueTime={dueTime} />,
        [COMMENT_TYPE.REMOVE_ASSIGNEE]: <RenderRemoveAssignee item={item} dueTime={dueTime} />,
        [COMMENT_TYPE.UNASSIGN]: <RenderUnassign item={item} dueTime={dueTime} />,
        [COMMENT_TYPE.CHANGE_PRIORITY]: <RenderChangePriority item={item} dueTime={dueTime} />,
        [COMMENT_TYPE.CHANGE_GROUP]: <RenderChangeGroup item={item} dueTime={dueTime} />,
        [COMMENT_TYPE.UPDATE_TASK]: <RenderUpdateTask item={item} dueTime={dueTime} />,
        [COMMENT_TYPE.REMOVE_DUE_DATE]: <RenderRemoveDueDate item={item} dueTime={dueTime} />,
        [COMMENT_TYPE.STATUS_CHANGE]: <RenderStatusChange item={item} dueTime={dueTime} />,
        [COMMENT_TYPE.ESTIMATE_TIME]: <RenderSetEstimate item={item} dueTime={dueTime} />,
        [COMMENT_TYPE.AUDIO]: <RenderAudio item={item} dueTime={dueTime} />,
        [COMMENT_TYPE.SET_MILESTONE]: <RenderSetMilestone item={item} dueTime={dueTime} />,
        [COMMENT_TYPE.ADD_LABEL]: <RenderAddLabel item={item} dueTime={dueTime} />,
        [COMMENT_TYPE.REMOVE_LABEL]: <RenderRemoveLabel item={item} dueTime={dueTime} />,
        [COMMENT_TYPE.STATUS_ID_CHANGE]: <RenderChangeStatusId item={item} dueTime={dueTime} />,
        [COMMENT_TYPE.COMMENT]: (
          <div id={item?.id}>
            <RenderCommentHeader item={item} dueTime={dueTime} />
            <RenderReply item={item} />
            <RenderCommentBody item={item} />
            <RenderReactions item={item} reactionCounts={reactionCounts} />
          </div>
        )
      };

      // Return the corresponding component based on the type or null if not found
      return commentTypeToComponent[item?.type || COMMENT_TYPE.COMMENT] || null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [RenderReactions, RenderCommentBody]
  );

  const attachmentPage = (isCommentModal: boolean) => {
    setIsCommentModal(isCommentModal);
    const withoutParentData = projectAllFiles?.filter((x) => isEmpty(x?.parentId));
    dispatch(setProjectFiles(withoutParentData));
    if (mobile) {
      setSelectFile(true);
    } else {
      setAttachment(true);
    }
  };

  const closeEmojiDropdown = () => {
    setOpenEmoji(false);
    setSelectedMessage('');
  };

  const handleOpenChange = (e: React.SyntheticEvent, itemId: string) => {
    e.stopPropagation();
    if (selectedMesaage === itemId) {
      setOpenEmoji(false);
      setSelectedMessage(null);
    } else {
      setOpenEmoji(true);
      setSelectedMessage(itemId);
    }
  };

  useEffect(() => {
    const dropdown = document.querySelector('.dropdown-content');
    if (dropdown && dropdown instanceof HTMLElement) {
      const rect = dropdown.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;

      if (rect.bottom + 10 > windowHeight) {
        dropdown.style.top = `-${rect.height + 35}px`;
      } else {
        dropdown.style.top = 'auto';
      }
    }
  }, [openEmoji]);

  const handleProjectChange = (value: boolean) => {
    setIsProjectChanged(value);
  };

  const onClickBackIcon = useCallback(() => {
    if (isTaskDetailPreview && onClosePreviewModal) {
      onClosePreviewModal();
      history.push('/');
    } else if (isProjectChanged) {
      history.push(`/projects/details/${currentProject?.id}/tasks`);
    } else if (history.length > 1) {
      history.goBack(); // Check if there's enough history to go back
    } else {
      history.push('/'); // Default navigation to home if no history exists
    }
  }, [currentProject?.id, history, isProjectChanged, isTaskDetailPreview, onClosePreviewModal]);

  const onClickProjectName = useCallback(() => {
    history.push(`/projects/details/${currentProject?.id}/tasks`);
  }, [currentProject?.id, history]);

  const onClickStopAndStartTime = useCallback(async () => {
    try {
      setAlertLoading(true);
      const date1 = new Date(trackingActivity[0]?.TimerStartedAt).getTime();
      const date2 = new Date().getTime();
      const timeDifference = date2 - date1; // Difference in milliseconds
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const itemId = trackingActivity[0]?.['_id'];

      const historyUpdated = trackingActivity[0]?.History?.map((history) => {
        const { __typename, ...other } = history;
        return other;
      });

      const historyHoursSum = trackingActivity[0]?.History?.reduce((total, historyItem) => {
        return total + (historyItem.Hours || 0);
      }, 0);

      const payloadUpdate = {
        Hours: Number(Number(historyHoursSum || 0) + hoursDifference),
        TimerStartedAtUnset: true,
        History: [
          ...(historyUpdated || []),
          {
            _id: nanoid(),
            CreatedBy: userDetails?.id,
            CreatedTime: new Date().toISOString(),
            UpdatedBy: userDetails?.id,
            UpdatedTime: new Date().toISOString(),
            TimerStartedAt: trackingActivity[0]?.TimerStartedAt,
            TimerEndedAt: new Date().toISOString(),
            Hours: hoursDifference
          }
        ]
      };
      await dispatch(updateTimeLogDetails(itemId, payloadUpdate));

      const payload = {
        project_id: currentProject?.id,
        task_id: taskId,
        date: getUTCDate(new Date()).toISOString(),
        hours: 0,
        startTime: true
      };
      const result = await dispatch(addTimeLogDetails(payload));
      if (result) {
        dispatch(startTracker(taskId));
        setAlertModalOpen(false);
        dispatch(setSuccessMessage('Time Started!'));
        await dispatch(getTimelogData(projectId, taskId));
        await dispatch(getActiveTime());
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
      setAlertLoading(false);
    } finally {
      setAlertLoading(false);
    }
  }, [currentProject?.id, dispatch, projectId, taskId, trackingActivity]);

  const sortedComments = taskDetails?.updatedComments?.slice(0).sort((a, b) => {
    const dateA: Date = new Date(a.createdOn);
    const dateB: Date = new Date(b.createdOn);

    return dateA.getTime() - dateB.getTime();
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenConfirmationModal = (isCheckBox: boolean) => {
    setIsCheckBox(isCheckBox);
    setIsConfirmationModelOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModelOpen(false);
  };

  // handle click for common dropdown
  const handleClick = useCallback(
    (key: number | string) => {
      key = Number(key);
      if (key === MENU_OPTIONS.EDIT) {
        onClickEdit(taskDetails);
      } else if (key === MENU_OPTIONS.ADDLINK) {
        openModel();
      } else if (key === MENU_OPTIONS.CLONE) {
        onClickClone(taskDetails);
      } else if (key === MENU_OPTIONS.DELETE) {
        Opendeletemodel(taskDetails?.id);
      }
    },
    [onClickClone, onClickEdit, openModel, taskDetails]
  );

  const onChangeComment = useCallback((item: TaskDetailsInterface) => {
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
    const newComment = {
      _id: nanoid(),
      CreatedBy: userDetails?.id,
      CreatedTime: new Date().toISOString(),
      UpdatedBy: userDetails?.id,
      UpdatedTime: new Date().toISOString(),
      Type: COMMENT_TYPE.STATUS_CHANGE,
      Status: true
    };
    const previousUpdatedComment =
      !isEmpty(item?.updatedComments) && item?.updatedComments !== undefined ? item?.updatedComments : [];
    const updatedComments = [
      ...previousUpdatedComment,
      {
        id: newComment['_id'],
        type: COMMENT_TYPE.STATUS_CHANGE,
        updatedBy: userDetails?.id,
        updatedTime: new Date().toISOString(),
        createdOn: new Date().toISOString(),
        user: { ...userDetails, name: userDetails?.given_name },
        status: true
      }
    ];
    return { updatedComments };
  }, []);

  const onClickConfirm = useCallback(() => {
    setConfirmationLoading(true);
    try {
      for (const subTask of subTaskList) {
        if (subTask?.status === false) {
          const taskDetailsClone = JSON.parse(JSON.stringify(subTask));
          taskDetailsClone.status = true;
          dispatch(updatSubTaskList(taskDetailsClone));
          const payloadTask = { status: true, projectId: subTask?.projectId };
          dispatch(updateTaskDetails(subTask?.id, payloadTask));
          dispatch(
            createNewComment(subTask?.id, {
              Type: COMMENT_TYPE.STATUS_CHANGE,
              Status: true
            })
          );
        }
      }
      const commentData = onChangeComment(taskDetails);
      const payloadTask = { status: true, projectId: taskDetails?.projectId };
      dispatch(updateMyTaskDetailsData({ propsName: 'status', value: true }));
      dispatch(updateMyTaskDetailsData({ propsName: 'updatedComments', value: commentData?.updatedComments }));
      dispatch(updateTaskDetails(taskDetails?.id, payloadTask));
      dispatch(
        createNewComment(taskDetails?.id, {
          Type: COMMENT_TYPE.STATUS_CHANGE,
          Status: true
        })
      );
      trackAnalyticActivity(TASK_ANALYTICS.MARKED_DONE);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setConfirmationLoading(false);
      setIsConfirmationModelOpen(false);
    }
  }, [dispatch, onChangeComment, subTaskList, taskDetails]);

  const onChangeStatus = useCallback(
    async (
      status: StatusListInterface,
      previousStatus: StatusListInterface,
      task: TaskDetailsInterface,
      isFromSubTask = false,
      isFromParentTask = false
    ) => {
      const payloadTask = { statusId: status['_id'], projectId: task?.projectId };
      if (isFromSubTask) {
        const taskDetailsClone = JSON.parse(JSON.stringify(task));
        taskDetailsClone.statusId = status['_id'];
        dispatch(updatSubTaskList(taskDetailsClone));
      } else if (isFromParentTask) {
        const taskDetailsClone = JSON.parse(JSON.stringify(task));
        taskDetailsClone.statusId = status['_id'];
        setParentTask(taskDetailsClone);
      } else {
        dispatch(updateMyTaskDetailsData({ propsName: 'statusId', value: status['_id'] }));
      }

      if (!isFromSubTask) {
        const newComment = {
          type: COMMENT_TYPE.STATUS_ID_CHANGE,
          group: status?.Name,
          previousGroup: previousStatus?.Name,
          priority: status?.Type
        };
        const updatedComment = updateSortedComment(newComment);
        dispatch(
          updateMyTaskDetailsData({
            propsName: 'updatedComments',
            value: updatedComment
          })
        );
      }

      const result = await dispatch(updateTaskDetails(task?.id, payloadTask));
      if (result) {
        await dispatch(
          createNewComment(task?.id, {
            Type: COMMENT_TYPE.STATUS_ID_CHANGE,
            Group: status?.Name,
            PreviousGroup: previousStatus?.Name,
            Priority: status?.Type
          })
        );
        trackAnalyticActivity(TASK_ANALYTICS.STATUS_CHANGED);
      }
    },
    [dispatch, updateSortedComment]
  );

  const onUpdateStatus = useCallback(
    async (
      status: StatusListInterface,
      previousStatus: StatusListInterface,
      task: TaskDetailsInterface,
      isFromSubTask = false,
      isFromParentTask = false
    ) => {
      if (status['_id'] && status['_id'] !== previousStatus['_id']) {
        let allSubTasksStatus = true;
        if (subTaskList?.length > 0 && !isFromSubTask) {
          for (const subTask of subTaskList) {
            if (subTask?.status === false) {
              allSubTasksStatus = false;
              break;
            }
          }
        }
        if (status?.Type === STATUS_TYPE.COMPLETED && !allSubTasksStatus) {
          handleOpenConfirmationModal(false);
          setStatus(status);
          setPreviousStatus(previousStatus);
          setCurrentTask(task);
          return;
        }
        onChangeStatus(status, previousStatus, task, isFromSubTask, isFromParentTask);
      }
    },
    [onChangeStatus, subTaskList]
  );

  const handleMenuClick = useCallback(
    (key: number | string, item: DataUpdatedCommentsInterface) => {
      key = Number(key);
      if (key === MENU_OPTIONS.EDIT) {
        editCommentData(item);
      } else if (key === MENU_OPTIONS.DELETE) {
        removeComment(item.id);
      }
      setSelectedMessage(null);
      setIsCommentDropdownOpen(false);
    },
    [editCommentData, removeComment]
  );

  const processSubTask = useCallback(
    async (subTask: TaskDetailsInterface) => {
      if (subTask?.status === false && status) {
        const payloadTask = { statusId: status['_id'], projectId: currentProject?.id };
        const result = await dispatch(updateTaskDetails(subTask?.id, payloadTask));
        if (result) {
          await dispatch(
            createNewComment(subTask?.id, {
              Type: COMMENT_TYPE.STATUS_ID_CHANGE,
              Group: status?.Name,
              PreviousGroup: previousStatus?.Name,
              Priority: status?.Type
            })
          );
          const taskDetailsClone = { ...(subTask || {}), statusId: status['_id'] };
          dispatch(updatSubTaskList(taskDetailsClone));
          trackAnalyticActivity(TASK_ANALYTICS.STATUS_CHANGED);
        }
      }
    },
    [currentProject?.id, dispatch, previousStatus?.Name, status]
  );

  const onClickConfirmStatus = useCallback(async () => {
    setConfirmationLoading(true);
    try {
      if (status && previousStatus) {
        if (subTaskList?.length > 0 && status?.Type === STATUS_TYPE?.COMPLETED) {
          for (const subTask of subTaskList) {
            await processSubTask(subTask);
          }
        }
        if (currentTask) {
          onChangeStatus(status, previousStatus, currentTask);
        }
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setConfirmationLoading(false);
      closeConfirmationModal();
    }
  }, [currentTask, onChangeStatus, previousStatus, processSubTask, status, subTaskList]);

  const onClickNo = useCallback(async () => {
    setCancelLoading(true);
    try {
      if (isCheckBox) {
        const commentData = onChangeComment(taskDetails);
        const payloadTask = { status: true, projectId: taskDetails?.projectId };
        dispatch(updateMyTaskDetailsData({ propsName: 'status', value: true }));
        dispatch(updateMyTaskDetailsData({ propsName: 'updatedComments', value: commentData?.updatedComments }));
        dispatch(updateTaskDetails(taskDetails?.id, payloadTask));
        dispatch(
          createNewComment(taskDetails?.id, {
            Type: COMMENT_TYPE.STATUS_CHANGE,
            Status: true
          })
        );
        trackAnalyticActivity(TASK_ANALYTICS.MARKED_DONE);
      } else {
        const payloadTask = { statusId: status?.['_id'], projectId: currentProject?.id };
        const result = await dispatch(updateTaskDetails(taskDetails?.id, payloadTask));
        if (result) {
          await dispatch(
            createNewComment(taskDetails?.id, {
              Type: COMMENT_TYPE.STATUS_ID_CHANGE,
              Group: status?.Name,
              PreviousGroup: previousStatus?.Name,
              Priority: status?.Type
            })
          );
          trackAnalyticActivity(TASK_ANALYTICS.STATUS_CHANGED);
          dispatch(getMyTaskDetailsData(taskDetails?.id));
        }
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setCancelLoading(false);
      setIsConfirmationModelOpen(false);
    }
  }, [currentProject?.id, dispatch, isCheckBox, onChangeComment, previousStatus?.Name, status, taskDetails]);

  const scrollToInline = () => {
    if (inlineScrollRef?.current) {
      const rect = inlineScrollRef.current.getBoundingClientRect();
      const isInView =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth);

      if (!isInView) {
        inlineScrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const onReplyClick = useCallback((item: DataUpdatedCommentsInterface) => {
    setReplyMessage({
      id: item?.id,
      Message: item?.comment,
      Files: item?.Files,
      CreatedTime: item?.createdOn,
      Sender: item?.user?.name,
      Sender_id: item?.user?.id
    });
    scrollToInline();
  }, []);

  //get file type
  const getFileType = useCallback((uploadFileType?: number, extension?: string) => {
    if (uploadFileType === FILE_TYPES.DOCS) return 'docs';
    if (uploadFileType === FILE_TYPES.WHITEBOARD) return 'white';
    return extension || '';
  }, []);

  const onSelectFile = useCallback(
    (item: any) => {
      if (!item) return;

      const { name, uploadFileType, parentId, id, size, url } = item;
      const extension = name?.split('.').pop();
      const isDocOrWhiteboard = uploadFileType === FILE_TYPES.DOCS || uploadFileType === FILE_TYPES.WHITEBOARD;

      let fileUrl = url;
      if (isDocOrWhiteboard) {
        fileUrl = `/projects/files/${projectId}/docs/${id}${!isEmpty(parentId) ? `?folder=${parentId}` : ''}`;
      }

      const sizeInMB = item?.size / (1024 * 1024);
      const sizeValue = sizeInMB >= 1 ? `${sizeInMB.toFixed(2)} MB` : `${(size / 1024).toFixed(2)} KB`;

      if (['png', 'jpeg', 'jpg'].includes(extension)) {
        inlineRef.current.addImageInContent(fileUrl);
      } else if (['mp4', 'mov', 'mkv', 'webm'].includes(extension)) {
        inlineRef.current.addRecordingCard(fileUrl, item?.name);
      } else {
        const newObj: CommentFilesInterface = {
          name,
          href: fileUrl || '',
          size: sizeValue,
          file_type: getFileType(item?.uploadFileType, extension),
          isCreatedDoc: true,
          uploadFileType: Number(item?.uploadFileType)
        };
        setUploadedFiles([...uploadedFiles, newObj]);
        if (!isOpenComment) onFileChange([...uploadedFiles, newObj]);
      }
    },
    [getFileType, isOpenComment, onFileChange, projectId, uploadedFiles]
  );

  //function will handle copy task link
  const handleCopyTaskLink = useCallback(async () => {
    try {
      const baseUrl = window.location.origin;
      const taskUrl = `${baseUrl}/projects/details/${taskDetails?.projectId}/tasks?&task=${taskDetails?.id}`;
      await copy(taskUrl);
      dispatch(setSuccessMessage(`Task URL copied successfully!`));
    } catch (error) {
      captureException(error);
      console.log('Failed to copy task url: ', error);
    }
  }, [dispatch, taskDetails?.id, taskDetails?.projectId]);

  //function will handle copy task number
  const handleCopyTaskNumber = useCallback(async () => {
    try {
      if (taskDetails?.taskNumber) {
        await copy(`task-${taskDetails?.taskNumber}`);
        dispatch(setSuccessMessage(`"task-${taskDetails?.taskNumber}" copied successfully!`));
      }
    } catch (error) {
      captureException(error);
      console.log('Failed to copy task number: ', error);
    }
  }, [dispatch, taskDetails?.taskNumber]);

  //format string of github branch
  const formatString = useCallback((input: string) => {
    return (input ?? '')
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^[-]+/, '')
      .replace(/[-]+$/, '');
  }, []);

  // Function will copy branch name in clipboard
  const handleCopyGitBranch = useCallback(async () => {
    try {
      const userName = userDetails?.email?.split('@')[0] || '';
      const branchName = `${formatString(userName)}/task-${taskDetails?.taskNumber}/${formatString(taskDetails?.name)}`;
      await copy(branchName);
      dispatch(setSuccessMessage('Branch copied successfully!'));
    } catch (error) {
      captureException(error);
      console.log('Failed to copy Github branch: ', error);
    }
  }, [dispatch, formatString, taskDetails, userDetails?.email]);

  const renderDropdown = () => {
    return (
      <>
        {menuItems.map((item) => (
          <DropdownItem
            label={item.label}
            key={item?.key}
            tone={item.label === 'Delete' ? 'critical' : 'primary'}
            iconName={item.iconName}
            iconTone={item.label === 'Add link' ? 'fill' : 'stroke'}
            iconSize={16}
            iconViewBox='0 0 16 16'
            onClick={() => handleClick(item.key)}
          />
        ))}
      </>
    );
  };

  const renderMoreMenuItems = useCallback(
    (item: DataUpdatedCommentsInterface) => {
      return dropdownMenuItems.map((menuItem) => (
        <DropdownItem
          key={menuItem.key}
          label={menuItem.label}
          onClick={() => handleMenuClick(menuItem.key, item)}
          tone={menuItem.label === 'Delete' ? 'critical' : 'primary'}
        />
      ));
    },
    [handleMenuClick]
  );

  if (selectFile) {
    return (
      <RenderExistingFile
        setAttachment={() => setAttachment(false)}
        setSelectFile={(value: boolean) => setSelectFile(value)}
        onSelectFile={onSelectFile}
      />
    );
  }

  if (isTaskDetailPreview && taskDetails?.id !== taskId) {
    return <AppLoading />;
  }

  return (
    <>
      <Group isTaskDetailPreview={isTaskDetailPreview}>
        <Header className='reaponsiveheader'>
          <HeaderTitle>
            <BackIcon onClick={onClickBackIcon}>
              <SVGIcon
                name='report-left-arrow-icon'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                className='arrow-icon'
              />
            </BackIcon>
            <Projectname onClick={onClickProjectName}>{currentProject?.name}</Projectname>
          </HeaderTitle>
          <div>
            {/* responsive dropdown and header */}
            <>
              <Icons>
                <IconPoint onClick={handleCopyTaskLink}>
                  <SVGIcon
                    name='github-attachment-icon'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    className='github-icons'
                  />
                </IconPoint>
                <IconPoint onClick={handleCopyTaskNumber}>
                  <SVGIcon
                    name='github-copy-cut-item-icon'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    className='github-icons'
                  />
                </IconPoint>
                {currentProject?.githubRepository && (
                  <IconPoint onClick={handleCopyGitBranch}>
                    <SVGIcon
                      name='github-branch-icon'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      className='github-icons'
                    />
                  </IconPoint>
                )}
                <Dropdown
                  content={
                    <IconPoint>
                      <SVGIcon
                        className='dot-icon'
                        name='task-detail-dot-icon'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                      />
                    </IconPoint>
                  }
                  trigger='click'>
                  {renderDropdown()}
                </Dropdown>
              </Icons>
            </>
          </div>
        </Header>
        <LeftBar isTaskDetailPreview={isTaskDetailPreview}>
          <LeftBarView>
            <Header className='webheader' isTaskDetailPreview={isTaskDetailPreview}>
              <TaskContainer>
                <HeaderTitle>
                  <BackIcon onClick={onClickBackIcon}>
                    <SVGIcon
                      name='report-left-arrow-icon'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      className='arrow-icon'
                    />
                  </BackIcon>
                  <Projectname onClick={onClickProjectName}>{currentProject?.name}</Projectname>
                </HeaderTitle>

                <Icons>
                  <IconPoint onClick={handleCopyTaskLink}>
                    <SVGIcon
                      name='github-attachment-icon'
                      width='26'
                      height='26'
                      viewBox='0 0 24 24'
                      className='github-icons'
                    />
                  </IconPoint>
                  <IconPoint onClick={handleCopyTaskNumber}>
                    <SVGIcon
                      name='github-copy-cut-item-icon'
                      width='26'
                      height='26'
                      viewBox='0 0 24 24'
                      className='github-icons'
                    />
                  </IconPoint>
                  {currentProject?.githubRepository && (
                    <IconPoint onClick={handleCopyGitBranch}>
                      <SVGIcon
                        name='github-branch-icon'
                        width='26'
                        height='26'
                        viewBox='0 0 24 24'
                        className='github-icons'
                      />
                    </IconPoint>
                  )}
                  <Dropdown
                    content={
                      <IconPoint>
                        <SVGIcon
                          className='dot-icon'
                          name='task-detail-dot-icon'
                          width='26'
                          height='26'
                          viewBox='0 0 24 24'
                        />
                      </IconPoint>
                    }
                    trigger='click'>
                    {renderDropdown()}
                  </Dropdown>
                </Icons>
              </TaskContainer>
            </Header>
            <HeaderBlock isOverflowHidden={isBubbleMenuOpen}>
              <TaskDetailContainer isTaskDetailPreview={isTaskDetailPreview}>
                <TaskInfo
                  parentTask={parentTask}
                  setParentTask={setParentTask}
                  setFigmaRefreshLoading={setFigmaRefreshLoading}
                  taskId={taskId}
                  isTaskDetailPreview={isTaskDetailPreview}
                  figmaRefreshLoading={figmaRefreshLoading}
                  setSubTaskOpen={setSubTaskOpen}
                  loadSubTasksData={loadSubTasksData}
                  setOpen={setOpen}
                  handleOpenConfirmationModal={handleOpenConfirmationModal}
                  onUpdateStatus={onUpdateStatus}
                  loadData={loadData}
                  Opendeletemodel={Opendeletemodel}
                  onClickEdit={onClickEdit}
                  onClickClone={onClickClone}
                  subTaskOpen={subTaskOpen}
                />

                {mobile && (
                  <RightBlock
                    taskId={taskId || ''}
                    loadData={loadData}
                    onUpdateStatus={onUpdateStatus}
                    setIsMilestonmodelopen={setIsMilestonmodelopen}
                    isTaskDetailPreview={isTaskDetailPreview}
                    updateSortedComment={(value) => updateSortedComment(value)}
                  />
                )}
                <Activity>
                  <ActivityTitle ref={inlineScrollRef}>Activity</ActivityTitle>
                  <ActivityView isTaskDetailPreview={isTaskDetailPreview}>
                    <ItemBlock>
                      {!isEmpty(taskDetails?.createdBy?.name) && (
                        <ItemChange>
                          <Avatar
                            imgSrc={taskDetails?.createdBy?.profile_photo ? taskDetails?.createdBy?.profile_photo : ''}
                            name={taskDetails?.createdBy?.name ? taskDetails?.createdBy?.name : 'U N'}
                            size={24}
                            isNotBorder={true}
                            isZindex={true}
                          />
                          <ActivityContentView>
                            <ActivitySubContent>
                              <SubTitle>
                                {taskDetails?.createdBy?.name}
                                <Text>created the task.</Text>
                                <Tooltip
                                  placement='bottom'
                                  maxWidth={'max-content'}
                                  title={moment(taskDetails.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}>
                                  <Text>{moment(taskDetails.createdOn).fromNow()}</Text>
                                </Tooltip>
                              </SubTitle>
                            </ActivitySubContent>
                          </ActivityContentView>
                        </ItemChange>
                      )}
                      {sortedComments?.map((item, index) => {
                        const dueTime = moment(item?.createdOn).fromNow();
                        if (item?.isArchived) return <React.Fragment key={item?.id}></React.Fragment>;
                        [buttonDropdownRef, emojiButtonRef].forEach((ref) => {
                          if (!ref.current[index]) {
                            ref.current[index] = React.createRef<HTMLDivElement>();
                          }
                        });
                        return (
                          <React.Fragment key={item?.id}>
                            <ItemChange
                              key={item?.id}
                              isHover={item?.type === COMMENT_TYPE.COMMENT}
                              isApplyBottomSpacing={
                                sortedComments?.length > index + 1 &&
                                item?.type === COMMENT_TYPE.COMMENT &&
                                sortedComments?.[index + 1]?.type !== COMMENT_TYPE.COMMENT
                              }
                              isActive={
                                ((openEmoji || isCommentDropdownOpen) && selectedMesaage === item?.id) ||
                                selectedMesaage === item?.id
                              }>
                              {item?.type !== COMMENT_TYPE.COMMENT &&
                                sortedComments?.[index - 1]?.type !== COMMENT_TYPE.COMMENT && <Borderdiv />}
                              {renderActivity(item, dueTime)}
                              {item?.type === COMMENT_TYPE.COMMENT &&
                                !deletedMessage?.some((deletedItem) => deletedItem?.id === item?.id) && (
                                  <>
                                    <RightSection className='rightsection'>
                                      <Portion>
                                        <>
                                          <ReactionDiv
                                            ref={emojiButtonRef?.current[index]}
                                            isActive={
                                              (openEmoji && selectedMesaage === item?.id) ||
                                              selectedMesaage === item?.id
                                            }
                                            onClick={(e) => handleOpenChange(e, item?.id)}>
                                            <SVGIcon
                                              name='reaction-icon'
                                              width='18'
                                              height='18'
                                              viewBox='0 0 18 18'
                                              className='svgicon'
                                            />
                                            {openEmoji && selectedMesaage === item?.id && (
                                              <Emoji
                                                setIsOpenEmoji={setOpenEmoji}
                                                isOpenEmoji={openEmoji}
                                                setOpenDropdownId={setSelectedMessage}
                                                emojiButtonref={emojiButtonRef?.current[index]}
                                                onEmojiClick={(emojiData: EmojiClickData) =>
                                                  onReactOnMessage(emojiData, item)
                                                }
                                                isEmojiLoading={emojiLoading}
                                              />
                                            )}
                                          </ReactionDiv>
                                        </>
                                        <Replyicon onClick={() => onReplyClick(item)}>
                                          <SVGIcon
                                            name='reply-icon'
                                            width='18'
                                            height='18'
                                            viewBox='0 0 18 18'
                                            className='svgicon'
                                          />
                                        </Replyicon>
                                        {item?.user?.id === currentUser?.id && (
                                          <Dropdown
                                            activeClassName='active'
                                            content={
                                              <ReactionDiv onClick={() => setSelectedMessage(item?.id)}>
                                                <SVGIcon
                                                  name='comment-delete-icon'
                                                  width='18'
                                                  height='18'
                                                  viewBox='0 0 18 18'
                                                  fill='var(--text-secondary)'
                                                />
                                              </ReactionDiv>
                                            }
                                            trigger='click'
                                            onOutsideClick={() => setSelectedMessage(null)}>
                                            {renderMoreMenuItems(item)}
                                          </Dropdown>
                                        )}
                                      </Portion>
                                    </RightSection>
                                  </>
                                )}
                            </ItemChange>
                          </React.Fragment>
                        );
                      })}
                    </ItemBlock>
                    <CommentInputBox
                      disable={isOpenComment}
                      ref={inlineRef}
                      valueContent={comment}
                      setValueContent={(value: string) => handleValueChange(value)}
                      uploadedFiles={uploadedFiles}
                      setUploadedFiles={onFileChange}
                      onClickSelectFile={() => attachmentPage(false)}
                      openModal={() => setIsOpenComment(true)}
                      sendComment={(files: CommentFilesInterface[]) => onSendComment(files, comment)}
                      users={currentProject?.users?.filter((user) => !isEmpty(user?.id))}
                      replyMessage={replyMessage}
                      setReplyMessage={() => setReplyMessage({})}
                      propsLoading={loading}
                      setPropsLoading={(value: boolean) => setLoading(value)}
                      handleOpenSnapModal={handleOpenModal}
                    />
                  </ActivityView>
                </Activity>
              </TaskDetailContainer>
            </HeaderBlock>
          </LeftBarView>
        </LeftBar>
        {attachment && !isCommentModal && (
          <RenderExistingFile setAttachment={() => setAttachment(false)} onSelectFile={onSelectFile} />
        )}
        {attachment && isCommentModal && (
          <ConfigProvider
            theme={{
              token: {
                colorBgElevated: 'var(--background-primary)',
                colorBgMask: 'var(--background-overlay)'
              }
            }}>
            <Drawer
              placement='right'
              onClose={() => setAttachment(false)}
              open={attachment}
              width={'350px'}
              headerStyle={{ display: 'none' }}
              bodyStyle={{ padding: 0, scrollbarWidth: 'none' }}>
              <RenderExistingFile
                setAttachment={() => setAttachment(false)}
                isCreateTaskModal
                onSelectFile={onSelectFile}
              />
            </Drawer>
          </ConfigProvider>
        )}
        {((attachment && isCommentModal) || !attachment) && !mobile && (
          <RightBlock
            taskId={taskId || ''}
            loadData={loadData}
            onUpdateStatus={onUpdateStatus}
            setIsMilestonmodelopen={setIsMilestonmodelopen}
            isTaskDetailPreview={isTaskDetailPreview}
            updateSortedComment={(value) => updateSortedComment(value)}
          />
        )}
        <ModalCustom open={open} onClose={handleCancel} isCreateTask={true}>
          <CreateTaskModal
            loadData={isFromSubList ? loadSubTasksData : loadData}
            onCancel={handleCancel}
            istaskDetail={true}
            onProjectChange={(value: boolean) => handleProjectChange(value)}
            isTaskDetailPreview={isTaskDetailPreview}
            isFromSubList={isFromSubList}
          />
        </ModalCustom>
        <ModalCustom open={deleteModelopen} onClose={close} width={334}>
          <Deletemodal
            onClose={close}
            onOk={() => deleteTask()}
            loading={loading}
            modaltitle={`${
              (subTaskList?.length ?? 0) > 0 ? `Delete this task and ${subTaskList?.length} subtasks?` : 'Delete Task?'
            }`}
            description={`${
              (subTaskList?.length ?? 0) > 0
                ? 'This action will remove this task and all its connected subtasks.'
                : 'Are you sure you want to delete this task?'
            }`}
          />
        </ModalCustom>
        {isEstimateDropdownOpen && (
          <OverLayDiv className='dropdown-overlay' onClick={() => setIsEstimateDropdownOpen(false)} />
        )}
      </Group>
      <ModalCustom open={isOpenComment} onClose={Cancel}>
        {/* using tiptap editor */}
        <Commentmodal
          ref={inlineRef}
          onClose={Cancel}
          sendComment={(files: CommentFilesInterface[]) => onSendComment(files, comment, true)}
          users={currentProject?.users?.filter((user) => !isEmpty(user?.id))}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          valueContent={comment}
          setValueContent={(value: string) => setComment(value)}
          propsLoading={loading}
          setPropsLoading={(value: boolean) => setLoading(value)}
          onClickSelectFile={() => attachmentPage(true)}
          handleOpenSnapModal={handleOpenModal}
          disable={false}
        />
      </ModalCustom>
      <ModalCustom open={isMilestonemodelopen} onClose={isClosemodel} width={462}>
        <MilestoneModal onClose={isClosemodel} projectId={taskDetails?.projectId} />
      </ModalCustom>
      <ModalCustom open={alertModelOpen} onClose={closeAlertModal} width={334}>
        <ConfirmationModal
          loading={alertLoading}
          onOk={onClickStopAndStartTime}
          onClose={closeAlertModal}
          modaltitle='Another timer is running.'
          description='Do you want to stop and start the timer on this task?'
        />
      </ModalCustom>
      {isMediaRecordingSupported() && (
        <ModalCustom open={isModalOpen} onClose={handleCloseModal} width={258}>
          <SnapRecordModal handleCloseModal={handleCloseModal} />
        </ModalCustom>
      )}

      <ModalCustom open={isConfirmationModelOpen} onClose={closeConfirmationModal} width={334}>
        <ConfirmationModal
          loading={confirmationLoading}
          cancelLoading={cancelLoading}
          onOk={isCheckBox ? onClickConfirm : onClickConfirmStatus}
          onNo={onClickNo}
          onClose={closeConfirmationModal}
          modaltitle='Uncompleted Subtasks'
          description='Do you want to complete all these subtasks?'
        />
      </ModalCustom>
      <ModalCustom open={subTaskOpen} isSubtaskpopup onClose={() => setSubTaskOpen(!subTaskOpen)}>
        <SubtaskPopup
          defaultStatus={currentProject?.defaultStatus}
          statusList={currentProject?.statusData}
          onClose={() => setSubTaskOpen(!subTaskOpen)}
          currentProjectId={currentProject?.id}
          currentTaskId={taskDetails?.id}
          loadParentTaskData={loadData}
        />
      </ModalCustom>
      <ModalCustom open={isOpen} onClose={cancelModel} width={462}>
        <LinkFileModal onClose={cancelModel} modalData={LINK_FILE_DATA.ADDLINK} taskId={taskDetails?.id} />
      </ModalCustom>
    </>
  );
};

export default TaskDetail;
