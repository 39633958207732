import { Action, combineReducers } from 'redux';
import { createReducer } from '../helpers/reduxHelpers';
import { createReducer as createReducerOrig, PayloadAction, current } from '@reduxjs/toolkit';
import * as Actions from '../actions/types';
import { ReducerInterface } from '../interfaces/ReducerInterface';
import { ProjectTemplateDetailsInterface } from '../interfaces/SettingsInterface';
import { isEmpty } from '../helpers/common';
import { COLOR_THEME } from '../global/constants';

const setSettingLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_SETTING_LOADER
});

const initialState = {
  defaultTaskGroups: [{ name: 'Todo' }, { name: 'In Progress' }, { name: 'In Review' }, { name: 'Done' }],
  features: {
    discussion: true,
    files: true,
    invoices: true,
    notes: true
  },
  hideCompletedTask: false,
  taskType: 3
};
const projectSettingsReducer = createReducerOrig(initialState, (builder) => {
  builder
    .addCase(Actions.SET_PROJECT_SETTING_DETAIL, (state = initialState, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_PROJECT_SETTING_DETAIL, (state, action: PayloadAction<ReducerInterface, never>) => {
      const project = JSON.parse(JSON.stringify(current(state)));
      project[action.payload.propsName] = action.payload.value;
      return project;
    })
    .addCase(Actions.CLEAR_PROJECT_SETTING_DETAIL, (state, action: Action<any>) => {
      const project = JSON.parse(JSON.stringify(initialState));
      return project;
    });
});

const profileinitialState = {
  name: '',
  phone: '',
  time: '',
  date: 'DD/MM/YYYY'
};

const profileSettingsDataReducer = createReducerOrig(profileinitialState, (builder) => {
  builder
    .addCase(Actions.SET_PROFILE_SETTING_DATA, (state = profileinitialState, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_PROFILE_SETTING_DATA, (state, action: PayloadAction<ReducerInterface, never>) => {
      const profile = JSON.parse(JSON.stringify(current(state)));
      profile[action.payload.propsName] = action.payload.value;
      return profile;
    })
    .addCase(Actions.CLEAR_PROFILE_SETTING_DATA, (state, action: Action<any>) => {
      const profile = JSON.parse(JSON.stringify(profileinitialState));
      return profile;
    });
});

const initialUserState = {
  email: '',
  password: ''
};

const setEmailDetailReducer = createReducerOrig(initialUserState, (builder) => {
  builder
    .addCase(Actions.SET_EMAIL_DETAIL, (state = initialUserState, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_EMAIL_DETAIL, (state, action: PayloadAction<ReducerInterface, never>) => {
      const user = JSON.parse(JSON.stringify(current(state)));
      user[action.payload.propsName] = action.payload.value;
      return user;
    })
    .addCase(Actions.CLEAR_EMAIL_DETAIL, () => {
      const user = JSON.parse(JSON.stringify(initialUserState));
      return user;
    });
});

const initialthemeState = {
  theme: 'light'
};

const seThemeModeReducer = createReducerOrig(initialthemeState, (builder) => {
  builder.addCase(Actions.UPDATE_THEME_MODE, (state, action: PayloadAction<ReducerInterface, never>) => {
    const user = JSON.parse(JSON.stringify(current(state)));
    user[action.payload.propsName] = action.payload.value;
    return user;
  });
});

const appSettingsinitialState = {
  invoice: true,
  customer: true,
  timetracking: true,
  messages: true
};

const appSettingsReducer = createReducerOrig(appSettingsinitialState, (builder) => {
  builder.addCase(Actions.UPDATE_APP_SETTING_DATA, (state, action: PayloadAction<ReducerInterface, never>) => {
    const data = JSON.parse(JSON.stringify(current(state)));
    data[action.payload.propsName] = action.payload.value;
    return data;
  });
});

const apiKeyListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_API_KEY_LIST
});

const settingIntegrationReducer = createReducer({
  initialState: {},
  actionType: Actions.SET_SETTING_INTEGRATION
});

const subscriptionListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_SUBSCRIPTION_LIST
});

const initialInvoicingState = {
  name: '',
  address: '',
  note: '',
  tax: [
    {
      name: '',
      value: null
    }
  ],
  dueDays: null
};

const setInvoicingDetailReducer = createReducerOrig(initialInvoicingState, (builder) => {
  builder
    .addCase(Actions.SET_INVOICING_DETAIL, (state = initialInvoicingState, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_INVOICING_DETAIL, (state, action: PayloadAction<ReducerInterface, never>) => {
      const user = JSON.parse(JSON.stringify(current(state)));
      user[action.payload.propsName] = action.payload.value;
      return user;
    })
    .addCase(Actions.CLEAR_INVOICING_DETAIL, () => {
      const user = JSON.parse(JSON.stringify(initialInvoicingState));
      return user;
    });
});

const invoiceSettingReducer = createReducer({
  initialState: {},
  actionType: Actions.SET_INVOICE_SETTING
});

const initialTemplateInputState = {
  name: '',
  description: ''
};

const projectTemplateInputReducer = createReducerOrig(initialTemplateInputState, (builder) => {
  builder
    .addCase(
      Actions.SET_PROJECT_TEMPLATE_INPUT,
      (state = initialTemplateInputState, action: PayloadAction<any, never>) => {
        return { ...(action.payload || {}) };
      }
    )
    .addCase(Actions.UPDATE_PROJECT_TEMPLATE_INPUT, (state, action: PayloadAction<ReducerInterface, never>) => {
      const project = JSON.parse(JSON.stringify(current(state)));
      project[action.payload.propsName] = action.payload.value;
      return project;
    })
    .addCase(Actions.CLEAR_PROJECT_TEMPLATE_INPUT, () => {
      const project = JSON.parse(JSON.stringify(initialTemplateInputState));
      return project;
    });
});

const projectTemplateListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_PROJECT_TEMPLATE_LIST
});

const initialTemplateState = {
  updated: false
};

const projectTemplateDetailsReducer = createReducerOrig(initialTemplateState, (builder) => {
  builder
    .addCase(
      Actions.SET_PROJECT_TEMPLATE_DETAILS,
      (state = initialTemplateState, action: PayloadAction<any, never>) => {
        return { ...(action.payload || {}), updated: false };
      }
    )
    .addCase(Actions.UPDATE_PROJECT_TEMPLATE_DETAILS, (state, action: PayloadAction<ReducerInterface, never>) => {
      const project = JSON.parse(JSON.stringify(current(state)));
      project[action.payload.propsName] = action.payload.value;
      let updatedValue = true;
      if (action.payload.propsName === 'updated') {
        updatedValue = action.payload.value;
      }
      return { ...project, updated: updatedValue };
    })
    .addCase(Actions.ADD_TASK_IN_PROJECT_TEMPLATE, (state, action: PayloadAction<ReducerInterface, never>) => {
      const project = JSON.parse(JSON.stringify(current(state)));
      project['tasks'] = [...(project?.tasks || []), action.payload];
      return { ...project, updated: true };
    })
    .addCase(
      Actions.UPDATE_TASK_IN_PROJECT_TEMPLATE,
      (state, action: PayloadAction<{ taskId: string; value: any }, never>) => {
        const project: ProjectTemplateDetailsInterface = JSON.parse(JSON.stringify(current(state)));
        const updatedTasks = project?.tasks?.map((x) => {
          if (x?.id === action.payload?.taskId) return action?.payload?.value;
          else return x;
        });
        return { ...project, tasks: updatedTasks, updated: true };
      }
    )
    .addCase(Actions.CLEAR_PROJECT_TEMPLATE_DETAILS, () => {
      return { ...initialTemplateState, updated: false };
    });
});

const templateTaskGroupsReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_TEMPLATE_TASK_GROUPS
});

const templateFilteredTasksListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_TEMPLATE_FILTERED_TASK_LIST
});

const templateOtherTasksReducer = createReducer({
  initialState: { tasks: [] },
  actionType: Actions.SET_TEMPLATE_OTHER_TASKS
});

const initialBrandDomainDetailState = {
  primaryDarkColor: '#00A16F',
  primaryColor: '#00A16F',
  faviconIcon: '',
  logo: '',
  metaTitle: '',
  domain: ''
};

const BrandDomainDetailReducer = createReducerOrig(initialBrandDomainDetailState, (builder) => {
  builder
    .addCase(
      Actions.SET_BRAND_DOMAIN_DETAILS,
      (state = initialBrandDomainDetailState, action: PayloadAction<any, never>) => {
        return {
          ...(action.payload || {}),
          primaryColor: !isEmpty(action.payload?.primaryColor) ? action.payload?.primaryColor : '#00A16F'
        };
      }
    )
    .addCase(Actions.UPDATE_BRAND_DOMAIN_DETAILS, (state, action: PayloadAction<ReducerInterface, never>) => {
      const brandDomain = JSON.parse(JSON.stringify(current(state)));
      brandDomain[action.payload.propsName] = action.payload.value;
      return brandDomain;
    })
    .addCase(Actions.CLEAR_BRAND_DOMAIN_DETAILS, () => {
      const brandDomain = JSON.parse(JSON.stringify(initialBrandDomainDetailState));
      return brandDomain;
    });
});
const referralInitialState = {
  totalReferrals: 0
};

const referralDetailReducer = createReducer({
  initialState: referralInitialState,
  actionType: Actions.SET_REFERRAL_DETAILS
});

const initialNotificationState = {
  Browser: false,
  Email: false,
  Mobile: false
};

const notificationSettingDetailsReducer = createReducerOrig(initialNotificationState, (builder) => {
  builder
    .addCase(
      Actions.SET_NOTIFICATIONS_SETTING_DETAIL,
      (state = initialNotificationState, action: PayloadAction<any, never>) => {
        return { ...(action.payload || {}) };
      }
    )
    .addCase(Actions.UPDATE_NOTIFICATIONS_SETTING_DETAIL, (state, action: PayloadAction<ReducerInterface, never>) => {
      const user = JSON.parse(JSON.stringify(current(state)));
      user[action.payload.propsName] = action.payload.value;
      let updatedValue = true;
      if (action.payload.propsName === 'updated') {
        updatedValue = action.payload.value;
      }
      return { ...user, updated: updatedValue };
    })
    .addCase(Actions.CLEAR_NOTIFICATIONS_SETTING_DETAIL, () => {
      const count = JSON.parse(JSON.stringify(initialNotificationState));
      return count;
    });
});

const taskTemplateReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_TASK_TEMPLATE_LIST
});
const tasktemplateinitialState = {
  templateName: '',
  description: '',
  name: '',
  priority: 0,
  users: [],
  Files: []
};

const tasktemplateDataReducer = createReducerOrig(tasktemplateinitialState, (builder) => {
  builder
    .addCase(Actions.SET_TASK_TEMPLATE_DATA, (state = tasktemplateinitialState, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_TASK_TEMPLATE_DATA, (state, action: PayloadAction<ReducerInterface, never>) => {
      const input = JSON.parse(JSON.stringify(current(state)));
      input[action.payload.propsName] = action.payload.value;
      return input;
    })
    .addCase(Actions.CLEAR_TASK_TEMPLATE_DATA, (state, action: Action<any>) => {
      const input = JSON.parse(JSON.stringify(tasktemplateinitialState));
      return input;
    });
});

const themeTypeReducer = createReducer({
  initialState: COLOR_THEME.LIGHT,
  actionType: Actions.SET_THEME_TYPE
});

const githubAccountLisReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_GITHUB_ACCOUNT_LIST
});

const githubRepositoryListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_GITHUB_REPOSITORY_LIST
});

const filteredGithubRepoListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_FILTERED_GITHUB_REPO_LIST
});

const securityAccessListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_SECURITY_ACCESS_LIST
});

const settingReducer = combineReducers({
  loading: setSettingLoaderReducer,
  projectSettings: projectSettingsReducer,
  profileData: profileSettingsDataReducer,
  emailDetail: setEmailDetailReducer,
  apiKeyList: apiKeyListReducer,
  themeMode: seThemeModeReducer,
  settingIntegration: settingIntegrationReducer,
  appSettings: appSettingsReducer,
  subscriptionList: subscriptionListReducer,
  invoicingDetail: setInvoicingDetailReducer,
  invoiceSetting: invoiceSettingReducer,
  projectTemplateInput: projectTemplateInputReducer,
  projectTemplateList: projectTemplateListReducer,
  projectTemplateDetails: projectTemplateDetailsReducer,
  templateTaskGroups: templateTaskGroupsReducer,
  templateFilteredTasksList: templateFilteredTasksListReducer,
  templateOtherTasks: templateOtherTasksReducer,
  brandDomainDetails: BrandDomainDetailReducer,
  referralDetails: referralDetailReducer,
  notificationSettingDetails: notificationSettingDetailsReducer,
  taskTemplateData: tasktemplateDataReducer,
  taskTemplateList: taskTemplateReducer,
  themeType: themeTypeReducer,
  githubAccountList: githubAccountLisReducer,
  githubRepositoryList: githubRepositoryListReducer,
  filteredGithubRepoList: filteredGithubRepoListReducer,
  securityAccessList: securityAccessListReducer
});

export default settingReducer;
