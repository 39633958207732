import { ActionCreatorWithOptionalPayload, ActionCreatorWithPayload, createAction, Dispatch } from '@reduxjs/toolkit';
import * as Actions from './types';
import {
  ApiKeyListInterface,
  AppSettingInterface,
  BrandDomainDetailsInterface,
  GithubRepositoryList,
  GitHubUser,
  InvoicingDetailsInterface,
  ProfileDataInterface,
  ProjectSettingInterface,
  ProjectTemplateDetailsInterface,
  ProjectTemplateListInterface,
  ReferralDetailsInterface,
  SecurityAccessListInterface,
  SettingIntegrationInterface,
  SubscriptionDetailsInterface,
  TaskInProjectTemplateInterface,
  TaskTemplateDataInterface,
  UpdateThemeInterface
} from '../interfaces/SettingsInterface';
import { PropsNameWithValueInterface, ReducerInterface } from '../interfaces/ReducerInterface';
import {
  FilteredCompletedTasksListInterface,
  FilteredTasksListInterface,
  TaskGroupInterface
} from '../interfaces/TaskInterface';

/**
 * @desc Set Project loader
 */
export const setSettingLoader: ActionCreatorWithPayload<boolean, string> = createAction(Actions.SET_SETTING_LOADER);

/**
 * @desc Set Project settings detail
 */
export const setProjectSettingsDetail: ActionCreatorWithPayload<ProjectSettingInterface, string> = createAction(
  Actions.SET_PROJECT_SETTING_DETAIL
);

/**
 * @desc Update Project settings detail
 */
export const updateProjectSettingsDetail: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_PROJECT_SETTING_DETAIL
);

/**
 * @desc Clear Project settings detail
 */
export const clearProjectSettingsDetail: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_PROJECT_SETTING_DETAIL
);

/**
 * @desc update user  data
 */
export const setEmailDetail: ActionCreatorWithPayload<ProfileDataInterface, string> = createAction(
  Actions.SET_EMAIL_DETAIL
);

/**
 * @desc update user  data
 */
export const updateEmailDetail: ActionCreatorWithPayload<PropsNameWithValueInterface, string> = createAction(
  Actions.UPDATE_EMAIL_DETAIL
);

/**
 * @desc clear user  data
 */
export const clearEmailDetail: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_EMAIL_DETAIL
);

/**
 * @desc set profile settings data
 */
export const setProfileSettingsData: ActionCreatorWithPayload<ProfileDataInterface, string> = createAction(
  Actions.SET_PROFILE_SETTING_DATA
);

/**
 * @desc update profile settings data
 */
export const updateProfileSettingsData: ActionCreatorWithPayload<PropsNameWithValueInterface, string> = createAction(
  Actions.UPDATE_PROFILE_SETTING_DATA
);

/**
 * @desc clear profile settings data
 */
export const clearProfileSettingsData: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_PROFILE_SETTING_DATA
);

/**
 * @desc Set Api Key List
 */
export const setApiKeyList: ActionCreatorWithPayload<ApiKeyListInterface[], string> = createAction(
  Actions.SET_API_KEY_LIST
);

/**
 * @desc Update Theme Mode
 */
export const updateThemeMode: ActionCreatorWithPayload<UpdateThemeInterface, string> = createAction(
  Actions.UPDATE_THEME_MODE
);

/**
 * @desc update user  data
 */
export const setSettingIntegrationData: ActionCreatorWithPayload<SettingIntegrationInterface, string> = createAction(
  Actions.SET_SETTING_INTEGRATION
);

/**
 * @desc Update App setting Data
 */
export const updateAppSettings: ActionCreatorWithPayload<AppSettingInterface, string> = createAction(
  Actions.UPDATE_APP_SETTING_DATA
);

/**
 * @desc Set Subscription List
 */
export const setSubscriptionList: ActionCreatorWithPayload<SubscriptionDetailsInterface[], string> = createAction(
  Actions.SET_SUBSCRIPTION_LIST
);

/**
 * @desc Set Invoicing detail
 */
export const setInvoicingDetail: ActionCreatorWithPayload<InvoicingDetailsInterface, string> = createAction(
  Actions.SET_INVOICING_DETAIL
);

/**
 * @desc Set Invoicing detail
 */
export const setInvoiceSeting: ActionCreatorWithPayload<InvoicingDetailsInterface, string> = createAction(
  Actions.SET_INVOICE_SETTING
);

/**
 * @desc Update Invoicing detail
 */
export const updateInvoicingDetail: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_INVOICING_DETAIL
);

/**
 * @desc Clear Invoicing detail
 */
export const clearInvoicingDetail: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_INVOICING_DETAIL
);

/**
 * @desc set Project Template Input
 */
export const setProjectTemplateInput: ActionCreatorWithPayload<ProjectTemplateListInterface, string> = createAction(
  Actions.SET_PROJECT_TEMPLATE_INPUT
);

/**
 * @desc Update Project Template Input
 */
export const updateProjectTemplateInput: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_PROJECT_TEMPLATE_INPUT
);

/**
 * @desc Clear Project Template Input
 */
export const clearProjectTemplateInput: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_PROJECT_TEMPLATE_INPUT
);

/**
 * @desc set Project Template Input
 */
export const setProjectTemplateDetails: ActionCreatorWithPayload<ProjectTemplateDetailsInterface | {}, string> =
  createAction(Actions.SET_PROJECT_TEMPLATE_DETAILS);

/**
 * @desc Update Project Template Input
 */
export const updateProjectTemplateDetails: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_PROJECT_TEMPLATE_DETAILS
);

/**
 * @desc add task in  Project Template Input
 */
export const addTaskInProjectTemplate: ActionCreatorWithPayload<TaskInProjectTemplateInterface, string> = createAction(
  Actions.ADD_TASK_IN_PROJECT_TEMPLATE
);

/**
 * @desc Update task in  Project Template Input
 */
export const updateTaskInProjectTemplate: ActionCreatorWithPayload<
  { taskId: string | undefined; value: TaskInProjectTemplateInterface },
  string
> = createAction(Actions.UPDATE_TASK_IN_PROJECT_TEMPLATE);

/**
 * @desc Clear Project Template Input
 */
export const clearProjectTemplateDetails: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_PROJECT_TEMPLATE_DETAILS
);

/**
 * @desc Set Project Template List
 */
export const setProjectTemplateList: ActionCreatorWithPayload<ProjectTemplateDetailsInterface[], string> = createAction(
  Actions.SET_PROJECT_TEMPLATE_LIST
);

/**
 * @desc Set Project Template List
 */
export const setTemplateTaskGroups: ActionCreatorWithPayload<TaskGroupInterface[], string> = createAction(
  Actions.SET_TEMPLATE_TASK_GROUPS
);

/**
 * @desc Set Project Template List
 */
export const setTemplateFilteredTasksList: ActionCreatorWithPayload<FilteredTasksListInterface[], string> =
  createAction(Actions.SET_TEMPLATE_FILTERED_TASK_LIST);

/**
 * @desc Set Project Template List
 */
export const setTemplateOtherTasks: ActionCreatorWithPayload<FilteredCompletedTasksListInterface | [], string> =
  createAction(Actions.SET_TEMPLATE_OTHER_TASKS);

/**
 * @desc Set Brand domain detail
 */
export const setBrandDomainDetails: ActionCreatorWithPayload<BrandDomainDetailsInterface, string> = createAction(
  Actions.SET_BRAND_DOMAIN_DETAILS
);

/**
 * @desc Update Brand domain detail
 */
export const updateBrandDomainDetails: ActionCreatorWithPayload<
  { propsName: string; value: BrandDomainDetailsInterface | string },
  string
> = createAction(Actions.UPDATE_BRAND_DOMAIN_DETAILS);

/**
 * @desc Clear Brand domain detail
 */
export const clearBrandDomainDetails: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_BRAND_DOMAIN_DETAILS
);
/**
 * @desc Set referral Details
 */
export const setReferralDetails: ActionCreatorWithPayload<ReferralDetailsInterface | {}, string> = createAction(
  Actions.SET_REFERRAL_DETAILS
);

/**
 * @desc set Notifications settings details
 */
export const setNotificationsSettingsDetails: ActionCreatorWithPayload<ProfileDataInterface, string> = createAction(
  Actions.SET_NOTIFICATIONS_SETTING_DETAIL
);

/**
 * @desc update Notifications settings details
 */
export const updateNotificationsSettingsDetails: ActionCreatorWithPayload<PropsNameWithValueInterface, string> =
  createAction(Actions.UPDATE_NOTIFICATIONS_SETTING_DETAIL);

/**
 * @desc clear Notifications settings details
 */
export const clearNotificationsSettingsDetails: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_NOTIFICATIONS_SETTING_DETAIL
);

/**
 * @desc Set task template  List
 */
export const setTaskTemplateList: ActionCreatorWithPayload<TaskTemplateDataInterface[], string> = createAction(
  Actions.SET_TASK_TEMPLATE_LIST
);
/**
 * @desc set task template
 */
export const setTaskTemplateData: ActionCreatorWithPayload<TaskTemplateDataInterface, string> = createAction(
  Actions.SET_TASK_TEMPLATE_DATA
);

/**
 * @desc update task template
 */
export const updateTaskTemplateData: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_TASK_TEMPLATE_DATA
);

/**
 * @desc clear task temlplate
 */
export const clearTaskTemplateData: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_TASK_TEMPLATE_DATA
);

/**
 * @desc Set Theme Type
 */
export const setThemeType: ActionCreatorWithPayload<number, string> = createAction(Actions.SET_THEME_TYPE);

/**
 * @desc Set Github account list
 */
export const setGithubAccountList: ActionCreatorWithPayload<GitHubUser[], string> = createAction(
  Actions.SET_GITHUB_ACCOUNT_LIST
);

/**
 * @desc Set Github repository list
 */
export const setGithubRepositoryList: ActionCreatorWithPayload<GithubRepositoryList[], string> = createAction(
  Actions.SET_GITHUB_REPOSITORY_LIST
);

/**
 * @desc Set filtered Github repository list
 */
export const setFilteredGithubRepositoryList: ActionCreatorWithPayload<GithubRepositoryList[], string> = createAction(
  Actions.SET_FILTERED_GITHUB_REPO_LIST
);

/**
 * @desc Set security access list
 */
export const setSecurityAccessList: ActionCreatorWithPayload<SecurityAccessListInterface[], string> = createAction(
  Actions.SET_SECURITY_ACCESS_LIST
);

/**
 * @desc Clear Setting Data
 */
export const clearSettingData = () => (dispatch: Dispatch) => {
  dispatch(setSettingLoader(false));
  dispatch(clearProjectSettingsDetail());
  dispatch(clearEmailDetail());
  dispatch(clearProfileSettingsData());
  dispatch(setApiKeyList([]));
  dispatch(setSubscriptionList([]));
  dispatch(clearInvoicingDetail());
  dispatch(clearProjectTemplateInput());
  dispatch(clearBrandDomainDetails());
  dispatch(setReferralDetails({}));
  dispatch(setReferralDetails({}));
  dispatch(clearNotificationsSettingsDetails());
  dispatch(clearTaskTemplateData());
  // dispatch(setThemeType(COLOR_THEME.LIGHT));
  dispatch(setGithubAccountList([]));
  dispatch(setGithubRepositoryList([]));
};
