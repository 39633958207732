import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import TiptapEditor from '../../tiptapEditor';
import { CommentFilesInterface } from '../../../interfaces/TaskInterface';
import { CommentInputSection, FileBox, Icon, IconSection, Uploaddiv } from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import RecordingComment from '../../comment/recordingComment';
import { EmojiClickData } from 'emoji-picker-react';
import { handleFileUpload, isMediaRecordingSupported, removeFileFromFirebase } from '../../../helpers/common';
import Button from '../../button';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { captureException } from '../../../services/logService';
import { setErrorMessage } from '../../../actions/messageActions';
import CommentDropdown from '../../dropdowns/commentDropdown/commentdropdown';
import { UserInterface } from '../../../interfaces/chatMessageInterface';
import { ReplyMessageDataInterface } from '../../../interfaces/MessageInterface';
import Emoji from '../../emojiPicker/emojiPicker';
import { RenderFilePreview } from '../../renderFilePreview';
import ModalCustom from '../../models/modal';
import InsertGifModal from '../../models/insertGifModal';
import { Dropdown } from '../../Dropdown';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';

interface Props {
  valueContent: string;
  setValueContent: (value: string) => void;
  handleOpenSnapModal: () => void;
  setUploadedFiles: any;
  sendComment: (files: CommentFilesInterface[]) => void;
  uploadedFiles: CommentFilesInterface[];
  disable: boolean;
  onClickSelectFile: () => void;
  users: UserInterface[];
  openModal: () => void;
  replyMessage: any;
  setReplyMessage: (value: ReplyMessageDataInterface) => void;
  propsLoading: boolean;
  setPropsLoading: (value: boolean) => void;
}
const CommentInputBox = forwardRef((props: Props, ref) => {
  const {
    valueContent,
    setValueContent,
    handleOpenSnapModal,
    setUploadedFiles,
    sendComment,
    uploadedFiles,
    disable = false,
    onClickSelectFile,
    users,
    replyMessage,
    setReplyMessage,
    propsLoading,
    setPropsLoading
  } = props || {};
  // Ref variables
  const editorRef = useRef<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);
  // State variables
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [isEmojiDropdownOpen, setIsEmojiDropdownOpen] = useState(false);
  const [openGifModal, setIsOpenGifModal] = useState(false);
  // use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { project: projectSelector } = stateSelector || {};
  const { uploadFilePercent } = projectSelector;
  //Other variables
  const dispatch = useDispatch();

  // Ref functions which are call from parent component
  useImperativeHandle(ref, () => ({
    addRecordingCard(fileUrl: string, fileName: string) {
      editorRef.current.addRecordingCard(fileUrl, fileName);
    },
    addContent(text: string) {
      editorRef.current.addContent(text);
    },
    addImageInContent(fileUrl: string) {
      editorRef.current.addImage(fileUrl);
    },
    clearContent() {
      editorRef?.current?.clearValue();
    }
  }));

  // Function call when upload snap recording card
  const uploadSnapEmbed = useCallback((firebaseUrl: string, fileName: string, videoId: string) => {
    editorRef?.current?.addRecordingCard(firebaseUrl, fileName, videoId);
  }, []);

  // On select emoji
  const handleEmojiSelected = useCallback((emojiData: EmojiClickData) => {
    const currentEmoji = UserPreferenceSingleton.getInstance().getUserUseEmoji();
    const updatedEmojis = [
      emojiData?.emoji,
      ...(Array.isArray(currentEmoji) ? currentEmoji.filter((e) => e !== emojiData?.emoji) : [])
    ].slice(0, 2);
    UserPreferenceSingleton.getInstance().setUserUseEmoji(updatedEmojis);
    editorRef.current.addContentExistingLine(emojiData.emoji);
  }, []);

  const openEmojiDropdown = useCallback((value: boolean) => {
    setIsEmojiDropdownOpen(value);
  }, []);

  const onSendComment = useCallback(() => {
    setUploadedFiles([]);
    setValueContent('');
    sendComment(uploadedFiles);
  }, [sendComment, setUploadedFiles, setValueContent, uploadedFiles]);

  const onFileUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (!files || files?.length === 0) {
        dispatch(setErrorMessage('No files selected.'));
        return;
      }
      try {
        setLoading(true);
        const fileArray = Array.from(files); // Convert FileList to Array
        //call common function to upload file in firebase
        const updatedFiles = await handleFileUpload(uploadedFiles || [], fileArray, dispatch, editorRef);
        const value = [...(uploadedFiles || []), ...(updatedFiles || [])];
        setUploadedFiles(value);
      } catch (error) {
        captureException(error);
        dispatch(setErrorMessage('Error occurred while uploading files.'));
      } finally {
        setLoading(false);
      }
    },
    [dispatch, uploadedFiles, setUploadedFiles]
  );

  const onClickClose = useCallback(
    (index: number, file: CommentFilesInterface) => {
      if (file && !file.uploadFileType && file.href.startsWith('https://firebasestorage.googleapis'))
        removeFileFromFirebase(file.href, dispatch);
      const filesClone = JSON.parse(JSON.stringify(uploadedFiles));
      filesClone.splice(index, 1);
      setUploadedFiles(filesClone);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    },
    [dispatch, uploadedFiles, setUploadedFiles]
  );

  const handleFileInput = useCallback(() => {
    queueMicrotask(() => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    });
  }, []);

  const addDroppedFiles = useCallback(
    (files: CommentFilesInterface[]) => {
      setUploadedFiles((prevFiles: CommentFilesInterface[]) => [...(prevFiles || []), ...(files || [])]);
    },
    [setUploadedFiles]
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      event.preventDefault(); // Prevent default behavior if needed
      onSendComment();
    }
  };

  const handleOpenGifModal = useCallback(() => {
    setIsOpenGifModal(true);
  }, []);

  const handleCloseGifModal = useCallback(() => {
    setIsOpenGifModal(false);
  }, []);

  return (
    <>
      <CommentInputSection onKeyDown={handleKeyDown}>
        <TiptapEditor
          ref={editorRef}
          valueContent={valueContent}
          setValueContent={setValueContent}
          setLoading={(value: boolean) => {
            if (setPropsLoading) setPropsLoading(value);
            else setLoading(value);
          }}
          setStateLoading={(value: boolean) => setLoading(value)}
          addUploadedFiles={addDroppedFiles}
          replyMessage={replyMessage}
          setReplyMessage={setReplyMessage}
          placeHolder='Leave a comment...'
          users={users}
          recordSnapModalOpen={handleOpenSnapModal}
          handleFileInput={handleFileInput}
          handleGifModal={handleOpenGifModal}
        />

        <input type='file' ref={fileInputRef} style={{ display: 'none' }} onChange={onFileUpload} multiple />
        {!disable && uploadedFiles?.length > 0 && (
          <FileBox>
            {uploadedFiles?.length > 0 &&
              uploadedFiles?.map((file: CommentFilesInterface, index: number) => {
                return (
                  <>
                    <RenderFilePreview
                      key={file?.href}
                      file={file}
                      isClose={true}
                      maxWidth={240}
                      onClose={() => onClickClose(index, file)}
                    />
                  </>
                );
              })}
          </FileBox>
        )}
        {loading && <Uploaddiv>Uploading ({Math.floor(uploadFilePercent)}%)</Uploaddiv>}
        <IconSection>
          <Dropdown
            activeClassName='active'
            content={
              <Icon>
                <SVGIcon
                  name='comment-attachment-icon'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  className='svg-icon'
                />
              </Icon>
            }>
            <CommentDropdown onClickUploadFile={handleFileInput} onClickSelectFile={onClickSelectFile} />
          </Dropdown>

          {isMediaRecordingSupported() && (
            <RecordingComment
              setLoading={(value: boolean) => setLoading(value)}
              fieldValue={valueContent}
              setValueContent={(value: string) => setValueContent(value)}
              iscommentModel={true}
              setButtonDisable={(value: boolean) => setButtonDisable(value)}
              handleOpenModal={handleOpenSnapModal}
              uploadSnapEmbed={uploadSnapEmbed}
            />
          )}
          <Icon ref={emojiPickerRef} onClick={() => openEmojiDropdown(!isEmojiDropdownOpen)}>
            <SVGIcon name='comment-emoji-icon' width='16' height='16' viewBox='0 0 16 16' className='svg-icon' />
            {isEmojiDropdownOpen && (
              <Emoji
                isOpenEmoji={isEmojiDropdownOpen}
                onEmojiClick={handleEmojiSelected}
                setIsOpenEmoji={setIsEmojiDropdownOpen}
                emojiButtonref={emojiPickerRef}
              />
            )}
          </Icon>
          <Button
            title='Send'
            smallbutton={true}
            onClick={onSendComment}
            disabled={loading || propsLoading || buttonDisable}
            commentbtn={true}
            size={'small'}
          />
        </IconSection>
      </CommentInputSection>
      <ModalCustom
        open={openGifModal}
        onClose={handleCloseGifModal}
        width={686}
        hasNotBoxShadow={true}
        isGifModal={true}>
        <InsertGifModal
          setSelectedGif={(url: string) => {
            editorRef.current.addImage(url);
          }}
          closeModal={handleCloseGifModal}
        />
      </ModalCustom>
    </>
  );
});

export default CommentInputBox;
