import axios from 'axios';
import { AnyAction, Dispatch } from 'redux';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import {
  setApiKeyList,
  setEmailDetail,
  setInvoiceSeting,
  setProfileSettingsData,
  setProjectSettingsDetail,
  setSettingLoader,
  setSubscriptionList,
  setProjectTemplateList,
  setProjectTemplateDetails,
  setReferralDetails,
  setNotificationsSettingsDetails,
  setTaskTemplateData,
  setTaskTemplateList,
  setSettingIntegrationData,
  setSecurityAccessList
} from '../actions/settingActions';
import { getAPIErrorReason, getCurrentDomain, isDev, isEmpty } from '../helpers/common';
import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import {
  AppSettingDetailsInterface,
  BrandDomainDetailsInterface,
  EmailDetailInterface,
  InviteNewUserInterface,
  InvoicingDetailsInterface,
  NotificationSettingDetailsInterface,
  ProfileDataInterface,
  ProjectSettingInterface,
  ProjectTemplateDetailsInterface,
  TaskTemplateDataInterface
} from '../interfaces/SettingsInterface';
import { UsersWorkspaceInterface, WorkspaceInterfase } from '../interfaces/WorkspaceInterface';
import { REACT_APP_SUBSCRIPTION_PRICE_ID, REACT_APP_TEAMCAMP_APIURL } from '../global/environment';
import { trackAnalyticActivity } from './analyticsService';
import { captureException } from './logService';
import { setUserProfileData } from '../actions/authActions';
import { DEFAULT_DATE_FORMAT } from '../global/constants';
import { StatusListInterface } from '../interfaces/ProjectInterface';
import { WORKSPACE_ANALYTICS } from '../global/analyticsConstants';

/**
 * @desc Project - Get project settings list
 * @param {*}
 */
export const getProjectSettingsData: any = (workspace_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const params = {
      companyId: workspace_id
    };
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/company/getSettingProjectDetails`, {
      params
    });
    const { data } = response;
    if (data) {
      const projectFeature = {
        discussion: data?.ProjectFeatures?.Discussion,
        files: data?.ProjectFeatures?.Files,
        invoices: data?.ProjectFeatures?.Invoices,
        notes: data?.ProjectFeatures?.Notes
      };
      const groups = data?.DefaultTaskGroups?.map((item: string) => {
        return { name: item };
      });
      const updatedData = {
        defaultTaskGroups: groups,
        features: projectFeature,
        hideCompletedTask: data?.HideCompletedTask,
        taskType: data?.TaskType
      };
      dispatch(setProjectSettingsDetail(updatedData));
      return updatedData;
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get project setting details please try again';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return undefined;
  } finally {
    await dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Project - Get project list
 * @param {*}
 */
export const updateProjectSettings: any =
  (workspace_id: string, data: ProjectSettingInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setSettingLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const payload = {
        user_id: userDetails?.id,
        company_id: workspace_id,
        data
      };
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/company/putProjectSeting`, payload);
      if (response) {
        dispatchSettingSuccess(response.data.message, dispatch);
        return true;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update project settings please try again';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc team - invite user
 * @param {*}
 */
export const inviteNewUser: any = (payloadData: InviteNewUserInterface) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
    const payload = {
      ...payloadData,
      userId: userDetails?.id,
      isDev: isDev()
    };
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/company/InviteUsers`, payload);
    if (response?.data?.error) {
      dispatchSettingError(response.data.error, dispatch);
      return true;
    } else if (response) {
      dispatchSettingSuccess(response.data.message, dispatch);
      return response?.data?.data;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to invite user please try again';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc team - delete user
 * @param {*}
 */
export const deleteInvitedUser: any =
  (workspace_id: string, user: UsersWorkspaceInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setSettingLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const params = {
        companyId: workspace_id,
        userId: userDetails?.id,
        deleteUserId: user?.id
      };
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/company/removeCompanyUser`, {
        params
      });
      if (response) {
        trackAnalyticActivity(WORKSPACE_ANALYTICS.USER_REMOVED);
        dispatchSettingSuccess(response.data.message, dispatch);
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to delete invited user please try again';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc setting - Get profile settings data
 * @param {*}
 */
export const getProfileSettingData: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
    const params = {
      user_id: userDetails?.id
    };
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/User/getUserDetails`, { params });
    const { data } = response;
    if (data) {
      const updatedData = {
        ...data,
        dateFormat: !isEmpty(data?.dateFormat) ? data?.dateFormat : DEFAULT_DATE_FORMAT
      };
      dispatch(setProfileSettingsData(updatedData));
      dispatch(setEmailDetail(updatedData));
      dispatch(setUserProfileData(updatedData));
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get profile setting data please try again';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc setting - update profile setting data
 * @param {*}
 */
export const updateProfileSetting: any =
  (payloadData: ProfileDataInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setSettingLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const payload = {
        ...payloadData,
        userId: userDetails?.id
      };
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/User/updateProfile`, payload);
      if (response) {
        if (response.data.message) {
          dispatch(setSuccessMessage(response.data.message));
          return true;
        } else if (response.data.error) {
          dispatch(setErrorMessage(response.data.error));
          return false;
        }
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update profile setting data please try again';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc profile - update user email
 * @param {*}
 */
export const updateEmailData: any = (payloadData: EmailDetailInterface) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
    const payload = {
      ...payloadData,
      userId: userDetails?.id
    };
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/User/changeEmail`, payload);
    if (response) {
      dispatch(setSuccessMessage(response.data.message));
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to update email details please try again';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Setting - Change role of User
 * @param {*}
 */
export const changeUserAdmin: any =
  (workspace_id: string, user_id: UsersWorkspaceInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setSettingLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const payload = {
        user_id: userDetails?.id,
        company_id: workspace_id,
        target_user_id: user_id
      };
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/company/putUserAdmin`, payload);
      if (!isEmpty(response?.data?.error)) {
        dispatchSettingError(response?.data?.error, dispatch);
      } else if (response?.data?.message) {
        dispatchSettingSuccess(response?.data?.message, dispatch);
        return true;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to change user role please try again';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc Setting - Stripe Redirect
 * @param {*}
 */
export const stripeRedirect: any =
  (
    paramsObj: {
      user_id: string;
      account_id: string;
      install_signature: string;
      stripe_user_id: string | undefined;
      state: string;
      livemode: boolean;
    },
    workspaceId: string
  ) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setSettingLoader(true));
      const params: {
        user_id: string;
        account_id: string;
        state?: string;
        install_signature: string;
        workspace_id: string;
        stripe_user_id: string;
        livemode: boolean;
      } = {
        user_id: paramsObj?.user_id || '',
        account_id: paramsObj?.account_id || '',
        state: paramsObj?.state,
        install_signature: paramsObj?.install_signature || '',
        workspace_id: workspaceId,
        stripe_user_id: paramsObj?.stripe_user_id || '',
        livemode: paramsObj?.livemode
      };
      const response: any = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/stripe/stripeRedirect`, {
        params
      });
      if (response.data.error) {
        dispatchSettingError(response.data.error, dispatch);
        return false;
      }
      return true;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to set stripe data';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc Setting - Stripe Disconnect
 * @param {*}
 */
export const disconnectStripe: any = (workspace_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
    const params = {
      userId: userDetails?.id,
      companyId: workspace_id
    };
    const response: any = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/stripe/disconnectStripeAccount`, {
      params
    });
    if (response.data.error) {
      dispatchSettingError(response?.data?.error, dispatch);
      return false;
    }
    return true;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to disconnect stripe account';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Setting - get API Key List
 * @param {*}
 */
export const getApiKeyList: any = (workspace_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const params = {
      companyId: workspace_id
    };
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/apiKey/getApiKeyList`, {
      params
    });
    const { data } = response;
    if (data) {
      dispatch(setApiKeyList(data));
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get API key list. Please try again!';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Setting - Create API key
 * @param {*}
 */
export const createApiKey: any = (workspace_id: string, title: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
    const payload = {
      userId: userDetails?.id,
      companyId: workspace_id,
      title
    };
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/apiKey/createApiKey`, payload);
    if (!isEmpty(response?.data?.error)) {
      dispatchSettingError(response?.data?.error, dispatch);
    } else if (response) {
      dispatchSettingSuccess(response?.data?.message, dispatch);
      return true;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to create API key please try again';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Setting - Delete API key
 * @param {*}
 */
export const deleteApiKey: any = (apiKey_Id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const params = {
      apyKeyId: apiKey_Id
    };
    const response = await axios.delete(`${REACT_APP_TEAMCAMP_APIURL}/apiKey/deleteApiKey`, { params });
    if (!isEmpty(response?.data?.error)) {
      dispatchSettingError(response?.data?.error, dispatch);
    } else if (response) {
      dispatchSettingSuccess(response?.data?.message, dispatch);
      return true;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to Delete key please try again';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Setting - Figma connect Redirect
 * @param {*}
 */
export const figmaConnectRedirect: any =
  (paramsObj: { code: string; state: string }, workspace_id: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setSettingLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const params: {
        userId: string;
        code: string;
        companyId: string;
      } = {
        userId: userDetails?.id || '',
        code: paramsObj?.code || '',
        companyId: workspace_id
      };
      const response: any = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/figma/figmaRedirect`, {
        params
      });
      if (response.data.error) {
        dispatchSettingError(response.data.error, dispatch);
        return false;
      }
      return true;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to set figma redirect details';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc Apps - upadte Apps Settings
 * @param {*}
 */
export const updateAppsSettings: any =
  (payload: AppSettingDetailsInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/company/putAppsSetting`, payload);
      if (response) {
        dispatchSettingSuccess('Updated successfully!', dispatch);
        return true;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update apps settings please try again';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return false;
    }
  };
/**
 * @desc Setting - get Subscription List
 * @param {*}
 */
export const getSubscriptionList: any = (workspace_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const params = {
      companyId: workspace_id
    };
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/admin/subscription/getActivePastSubscriptions`, {
      params
    });
    const { data } = response;
    if (data) {
      dispatch(setSubscriptionList(data));
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get subscription list. Please try again!';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Setting - Get invoice settings list
 * @param {*}
 */
export const getInvoicingSettingsData: any = (workspace_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/company/getPutInvoiceSetting/${workspace_id}`);
    const { data } = response;
    if (data) {
      dispatch(setInvoiceSeting(data));
      return data;
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get Invoicing details please try again';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Setting - update Invoicing Settings data
 * @param {*}
 */
export const updateInvoicingSettings: any =
  (workspace_id: string, payload: InvoicingDetailsInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setSettingLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      payload.userId = userDetails?.id;
      const response = await axios.put(
        `${REACT_APP_TEAMCAMP_APIURL}/company/getPutInvoiceSetting/${workspace_id}`,
        payload
      );
      if (response) {
        dispatchSettingSuccess(response.data.message, dispatch);
        return true;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update invoicing details please try again';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

export const createProjectTemplate: any =
  (workspace_id: string, name: string, description: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setSettingLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const payload = {
        name: name,
        companyId: workspace_id,
        description: description,
        userId: userDetails?.id
      };
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/projectTemplate/postProjectTemplate`, payload);
      if (response) {
        if (!isEmpty(response?.data?.error)) {
          dispatchSettingError(response?.data?.error, dispatch);
          return false;
        } else {
          dispatchSettingSuccess('Project template created succesfully!', dispatch);
          return true;
        }
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to create Project Template please try again';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

export const getProjectTemplateList: any =
  (workspace_id: string, isCreateProject = false) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setSettingLoader(true));
      const response = await axios.get(
        `${REACT_APP_TEAMCAMP_APIURL}/projectTemplate/getProjectTemplateList/${workspace_id}`
      );
      const { data } = response;
      if (data) {
        if (isCreateProject) {
          const updatedData = data?.map((x: { name: string }) => {
            return { ...x, value: x?.name };
          });
          dispatch(setProjectTemplateList(updatedData || []));
          return updatedData;
        } else {
          dispatch(setProjectTemplateList(data));
          return data;
        }
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get Project Template list';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

export const getProjectTemplateDetails: any =
  (workspace: WorkspaceInterfase, templateId: string, returnUpdatedData = true) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setSettingLoader(true));
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/projectTemplate/getProjectTemplate/${templateId}`);
      const { data } = response;
      if (data) {
        if (!returnUpdatedData) return data;
        const projectUsers = data?.projectUsers?.map((x: any) => {
          const user = workspace?.users?.find((y: any) => x?.['_id'] === y?.id);
          return user;
        });
        const defaultStatus = data?.statusData?.find((status: StatusListInterface) => status?.Default);
        const updatedData = {
          ...data,
          id: data?.['_id'],
          users: projectUsers,
          defaultStatus: defaultStatus || data?.Status?.[0] || {}
        };
        dispatch(setProjectTemplateDetails(updatedData || {}));
        return updatedData;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get Project Template details';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

export const saveProjectTemplateDetails: any =
  (payload: ProjectTemplateDetailsInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setSettingLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const response = await axios.put(
        `${REACT_APP_TEAMCAMP_APIURL}/projectTemplate/putProjectTemplate/${payload?.['_id']}`,
        { ...(payload || {}), userId: userDetails?.id }
      );
      const { data } = response;
      if (data) {
        dispatchSettingSuccess('Template updated succesfully', dispatch);
        return data;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to save project template details';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc Setting - Delete Project Template
 * @param {*}
 */
export const deleteProjectTemplate: any = (templateId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const response = await axios.delete(
      `${REACT_APP_TEAMCAMP_APIURL}/projectTemplate/deleteProjectTemplate/${templateId}`
    );
    if (!isEmpty(response?.data?.error)) {
      dispatchSettingError(response?.data?.error, dispatch);
    } else if (response) {
      dispatchSettingSuccess(response?.data, dispatch);
      return true;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to Delete template. please try again';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Setting - Get User Refer Code
 * @param {*}
 */
export const getUserReferCode: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/User/getUserRefCode`);
    const { data } = response;
    if (data) {
      return data;
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get user refer code. Please try again!';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Setting -> get Referral Details
 * @param {*}
 */
export const getReferralDetails: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/User/getReferralDetails`);
    const { data } = response;
    if (data) {
      dispatch(setReferralDetails(data));
      return data;
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get Referral Details. Please try again!';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Setting - Add Domain in project
 * @param {*}
 */
export const addDomainInVercelProject: any = (domain: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    if (isEmpty(domain)) return dispatchSettingError('Please valid domain URL!', dispatch);
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/branding/addVercelDomain`, { domain });
    const { data } = response;
    if (data) {
      return data;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to add domain in vercel. Please try again!';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Setting - Add Domain in project
 * @param {*}
 */
export const getBrandingDetails: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/branding/getBrandingDetails`);
    const { data } = response;
    if (data) {
      return data;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get branding details. Please try again!';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Setting - Add Domain in project
 * @param {*}
 */
export const updateBrandingDetails: any =
  (payload: BrandDomainDetailsInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setSettingLoader(true));
      const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/branding/updateBrandingDetails`, payload);
      const { data } = response;
      if (data) {
        dispatchSettingSuccess('Branding data updated successfully', dispatch);
        return data;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update branding details. Please try again!';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc Setting - Add Domain in project
 * @param {*}
 */
export const getDomainConfigurationDetails: any = (domain: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/branding/getDomainConfiguration/${domain}`);
    const { data } = response;
    if (data) {
      return data;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get domain config. Please try again!';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Setting - Add Domain in project
 * @param {*}
 */
export const removeVercelDomain: any = (domain: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    if (isEmpty(domain)) return dispatchSettingError('Please valid domain URL!', dispatch);
    const response = await axios.delete(`${REACT_APP_TEAMCAMP_APIURL}/branding/removeDomain/${domain}`);
    const { data } = response;
    if (data) {
      dispatchSettingSuccess('Domain removed successfully', dispatch);
      return data;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to remove domain in vercel. Please try again!';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setSettingLoader(false));
  }
};
/**
 * @desc Setting - Intergrations -> Disconnect figma account
 * @param {*}
 */
export const disconnectFigmaAccount: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/figma/disconnectFigma`);
    if (response) {
      dispatchSettingSuccess(response.data, dispatch);
      return true;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to disconnect figma account please try again';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Setting - Get notification setting details
 * @param {*}
 */
export const getNotificationSettingDetails: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/User/userNotificationSettings`);
    const { data } = response;
    if (data) {
      dispatch(setNotificationsSettingsDetails({ ...data, updated: false }));
      return data;
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get notification setting details. Please try again!';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Setting - Update notification setting details
 * @param {*}
 */
export const updateNotificationSettingDetails: any =
  (payload: NotificationSettingDetailsInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setSettingLoader(true));
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/User/userNotificationSettings`, payload);
      const { data } = response;
      if (data) {
        dispatch(setSuccessMessage('Notifications setting updated!'));
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update notification setting details. Please try again!';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc Setting - template - create new task template
 * @param {*}
 */
export const createNewTaskTemplate: any =
  (payload: TaskTemplateDataInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setSettingLoader(true));
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/taskTemplate`, payload);
      const { data } = response;
      if (data) {
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to create task template. Please try again!';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc Setting - template - get task template
 * @param {*}
 */
export const getTaskTemplateList: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/taskTemplate`);
    const { data } = response;
    if (data) {
      dispatch(setTaskTemplateList(data?.data));
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get task template list. Please try again!';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Setting -template - delete task Template
 * @param {*}
 */
export const deleteTaskTemplate: any = (templateId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const response = await axios.delete(`${REACT_APP_TEAMCAMP_APIURL}/taskTemplate/${templateId}`);
    if (!isEmpty(response?.data?.error)) {
      dispatchSettingError(response?.data?.error, dispatch);
    } else if (response) {
      dispatchSettingSuccess(response?.data, dispatch);
      return true;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to delete task template. please try again';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

export const updateTaskTemplateDetails: any =
  (taskId: string, payload: TaskTemplateDataInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setSettingLoader(true));
      const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/taskTemplate/${taskId}`, payload);
      const { data } = response;
      if (data) {
        dispatchSettingSuccess(' Task Template updated succesfully', dispatch);
        return data;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update task template details';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

export const getTaskTemplateDetails: any =
  (workspace: WorkspaceInterfase, templateId: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setSettingLoader(true));
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/taskTemplate/${templateId}`);
      const { data } = response?.data;
      if (data) {
        const assignedUsers = data?.Assignee?.map((assigneeId: string) =>
          workspace?.users?.find((user: any) => user.id === assigneeId)
        );
        const updatedData = {
          templateName: data?.Title,
          description: data?.Description,
          name: data?.TaskTitle,
          priority: data?.Priority || 0,
          users: assignedUsers,
          Files: data?.Attachment,
          _id: data?.['_id']
        };
        dispatch(setTaskTemplateData(updatedData));
        return true;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get Task Template details';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc get subscription url
 * @param {*}
 */
export const getSubscriptionUrl: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(setSettingLoader(true));
    const payload = {
      priceId: REACT_APP_SUBSCRIPTION_PRICE_ID,
      successUrl: `https://${getCurrentDomain()}/settings/subscription?status=success`
    };
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/stripe/getSubscriptionUrl`, payload);
    if (response?.data) {
      return response?.data;
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get subscription url';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Invoice - Get Company Stripe Account Status
 * @param {*}
 */
export const getCompanySettingIntegration: any = (workSpace_Id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/company/integrationStatus`, {
      headers: { workspace_id: workSpace_Id }
    });
    const { data } = response;
    if (data) {
      dispatch(setSettingIntegrationData(data));
      return data;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || `Unable to get Company Stripe Account Status please try again`;
    captureException(error);
    dispatchSettingError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc sentry connect
 * @param {*}
 */
export const sentryAccountConnect: any =
  (payload: { installationId: string; code: string }) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setSettingLoader(true));
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/sentry/bind`, payload);
      if (response) return true;
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to add sentry account! please try again';
      captureException(error, 'settings');
      dispatchSettingError(error, dispatch);
      return false;
    } finally {
      await dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc Setting - session list
 * @param {*}
 */
export const getSessionList: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));

    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/auth/getSessions`);
    const { data } = response;
    if (data) {
      dispatch(setSecurityAccessList(data));
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get session list. Please try again!';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Setting - session remove
 * @param {*}
 */
export const removeSession: any = (payload: { sessionId: string }) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/auth/revokeSession`, payload);
    if (response) {
      dispatchSettingSuccess('remove session successfully!', dispatch);
      return true;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to remove session list please try again';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return false;
  }
};
function dispatchSettingError(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setErrorMessage(msg));
}

function dispatchSettingSuccess(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setSuccessMessage(msg));
}
