import React, { useCallback, useEffect } from 'react';
import {
  Group,
  Content,
  Icon,
  Title,
  Formblock,
  Profile,
  Heading,
  Text,
  Subblock,
  Item,
  Label,
  Inputvalue,
  Textformat,
  FormContent,
  Endblock,
  ProfileView,
  ProfileContent,
  InputDiv,
  Input,
  IconDiv,
  ItemDiv
} from './styles';
import profile from '../../../assets/images/profile.svg';
import { industryList, teamMember } from '../../../global/row';
import { useDispatch, useSelector } from 'react-redux';
import { updateWorkspaceInputDetail } from '../../../actions/workspaceActions';
import { checkStepOneValidation } from '../../../services/workspaceService';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import Button from '../../../component/button';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import AppLogo from '../../../component/appLogo';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { VIEW_ANALYTICS } from '../../../global/analyticsConstants';
import { Dropdown, DropdownItem } from '../../../component/Dropdown';

interface Props {
  gotoNext: () => void;
}

const OnboardingStep1: React.FC<Props> = ({ gotoNext }) => {
  const [isTeamMemberOpen, setIsTeamMemberOpen] = React.useState(false);
  const [isIndustryOpen, setIsIndustryOpen] = React.useState(false);

  //use selector state variables
  const workspaceSelector = useSelector((state: RootReducerInterface) => state.workspace);
  const { workspaceInputDetails } = workspaceSelector;
  const { name, industry, teamType } = workspaceInputDetails;
  const [selectedTeamMember, setSelectedTeamMember] = React.useState(teamType || null); // Track selected item
  const [selectedIndustry, setSelectedIndustry] = React.useState(industry || null);
  // Other variables
  const dispatch = useDispatch();
  const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();

  useEffect(() => {
    trackAnalyticActivity(VIEW_ANALYTICS.ONBOARDING_STEP_1);
  }, []);

  const onUpdateInputValue = useCallback(
    (propsName: string, value: any) => {
      dispatch(updateWorkspaceInputDetail({ propsName, value }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      const payload = {
        name: workspaceInputDetails?.name,
        industry: workspaceInputDetails?.industry?.value,
        teamType: workspaceInputDetails?.teamType?.value
      };
      const isValidate = await dispatch(checkStepOneValidation(payload));
      if (!isValidate) return;
      gotoNext();
    },
    [dispatch, gotoNext, workspaceInputDetails]
  );

  const handleSelect = (item: { label: string; value: number | string }, type: 'teamType' | 'industry') => {
    if (type === 'teamType' && typeof item.value === 'number') {
      setSelectedTeamMember({ label: item.label, value: item.value });
      onUpdateInputValue('teamType', item);
      setIsTeamMemberOpen(false);
    } else {
      setSelectedIndustry(item);
      onUpdateInputValue('industry', item);
      setIsIndustryOpen(false);
    }
  };

  return (
    <>
      <Group className='windows-onboarding-flow'>
        <Content onSubmit={onSubmit}>
          <Icon>
            <AppLogo height='48' />
          </Icon>
          <Title>Just a few quick things to set up your account…</Title>
          <FormContent>
            <Formblock>
              <Profile>
                <Icon>
                  <img src={profile} alt='' />
                </Icon>
                <ProfileView>
                  <ProfileContent>
                    <Heading>Hello, {userDetails?.given_name}</Heading>
                  </ProfileContent>
                  <Text>First, tell us a bit about your company.</Text>
                </ProfileView>
              </Profile>

              <Subblock>
                <Item>
                  <Label>Company Name</Label>
                  <Inputvalue
                    typeof='text'
                    placeholder='Enter company name'
                    value={name}
                    onChange={(e) => {
                      onUpdateInputValue('name', e.target.value);
                    }}
                  />
                </Item>
                <Item>
                  <Label>Team Member</Label>
                  <Dropdown
                    isMinWidth={438}
                    content={
                      <InputDiv onClick={() => setIsTeamMemberOpen((prev) => !prev)}>
                        <Input
                          placeholder='Select a team member'
                          value={selectedTeamMember ? selectedTeamMember.label : ''}
                        />
                        <IconDiv>
                          <SVGIcon
                            name='angledown-icon'
                            width='18'
                            height='18'
                            viewBox='0 0 18 18'
                            className={`angledown-icon ${isTeamMemberOpen ? 'rotate' : ''}`}
                          />
                        </IconDiv>
                      </InputDiv>
                    }
                    trigger='click'>
                    <ItemDiv>
                      {teamMember.map((item) => {
                        const isSelected = selectedTeamMember?.value === item.value;
                        return (
                          <DropdownItem
                            key={item.value}
                            label={item.label}
                            onClick={() => handleSelect(item, 'teamType')}
                            isSuffix={
                              isSelected ? (
                                <SVGIcon name='checkbox-icon' width='16' height='16' viewBox='0 0 16 16' />
                              ) : null
                            }
                          />
                        );
                      })}
                    </ItemDiv>
                  </Dropdown>
                </Item>
                <Item>
                  <Label>Industry</Label>
                  <Dropdown
                    isMinWidth={438}
                    content={
                      <InputDiv onClick={() => setIsIndustryOpen((prev) => !prev)}>
                        <Input
                          placeholder='Select an industry'
                          value={selectedIndustry ? selectedIndustry.label : ''}
                        />
                        <IconDiv>
                          <SVGIcon
                            name='angledown-icon'
                            width='18'
                            height='18'
                            viewBox='0 0 18 18'
                            className={`angledown-icon ${isIndustryOpen ? 'rotate' : ''}`}
                          />
                        </IconDiv>
                      </InputDiv>
                    }
                    trigger='click'>
                    <ItemDiv>
                      {industryList.map((item) => {
                        const isSelected = selectedIndustry?.value === item.value;
                        return (
                          <DropdownItem
                            key={item.value}
                            label={item.label}
                            onClick={() => handleSelect(item, 'industry')}
                            isSuffix={
                              isSelected ? (
                                <SVGIcon name='checkbox-icon' width='16' height='16' viewBox='0 0 16 16' />
                              ) : null
                            }
                          />
                        );
                      })}
                    </ItemDiv>
                  </Dropdown>
                </Item>
              </Subblock>
            </Formblock>
            <Endblock>
              <Textformat>Step 1 to 2</Textformat>
              <Button title='Next' type='submit' resWidth={100} />
            </Endblock>
          </FormContent>
        </Content>
      </Group>
    </>
  );
};
export default OnboardingStep1;
