import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  status?: string;
  ismemberempty?: boolean;
  isHover?: boolean;
  height?: number;
}
const Group = styled.div`
  display: flex;
  overflow: hidden !important;
  @media (max-width: 449px) {
    flex-direction: column;
    overflow: unset !important;
  }
`;
const Maindiv = styled.div`
  height: 100vh;
  display: flex;
  flex: auto;
  overflow: hidden !important;
  .responsive-project-section {
    display: none;
  }
  @media (max-width: 449px) {
    flex-direction: column-reverse;
    height: auto;
    gap: 20px;
    padding-top: 16px;
    margin-bottom: 10px;
    .responsive-project-section {
      display: flex;
    }
  }
`;
const Leftdiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  padding: 24px 24px 0 0;
  max-width: 1022px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 449px) {
    padding: 0;
  }
`;
const Modalhead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 0.5px solid var(--border-primary);
`;

const Headline = styled.h6`
  ${Typography.body_lg_semibold}
  color: var(--text-primary);
  margin: 0;
`;

const Leftheader = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  @media (max-width: 449px) {
    gap: 24px;
    align-items: center;
  }
`;
const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  .img {
    cursor: pointer;
    border-radius: 50%;
    border: 0.5px solid var(--border-primary);
    object-fit: cover;
  }
  .emptyimg {
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid transparent;
  }
  @media (max-width: 449px) {
    gap: 12px;
    .img {
      width: 22px;
      height: 22px;
      border: 0.5px solid var(--border-primary);
    }
    .emptyimg {
      width: 24px;
      height: 24px;
    }
  }
`;
const Projectdetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  h1 {
    margin: 0;
    color: var(--text-primary);
    ${Typography.heading_lg}
  }
  p {
    margin: 0;
    color: var(--text-secondary);
    ${Typography.body_sm}
    border-radius: 6px;
    padding: 2px 6px;
    border: 1px solid var(--neutral-secondary);
    background: var(--neutral-secondary);
  }
`;

const Icon = styled.div<Props>`
  position: relative;
  display: flex;
  cursor: pointer;

  .more-icon {
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
  }
  .svgicon {
    stroke: var(--text-primary);
  }
  .large-more-icon {
    fill: var(--text-secondary);
    @media (max-width: 449px) {
      margin: 8px;
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
    @media (max-width: 449px) {
      background-color: transparent;
    }
  }
  ${(props: Props) =>
    props.isHover &&
    css`
      :hover {
        background-color: var(--background-muted);
        border-radius: 4px;
      }
    `}
  .padding {
    padding: 3px;
  }
`;
const Project = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 449px) {
    &.web-project-section {
      display: none;
    }
  }
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
    color: var(--text-primary);
    ${Typography.heading_md}
  }
`;
const Projectbox = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(194px, 1fr));
  @media (max-width: 449px) {
    display: flex;
    flex-direction: column;
    gap: 0;
    border: 0.5px solid var(--border-primary);
    border-radius: 12px;
    background-color: var(--background-primary);
  }
`;
const Card = styled.div`
  cursor: pointer;
  width: 100%;
  max-width: 240px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  @media (max-width: 449px) {
    border-radius: 0;
    background-color: transparent;
    border: none;
    max-width: 100%;
    border-bottom: 0.5px solid var(--border-primary);
    :last-child {
      border-bottom: none;
    }
  }
`;
const Topcard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  padding: 16px;
  @media (max-width: 449px) {
    padding: 0;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  h5 {
    margin: 0;
    color: var(--text-primary);
    ${Typography.body_lg_medium}
  }
  p {
    margin: 0;
    color: var(--text-secondary);
    ${Typography.body_md_medium}
  }
  @media (max-width: 449px) {
    h5 {
      ${Typography.heading_md}
    }
  }
`;
const Activebtn = styled.div`
  color: var(--brand-primary);
  ${Typography.body_sm}
  border-radius: 6px;
  border: 1px solid var(--brand-secondary);
  background: var(--brand-secondary);
  padding: 2px 5px;
  cursor: pointer;
`;
const AssignContent = styled.div`
  border-top: 0.5px solid var(--border-primary);
  padding: 12px 16px;
  @media (max-width: 449px) {
    border-top: none;
    padding: 0;
  }
`;

const Assigngroup = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-right: -8px;
    position: relative;
    @media (max-width: 449px) {
      padding-bottom: 0;
      margin-right: -9px;
    }
  }

  .plus-icon {
    width: 20px;
    height: 20px;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
    display: flex;
    background: var(--text-secondary);
    color: var(--text-white);
    ${Typography.body_xs_semibold}
    border: 2px solid var(--background-primary);

    @media (max-width: 449px) {
      width: 20px;
      font-size: 10px;
      font-weight: 700;
      height: 20px;
    }
  }
`;
const Plus = styled.div`
  position: absolute;
  top: 4px;
`;
const Rightdiv = styled.div`
  width: 100%;
  max-width: 268px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 0 24px 16px;
  position: relative;
  z-index: 9;
  overflow: scroll;
  border-left: 0.5px solid var(--border-primary);
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 449px) {
    padding: 0;
    border-left: none;
    max-width: 100%;
  }
`;

const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  gap: 8px;
  color: var(--brand-primary);
  ${Typography.body_md_medium}
`;

const Rightcontent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Clientdetail = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
`;

const Row = styled.div`
  display: flex;
  padding: 10px 12px 9px;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  border-bottom: 0.5px solid var(--border-primary);
  :last-child {
    border-bottom: none;
  }
  p {
    color: var(--text-secondary);
    ${Typography.body_md_medium}
    margin: 0;
  }
`;
const Row1 = styled.div`
  display: flex;
  padding: 10px 12px;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  border-bottom: 0.5px solid var(--border-primary);

  :last-child {
    border-bottom: none;
  }
  @media (max-width: 449px) {
    padding: 7px 12px 6px;
    gap: 4px;
  }
`;
const TextDiv = styled.p`
  color: var(--text-primary);
  ${Typography.body_md_medium};
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const EmailTag = styled.p`
  color: var(--brand-primary);
  ${Typography.body_md_medium};
  margin: 0;
`;
const Firstline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  p {
    margin: 0;
    color: var(--text-primary);
    ${Typography.body_md_semibold}
  }
  h5 {
    margin: 0;
    color: var(--text-secondary);
    ${Typography.body_md_medium}
  }
`;
const Tab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  .active {
    display: inline-flex;
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

const Bottmcontent = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
`;

const Invoice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  @media (max-width: 449px) {
    width: calc(100vw - 34px);
  }
`;
const Table = styled.table<Props>`
  border-collapse: collapse;
  position: relative;
  width: 100%;
  border-style: hidden;
  border-radius: 8px;
  box-shadow: 0 0 0 0.5px var(--border-primary);
  background: var(--background-primary);
  margin-left: 1px;
  tr {
    border-top: 0.5px solid var(--border-primary);
    display: flex;
    align-items: center;
  }
  thead {
    tr {
      border-top: none;
      background: var(--background-tertiary);
      border-radius: 8px 8px 0 0;
    }
  }
  th {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    text-align: left;
    padding: 10px 0 10px 20px;

    .lineicon {
      stroke: var(--border-primary);
    }
    p {
      margin: 0;
      ${Typography.body_md_medium}
    }
    text-transform: uppercase;
    color: var(--text-secondary);
    :last-child {
      border-right: 0;
    }
    &:first-child {
      padding: 10px 0 10px 12px;
      margin-left: -6px;
      p {
        margin-left: 6px;
      }
    }
    &:nth-child(2) {
      margin-right: 6px;
    }
  }
  td {
    width: 100%;
    text-align: left;
    padding: 10px 0 10px 20px;
    p {
      margin: 0;
      ${Typography.body_md_medium}
      color: var(--text-primary);
    }
    h5 {
      margin: 0;
      color: var(--text-secondary);
      ${Typography.body_md_medium}
      padding-top: 4px;
    }
    color: var(--text-secondary);
    span {
      ${Typography.body_md_medium}
      color: var(--text-primary);
    }
    &:first-child {
      padding: 10px 0 10px 12px;
    }
    &:last-child {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 12px 10px 21px;
      text-align: end;
    }
    &:nth-last-child(2) {
      color: var(--brand-primary);
    }
  }

  tr td:nth-last-child(3) {
    text-align: right;
    padding: 10px 20px;
  }
  tr th:nth-last-child(3) {
    justify-content: end;
  }
  @media (max-width: 449px) {
    box-shadow: none;
    th {
      gap: unset;
    }
    tbody {
      position: relative;
    }
    td {
      position: relative;
      width: 125px;
      &:nth-child(2) {
        display: none;
      }
      &:last-child {
        padding: 10px 12px 10px 16px;
        width: 24px;
        height: ${(props) => props.height}px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:nth-last-child(2) {
        padding: 10px 0px 10px 8px;
      }
      &:first-child {
        padding: 10px 8px 10px 12px;
      }
    }
    th {
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(4) {
        padding: 10px 0 10px 8px;
      }
      &:first-child {
        margin-left: 0px;
        p {
          margin-left: 0px;
        }
      }
      &:last-child {
        padding: 10px 12px 10px 16px;
        width: 24px;
      }
      &:nth-child(3) {
        padding: 10px 0px 9px 8px;
        justify-content: flex-start !important;
        p {
          width: 100%;
        }
      }
    }
    tbody > tr > td:last-child {
      z-index: 1;
      position: sticky;
      right: 0;
      box-shadow: var(--shadow-card);
      background-color: var(--background-primary);
    }
    thead > tr > th:last-child {
      z-index: 9;
      position: sticky;
      right: 0;
      box-shadow: var(--shadow-card);
      background-color: var(--background-primary);
    }
    tr td:nth-last-child(3) {
      text-align: left;
      padding: 10px 8px 10px 8px;
    }
  }
`;

const TableTr = styled.tr<{ isActive: boolean }>`
  position: relative;
  cursor: pointer;
  :hover {
    background-color: var(--neutral-secondary);
    &:last-child {
      border-radius: 0 0 8px 8px;
    }
  }
  ${(props) =>
    props.isActive &&
    css`
      background-color: var(--neutral-secondary);
      &:last-child {
        border-radius: 0 0 8px 8px;
      }
    `}
`;

const StatusPAID = styled.div<Props>`
  color: var(--accent-success);
  ${Typography.body_md_medium}
  text-transform: uppercase;
  ${(props: Props) =>
    props.status === 'PENDING' &&
    css`
      color: var(--accent-caution);
    `}
  ${(props: Props) =>
    props.status === 'PAID' &&
    css`
      color: var(--accent-success);
    `} 
    ${(props: Props) =>
    (props.status === 'DRAFT' || props.status === 'DUE') &&
    css`
      color: var(--text-secondary);
    `}
    ${(props: Props) =>
    props.status === 'OVER DUE' &&
    css`
      color: var(--accent-error);
    `}
`;

const Point = styled.div`
  position: relative;
  width: 100%;
  margin: 0 16px 12px;
  max-width: -webkit-fill-available;
  border: none;
  small {
    position: absolute;
    border-radius: 50%;
    background-color: ${(props: { color: string }) => props.color};
    top: 6px;
    width: 10px;
    height: 10px;
    left: -0.5px;
  }
  span {
    ${Typography.body_sm}
    color: var(--text-primary);
    margin-left: 16px;
    font-family: 'Inter';
  }
  @media (max-width: 449px) {
    margin: 0 0px 6px 0px;
    span {
      margin-left: 18px;
    }
  }
`;

const ProgressDiv = styled.div`
  display: flex;
  padding: 0 16px 12px;
  @media (max-width: 449px) {
    display: none;
  }
`;

const Emptybox = styled.div`
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  width: 100%;
  @media (max-width: 449px) {
    width: -webkit-fill-available;
  }
`;
const Empty = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 35px 0;
  ${(props: Props) =>
    props.ismemberempty &&
    css`
      padding: 28px 14px;
      gap: 16px;
      max-width: 240px;
      button {
        padding: 4px 15px;
        ${Typography.body_sm_medium};
      }
      @media (max-width: 449px) {
        padding: 35px 14px;
        max-width: -webkit-fill-available;
      }
    `}
  @media (max-width: 449px) {
    button {
      padding: 4px 15px;
      ${Typography.body_sm_medium};
    }
  }
`;

const Lefttitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  .icon {
    cursor: pointer;
    path {
      fill: var(--text-secondary);
    }
  }
  .no-access-icon {
    cursor: pointer;
    path {
      stroke: var(--text-secondary);
    }
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Carddetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media (max-width: 449px) {
    padding: 16px 16px 15px 16px;
    gap: 6px;
  }
`;
const Overlay = styled.div`
  background-color: var(--background-hover);
  border: 0.5px solid var(--border-primary);
  top: 0;
  left: 0;
  width: 58px;
  height: 58px;
  position: absolute;
  display: none;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  @media (max-width: 449px) {
    width: 22px;
    height: 22px;
  }
`;
const Profileimg = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  :hover {
    overflow: hidden;
    border-radius: 50%;
    .img {
      filter: blur(1.5px);
    }
    .edit-profile {
      display: flex;
    }
    .emptyimg {
      filter: blur(1.5px);
    }
  }
  @media (max-width: 449px) {
    width: 24px;
    height: 24px;
  }
`;
const Editicon = styled.div`
  display: flex;
  justify-content: center;
  margin: auto 0;
  .edit-icon {
    path {
      stroke: var(--text-white);
      fill: none;
    }
  }
  @media (max-width: 449px) {
    .edit-icon {
      width: 16px;
      height: 16px;
    }
  }
`;

const ResponsiveNavbarIcon = styled.div`
  @media (max-width: 449px) {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    gap: 24px;
    margin: 0 -16px;
    background-color: var(--background-primary);
    position: sticky;
    border-bottom: 0.5px solid var(--border-primary);
    top: 0;
    z-index: 11;
  }
`;

const Blankdiv = styled.div`
  width: 24px;
  height: 24px;
`;

const TableWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
  border-radius: 8px;

  @media (max-width: 449px) {
    width: calc(100vw - 34px);
    overflow: auto;
    scrollbar-width: none;
    border: 0.5px solid var(--border-primary);
  }
`;

const Dropdiv = styled.div`
  .active {
    background-color: var(--background-muted);
    border-radius: 4px;
  }
`;

export {
  Group,
  Maindiv,
  Leftdiv,
  Rightdiv,
  Leftheader,
  Projectdetail,
  Profile,
  Icon,
  Project,
  Title,
  Projectbox,
  Card,
  Topcard,
  Text,
  Activebtn,
  AssignContent,
  Assigngroup,
  Plus,
  Link,
  Rightcontent,
  Clientdetail,
  Row,
  Firstline,
  Tab,
  Bottmcontent,
  Row1,
  Invoice,
  Table,
  StatusPAID,
  Point,
  ProgressDiv,
  Emptybox,
  Empty,
  Headline,
  Modalhead,
  Lefttitle,
  Carddetail,
  Overlay,
  Profileimg,
  Editicon,
  ResponsiveNavbarIcon,
  Blankdiv,
  EmailTag,
  TextDiv,
  TableWrapper,
  Dropdiv,
  TableTr
};
