/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AppLayout from '../../../component/appLayout';
import {
  AssignContent,
  Assigngroup,
  Card,
  Group,
  Icon,
  Leftdiv,
  Leftheader,
  Maindiv,
  Plus,
  Profile,
  Project,
  Projectbox,
  Projectdetail,
  Text,
  Title,
  Topcard,
  Invoice,
  Table,
  StatusPAID,
  Point,
  ProgressDiv,
  Emptybox,
  Empty,
  Headline,
  Modalhead,
  Carddetail,
  Overlay,
  Profileimg,
  Editicon,
  ResponsiveNavbarIcon,
  Blankdiv,
  TableWrapper,
  Dropdiv,
  TableTr
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from '../../../component/avatar/Avatar';
import ModalCustom from '../../../component/models/modal';
import Deletemodal from '../../../component/models/deleteModel';
import Addmembermodal from '../../../component/models/addMembermodal';
import { useHistory, useParams } from 'react-router-dom';
import {
  deleteCustomerMember,
  getCustomerList,
  getCustomerMembersList,
  getCustomersInvoiceList,
  getCustomersProjectList,
  getIndividualCustomerDetail,
  updateCustomerData
} from '../../../services/customerServices';
import {
  CustomerDetailsInterface,
  CustomerProjectListInterface,
  MemberInputInterface
} from '../../../interfaces/CustomerInterface';
import {
  clearCustomerDetail,
  setCustomerDetail,
  setCustomersInvoiceList,
  setCustomersProjectList,
  setIndividualCustomerDetail,
  updateCustomerDetail
} from '../../../actions/customerActions';
import {
  calculateDueTime,
  currencyWithDecimal,
  getFirebaseUrlFromFile,
  getFormattedDate,
  isEmpty
} from '../../../helpers/common';
import { currencySymbols } from '../../../global/row';
import { CustomerDetailMoreMenu, MENU_OPTIONS, PROJECT_STATUS, PROJECT_STATUS_COLOR } from '../../../global/constants';
import CustomerdetailLoading from '../../../component/loading/customerDetailLoading';
import projectdarkempty from '../../../assets/images/emptystates/projectdarkempty.svg';
import projectempty from '../../../assets/images/emptystates/projectlightempty.svg';
import invoicedarkempty from '../../../assets/images/emptystates/invoicedarkempty.svg';
import invoiceempty from '../../../assets/images/emptystates/invoice-empty.svg';
import CreateNewProjectModal from '../../../component/models/createNewProjectModal';
import { clearProjectDetail, updateProjectDetail } from '../../../actions/projectActions';
import Invoicemodal from '../../../component/models/invoicemodal';
import { setErrorMessage, setSuccessMessage } from '../../../actions/messageActions';
import CustomerModal from '../../../component/models/customerModal/customerModal';
import { captureException } from '../../../services/logService';
import emptylogo from '../../../assets/images/emptystates/emptylogo.svg';
import darkemptylogo from '../../../assets/images/emptystates/darkemptylogo.svg';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import CustomerEditModal from '../../../component/models/customerModal/customerAdditionalModal';
import SuccessfullModel from '../../../component/models/succesfullModel';
import EmptyState from '../../../component/emptyState';
import CustomerdetailRight from './customerDetailRightSection';
import { INVOICE_ANALYTICS } from '../../../global/analyticsConstants';
import { useMobileDevice } from '../../../global/useMobile';
import Responsivnavbar from '../../../component/navbar/responsivenavbar';
import { ProjectDetailInterface, UserInterface } from '../../../interfaces/ProjectInterface';
import { Dropdown, DropdownItem } from '../../../component/Dropdown';
import ProgressBar from '../../../component/progressBar';

const Customerdetail: React.FC = () => {
  //Refs
  const invoiceButtonRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  //States
  const [isOpen, setOpen] = useState(false);
  const [isInvoiceDropdownOpen, setIsInvoiceDropdownOpen] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  const [deleteModelOpen, setDeleteModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<MemberInputInterface>();
  const [isInvoiceModel, setIsInvoiceModel] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [openCustomerModel, setOpenCustomerModel] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isSuccessfulModalOpen, setIsSuccessfulModalOpen] = useState(false);
  const [clientLoginUrl, setClientLoginUrl] = useState('');
  const [selectedInvoice, setSelectedInvoice] = useState<string | null>(null);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    settings: settingsSelector,
    project: projectSelector,
    customer: customerSelector,
    workspace: workspaceSelector
  } = stateSelector || {};
  const { themeMode } = settingsSelector;
  const { individualCustomer, customerInvoiceList, customerProjectList, newCustomer } = customerSelector;
  const { list } = projectSelector;
  const { workspace } = workspaceSelector;
  //Other variable
  const isMobile = useMobileDevice();
  const history = useHistory();
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const getImageSource = () => (themeMode?.theme === 'dark' ? invoicedarkempty : invoiceempty);
  const getImageSourceProject = () => (themeMode?.theme === 'dark' ? projectdarkempty : projectempty);

  // for open successful model
  const handleOnclickOpenModel = () => {
    setIsSuccessfulModalOpen(true);
  };

  // for close successful model
  const handleOnclickCloseModel = () => {
    setIsSuccessfulModalOpen(false);
  };

  // handle invoice dropdown
  const handleInvoiceDropdown = useCallback(
    (e: React.SyntheticEvent, id: string) => {
      e.stopPropagation();
      if (openDropdownId === id) {
        setIsInvoiceDropdownOpen(false);
        setOpenDropdownId(null);
      } else {
        setIsInvoiceDropdownOpen(true);
        setOpenDropdownId(id);
      }
    },
    [openDropdownId]
  );

  // handel modal close
  const handleCloseModal = useCallback((clientUrl?: string) => {
    setIsModalOpen(false);
    if (clientUrl) {
      setClientLoginUrl(clientUrl);
      handleOnclickOpenModel();
    }
  }, []);

  // handel delete modal close
  const close = () => {
    setDeleteModalOpen(false);
    setSelectedItem(undefined);
  };

  // load customer project
  const loadCustomerProject = useCallback(async () => {
    if (!isEmpty(workspace?.id) && !isEmpty(params?.id)) {
      const projects = await dispatch(getCustomersProjectList(params?.id));
      const updatedProjectList = await projects?.map((project: CustomerProjectListInterface) => {
        const users = project?.ProjectUsers?.map((projectUser) => {
          const matchingUserData = workspace?.users?.find(
            (userData: { id: string }) => userData?.id === String(projectUser)
          );
          return matchingUserData || projectUser;
        });
        const updated = { ...project, users: users };
        return updated;
      });
      dispatch(setCustomersProjectList(updatedProjectList));
    }
  }, [dispatch, params?.id, workspace?.id]);

  // load initial data
  const loadData = useCallback(async () => {
    try {
      if (!isEmpty(workspace?.id) && !isEmpty(params?.id)) {
        setLoading(true);
        await Promise.all([dispatch(getIndividualCustomerDetail(params?.id)), dispatch(getCustomerList())]);
        loadCustomerProject();
        const response = await Promise.all([
          dispatch(getCustomersInvoiceList(params?.id)),
          dispatch(getCustomerMembersList(params?.id))
        ]);
        const invoices = response?.[0] || [];
        dispatch(setCustomersInvoiceList(invoices));
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [dispatch, loadCustomerProject, params?.id, workspace?.id]);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(clearCustomerDetail());
      dispatch(setCustomersProjectList([]));
      dispatch(setCustomersInvoiceList([]));
      dispatch(setIndividualCustomerDetail({}));
    };
  }, [dispatch, loadData]);

  // handel cancel button
  const handleCancel = useCallback(async () => {
    loadCustomerProject();
    setOpen(false);
    dispatch(clearProjectDetail());
  }, [dispatch, loadCustomerProject]);

  // for open modal
  const OpenModal = useCallback(() => {
    dispatch(updateProjectDetail({ propsName: 'customerId', value: params?.id }));
    setOpen(true);
  }, [dispatch, params?.id]);

  // handel click new invoice
  const onClickNewInvoice = () => {
    trackAnalyticActivity(INVOICE_ANALYTICS.NEW_INVOICE_CLICKED);
    setIsInvoiceModel(!isInvoiceModel);
  };

  // handel close invoice modal
  const handleClose = () => {
    setIsInvoiceModel(false);
  };

  // Delete customer detail
  const onClickDelete = useCallback((item: CustomerDetailsInterface) => {
    dispatch(setCustomerDetail(item));
    setIsDeleteOpen(true);
  }, []);

  // close delete modal
  const onCloseDeleteModal = useCallback(() => {
    setIsDeleteOpen(false);
    setSelectedItem(undefined);
  }, []);

  // handel delete customer
  const onDeleteCustomer = useCallback(
    async (item: { id: string }) => {
      try {
        setLoading(true);
        let response;
        if (!isEmpty(item?.id)) {
          response = await dispatch(updateCustomerData(item?.id, { IsArchived: true }));
        }
        if (response) {
          onCloseDeleteModal();
          history.push('/customer');
          dispatch(setSuccessMessage('Customer deleted successfully!'));
          loadData();
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    [onCloseDeleteModal, history, dispatch, loadData]
  );

  // edit customer detail
  const onClickEdit = useCallback(
    (item: CustomerDetailsInterface) => {
      dispatch(setCustomerDetail(item));
      setOpenCustomerModel(true);
    },
    [dispatch]
  );

  // close customer model
  const closeCustomerModel = useCallback(async () => {
    await dispatch(getIndividualCustomerDetail(params?.id));
    dispatch(clearCustomerDetail());
    setOpenCustomerModel(false);
  }, [workspace?.id, params?.id]);

  // delete customer member
  const onDeleteCustomerMember = useCallback(
    async (member_id: string | undefined) => {
      try {
        setLoading(true);
        const result = await dispatch(deleteCustomerMember(member_id));
        if (result) {
          await dispatch(getCustomerMembersList(params?.id));
          close();
        }
      } catch (e) {
        captureException(e);
      } finally {
        setLoading(false);
      }
    },
    [params?.id, dispatch]
  );

  // redirect to invoice detail page
  const onClickInvoice = useCallback(
    (invoiceId: string) => {
      setSelectedInvoice(null);
      history.push(`/invoices/view/${invoiceId}`);
    },
    [history]
  );

  // close edit modal
  const onCloseEditModal = useCallback(async () => {
    dispatch(clearCustomerDetail());
    setIsEditModalOpen(false);
  }, [dispatch]);

  // handel upload click
  const handleUploadClick = useCallback(() => {
    inputRef.current?.click();
  }, []);

  // for convert base64
  const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = async () => {
        const item = {
          profile: fileReader.result,
          file: file
        };
        let profileUrl;
        if (item) {
          profileUrl = await getFirebaseUrlFromFile(item, 'customer-profile/', dispatch);
        }
        dispatch(updateCustomerDetail({ propsName: 'profileImage', value: profileUrl }));
        const payload = {
          ProfileImage: profileUrl
        };
        const response = await dispatch(updateCustomerData(individualCustomer?.id, payload));
        if (response) dispatch(setSuccessMessage('Customer profile updated successfully!'));
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // handle file read
  const handleFileRead = async (event: any) => {
    try {
      const file = event.target.files?.[0];
      let fileData = file?.name.split('.');
      if (file.size > 500000) {
        dispatch(setErrorMessage('Please upload less than 500kb photo size.'));
        return false;
      }
      if (fileData[1] === 'jpg' || fileData[1] === 'jpeg' || fileData[1] === 'png') {
        await convertBase64(file);
      } else {
        dispatch(setErrorMessage('Please upload a valid type photo.'));
        return false;
      }
    } catch (e) {
      captureException(e);
      console.log('error', e);
    }
  };

  // handle click for (edit and delete)
  const handleClick = useCallback((key: number | string, item: CustomerDetailsInterface) => {
    key = Number(key);
    if (key === MENU_OPTIONS.EDIT) {
      onClickEdit(item);
    } else if (key === MENU_OPTIONS.DELETE) {
      onClickDelete(item);
    }
  }, []);

  const renderMoreMenuItems = useCallback(
    (item: CustomerDetailsInterface) => {
      return CustomerDetailMoreMenu.map((menuItem) => (
        <DropdownItem
          key={menuItem.key}
          label={menuItem.label}
          onClick={() => handleClick(menuItem.key, item)}
          tone={menuItem.label === 'Delete' ? 'critical' : 'primary'}
        />
      ));
    },
    [handleClick]
  );

  // common component of Header for desktop and responsive
  const HeaderComponent = useMemo(() => {
    const getProfileImage = () => {
      if (!isEmpty(newCustomer?.profileImage)) return newCustomer?.profileImage;
      if (!isEmpty(individualCustomer?.profileImage)) return individualCustomer?.profileImage;
      return darkemptylogo;
    };

    const getProfileImageSrc = () => {
      if (!isEmpty(newCustomer?.profileImage)) return newCustomer.profileImage;
      if (!isEmpty(individualCustomer?.profileImage)) return individualCustomer.profileImage;
      return emptylogo;
    };

    const profileImageSrc = () => {
      if (themeMode?.theme === 'dark') return getProfileImage();
      return getProfileImageSrc();
    };

    return (
      <>
        <Leftheader>
          <input type='file' onChange={handleFileRead} ref={inputRef} style={{ display: 'none' }} />
          <Profile>
            <Profileimg>
              <img
                src={profileImageSrc()}
                alt='emptyprofile'
                width={isMobile ? 22 : 58}
                height={isMobile ? 22 : 58}
                className={
                  !isEmpty(newCustomer?.profileImage) || !isEmpty(individualCustomer?.profileImage) ? 'img' : 'emptyimg'
                }
              />
              <Overlay className='edit-profile' onClick={handleUploadClick}>
                <Editicon>
                  <SVGIcon name='profile-edit-icon' width='26' height='26' viewBox='0 0 26 26' className='edit-icon' />
                </Editicon>
              </Overlay>
            </Profileimg>
            <Projectdetail>
              <h1>{individualCustomer?.companyName}</h1>
              {!isMobile && !isEmpty(individualCustomer?.email) && <p>{individualCustomer.email}</p>}
            </Projectdetail>
          </Profile>
          <Dropdiv>
            <Dropdown
              activeClassName='active'
              content={
                <Icon>
                  <SVGIcon
                    name='large-more-icon'
                    width={isMobile ? '16' : '24'}
                    height={isMobile ? '16' : '24'}
                    viewBox='0 0 24 24'
                    className='large-more-icon'
                  />
                </Icon>
              }
              trigger='click'>
              {renderMoreMenuItems(individualCustomer)}
            </Dropdown>
          </Dropdiv>
        </Leftheader>
      </>
    );
  }, [themeMode, newCustomer, individualCustomer, handleClick, renderMoreMenuItems]);

  // render customer project list
  const renderCustomerProjectList = useMemo(() => {
    const getProjectDetails = (projectId: string) => list?.find((item) => item?.id === projectId);
    const navigateToProject = (projectDetails: ProjectDetailInterface | undefined) => {
      if (projectDetails) {
        history.push(`/projects/details/${projectDetails?.id}/tasks`);
      } else {
        dispatch(setErrorMessage("You don't have access to this project"));
      }
    };
    const renderProjectStatus = (projectDetails: ProjectDetailInterface | undefined) => {
      const statusColor = PROJECT_STATUS_COLOR[projectDetails?.status || 1];
      const statusText = projectDetails?.statusText || 'Not Started yet';
      const progressText =
        projectDetails?.status !== PROJECT_STATUS.NOT_STARTED &&
        projectDetails?.status &&
        ` (${projectDetails?.progress}%)`;

      return (
        <Point color={statusColor}>
          <span>
            <small></small>
            {statusText}
            {progressText}
          </span>
        </Point>
      );
    };
    const renderProjectProgress = (projectDetails: ProjectDetailInterface | undefined) => (
      <ProgressDiv>
        <ProgressBar
          percent={projectDetails?.progress || 0}
          showInfo={false}
          progressColor={PROJECT_STATUS_COLOR[projectDetails?.status || 1]}
        />
      </ProgressDiv>
    );
    const renderProjectUsers = (users: UserInterface[]) => (
      <AssignContent>
        <Assigngroup>
          <ul>
            {users?.map((user, index) => {
              if (!isEmpty(user?.name) && index <= 3) {
                return (
                  <li key={user?.id}>
                    <Avatar imgSrc={user?.profile_photo || ''} name={user?.name || 'U N'} size={20} />
                  </li>
                );
              }
              return null;
            })}
            {users?.length > 4 && (
              <li className='plus-icon'>
                <Plus>+{users?.length - 4}</Plus>
              </li>
            )}
          </ul>
        </Assigngroup>
      </AssignContent>
    );

    return customerProjectList?.map((project) => {
      const projectDetails = getProjectDetails(project['_id']);
      return (
        <Card key={project['_id']} onClick={() => navigateToProject(projectDetails)}>
          <Carddetail>
            <Topcard>
              <Text>
                <h5>{project?.Name}</h5>
              </Text>
            </Topcard>
            <div>
              {renderProjectStatus(projectDetails)}
              {renderProjectProgress(projectDetails)}
              {renderProjectUsers(project?.users)}
            </div>
          </Carddetail>
        </Card>
      );
    });
  }, [customerProjectList, list, history, dispatch]);

  // render customer invoice list
  const renderCustomerInvoiceList = useMemo(() => {
    return customerInvoiceList?.map((invoice, index) => {
      const ref = currencySymbols.find((x) => x.code === invoice?.currency);
      const symbol = ref?.symbol;

      if (!invoiceButtonRef?.current[index]) {
        invoiceButtonRef.current[index] = React.createRef<HTMLDivElement>();
      }

      return (
        <>
          <TableTr
            isActive={selectedInvoice === invoice?.id}
            key={invoice?.id}
            onClick={() => onClickInvoice(invoice?.id)}>
            <td>
              <p>{invoice?.number}</p>
            </td>
            <td>
              <p>{getFormattedDate(new Date(invoice?.date))}</p>
              {calculateDueTime(invoice?.dueDate)?.overDue && <h5>{calculateDueTime(invoice?.dueDate)?.dueTime}</h5>}
            </td>
            <td>
              <p>
                {symbol} {Number(currencyWithDecimal(invoice?.totalAmount))}
              </p>
              {invoice?.dueAmount > 0 && (
                <h5>
                  Due {symbol} {invoice?.dueAmount}
                </h5>
              )}
            </td>
            <td>
              <StatusPAID status={invoice?.statusText}>{invoice?.statusText}</StatusPAID>
            </td>
            <td height={invoice?.dueAmount ? '44px' : '24px'}>
              <Dropdiv>
                <Dropdown
                  activeClassName='active'
                  content={
                    <Icon
                      isHover={true}
                      onClick={() => setSelectedInvoice((prev) => (prev === null ? invoice?.id : null))}>
                      <SVGIcon
                        name='more-icon'
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        className='more-icon padding'
                      />
                    </Icon>
                  }
                  trigger='click'
                  onOutsideClick={() => setSelectedInvoice(null)}>
                  <DropdownItem label='View' onClick={() => onClickInvoice(invoice?.id)} />
                </Dropdown>
              </Dropdiv>
            </td>
          </TableTr>
        </>
      );
    });
  }, [customerInvoiceList, handleInvoiceDropdown, isInvoiceDropdownOpen, onClickInvoice, openDropdownId]);

  return (
    <>
      <>
        <AppLayout>
          <Group>
            {loading && <CustomerdetailLoading />}
            {/*responsive view design for header */}
            {isMobile && (
              <ResponsiveNavbarIcon>
                <Responsivnavbar />
                {HeaderComponent}
              </ResponsiveNavbarIcon>
            )}
            {!loading && (
              <Maindiv>
                <Leftdiv>
                  {/* desktop view design for header and project card */}
                  {!isMobile && (
                    <>
                      {HeaderComponent}
                      <Project className={'web-project-section'}>
                        <Title>
                          <p>Projects</p>
                        </Title>

                        {/* Empty state */}
                        {customerProjectList?.length === 0 && !loading && (
                          <Emptybox>
                            <Empty>
                              <EmptyState
                                image={getImageSourceProject()}
                                header='You have no projects yet'
                                title='You can create a new project by clicking the button below.'
                                name='New Project'
                                isgap={16}
                                onButtonClick={OpenModal}
                                isCustomerDetail={true}
                              />
                            </Empty>
                          </Emptybox>
                        )}
                        {customerProjectList?.length > 0 && !loading && (
                          <Projectbox>{renderCustomerProjectList}</Projectbox>
                        )}
                      </Project>
                    </>
                  )}
                  {
                    <>
                      <Invoice>
                        <Title>
                          <p>Invoices</p>
                        </Title>

                        {/* Empty state */}
                        {customerInvoiceList?.length === 0 && !loading && (
                          <Emptybox>
                            <Empty>
                              <EmptyState
                                image={getImageSource()}
                                header='You have no invoice records.'
                                name='New Invoice'
                                isgap={16}
                                onButtonClick={onClickNewInvoice}
                                isCustomerDetail={true}
                              />
                            </Empty>
                          </Emptybox>
                        )}
                        {customerInvoiceList?.length > 0 && !loading && (
                          <TableWrapper>
                            <Table>
                              <thead>
                                <tr>
                                  <th>
                                    <p>Invoice no</p>
                                    <SVGIcon
                                      name='line'
                                      width='1'
                                      height='21'
                                      viewBox='0 0 1 21'
                                      className='lineicon'
                                    />
                                  </th>
                                  <th>
                                    <p>Invoice Date</p>
                                    <SVGIcon
                                      name='line'
                                      width='1'
                                      height='21'
                                      viewBox='0 0 1 21'
                                      className='lineicon'
                                    />
                                  </th>
                                  <th>
                                    <p>Amount</p>
                                    <SVGIcon
                                      name='line'
                                      width='1'
                                      height='21'
                                      viewBox='0 0 1 21'
                                      className='lineicon'
                                    />
                                  </th>
                                  <th>
                                    <p>Status</p>
                                  </th>
                                  <th>
                                    <Blankdiv />
                                  </th>
                                </tr>
                              </thead>
                              <tbody className='invoice_list'>{renderCustomerInvoiceList}</tbody>
                            </Table>
                          </TableWrapper>
                        )}
                      </Invoice>
                    </>
                  }
                </Leftdiv>
                <CustomerdetailRight
                  openDeleteModal={() => setDeleteModalOpen(true)}
                  setIsModalOpen={() => setIsModalOpen(true)}
                  openEditModal={() => setIsEditModalOpen(true)}
                  setSelectedItem={(value) => setSelectedItem(value)}
                />
                {/* Responsive view for Project Card  */}
                {isMobile && (
                  <Project className={'responsive-project-section '}>
                    <Title>
                      <p>Projects</p>
                    </Title>

                    {/* Empty state */}
                    {customerProjectList?.length === 0 && !loading && (
                      <Emptybox>
                        <Empty>
                          <EmptyState
                            image={getImageSourceProject()}
                            header='You have no projects'
                            name='New Project'
                            isgap={16}
                            onButtonClick={OpenModal}
                            isCustomerDetail={true}
                          />
                        </Empty>
                      </Emptybox>
                    )}
                    {customerProjectList?.length > 0 && !loading && (
                      <Projectbox>{renderCustomerProjectList}</Projectbox>
                    )}
                  </Project>
                )}
              </Maindiv>
            )}
          </Group>
        </AppLayout>
      </>
      <ModalCustom open={deleteModelOpen} onClose={close} width={334}>
        <Deletemodal
          onClose={close}
          loading={loading}
          onOk={() => onDeleteCustomerMember(selectedItem?.id)}
          modaltitle='Delete Member?'
          description='Are you sure you want to delete this member?'
        />
      </ModalCustom>
      <ModalCustom open={isModalOpen} onClose={handleCloseModal} width={480}>
        <Addmembermodal closeModal={handleCloseModal} customer_id={individualCustomer?.id} />
      </ModalCustom>
      <ModalCustom open={isOpen} onClose={handleCancel} width={632}>
        <Modalhead>
          <Headline>Create New Project</Headline>
          <Icon onClick={handleCancel}>
            <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='svgicon' />
          </Icon>
        </Modalhead>
        <CreateNewProjectModal handleCloseModal={handleCancel} onClickCancel={handleCancel} isCustomerDetail={true} />
      </ModalCustom>
      <ModalCustom open={isInvoiceModel} onClose={handleClose} width={381}>
        <Invoicemodal
          closemodel={handleClose}
          isCustomerDetail={true}
          customerDetail={individualCustomer}
          CustomerInvoiceModal={true}
        />
      </ModalCustom>
      <ModalCustom open={openCustomerModel} onClose={closeCustomerModel}>
        <CustomerModal handleCloseModal={closeCustomerModel} />
      </ModalCustom>
      <ModalCustom open={isDeleteOpen} onClose={onCloseDeleteModal} width={334}>
        <Deletemodal
          onClose={onCloseDeleteModal}
          onOk={() => onDeleteCustomer(individualCustomer)}
          loading={loading}
          modaltitle='Delete Customer?'
          description='Are you sure to want to delete this customer?'
        />
      </ModalCustom>

      <ModalCustom open={isEditModalOpen} onClose={onCloseEditModal}>
        <CustomerEditModal handleCloseModal={onCloseEditModal} />
      </ModalCustom>
      <ModalCustom open={isSuccessfulModalOpen} onClose={handleOnclickCloseModel} width={420}>
        <SuccessfullModel clientLoginUrl={clientLoginUrl} onCancel={handleOnclickCloseModel} />
      </ModalCustom>
    </>
  );
};

export default Customerdetail;
