import { RefObject } from 'react';
import { REACT_APP_BLOCKSUITE_URL } from '../../../../../global/environment';
import { DropdownItem } from '../../../../../component/Dropdown';

export default function ExportSubMenu({ editorIframe }: { editorIframe: RefObject<HTMLIFrameElement> }) {
  // for export in to pdf
  const exportToPdf = () => {
    editorIframe.current?.contentWindow?.postMessage({ type: 'export', payload: 'pdf' }, `${REACT_APP_BLOCKSUITE_URL}`);
  };

  // for export in to HTML
  const exportToHtml = () => {
    editorIframe.current?.contentWindow?.postMessage(
      { type: 'export', payload: 'html' },
      `${REACT_APP_BLOCKSUITE_URL}`
    );
  };

  // for export in to markDown
  const exportToMarkdown = () => {
    editorIframe.current?.contentWindow?.postMessage(
      { type: 'export', payload: 'markdown' },
      `${REACT_APP_BLOCKSUITE_URL}`
    );
  };

  // for export in to PNG
  const exportToPng = () => {
    editorIframe.current?.contentWindow?.postMessage({ type: 'export', payload: 'png' }, `${REACT_APP_BLOCKSUITE_URL}`);
  };

  return (
    <>
      <DropdownItem label='Export to PDF' onClick={() => exportToPdf()} />
      <DropdownItem label='Export to HTML' onClick={exportToHtml} />
      <DropdownItem label='Export to PNG' onClick={exportToPng} />
      <DropdownItem label='Export to Markdown' onClick={exportToMarkdown} />
    </>
  );
}
