import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

interface TooltipContentProps {
  placement?: string;
  arrowLeft: string;
  arrowTop: string;
  backgroundColor: string;
  maxWidth: string | number;
  isInModal?: boolean;
}

export const TooltipContent = styled.div<TooltipContentProps>`
  position: fixed;
  z-index: 1000;
  ${Typography.body_sm}
  background-color: ${(props) => props.backgroundColor};
  color: var(--text-primary);
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid var(--border-primary);
  max-width: ${(props) => props.maxWidth || '100%'};
  width: 100%;

  ${({ isInModal }) =>
    isInModal &&
    css`
      word-wrap: break-word;
    `}

  &::before,
  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    left: ${(props) => props.arrowLeft};
    top: ${(props) => props.arrowTop};
    transform: translateX(-61%);
    overflow: hidden;
    z-index: 9999;
  }

  &::before {
    border: 6px solid transparent;
    z-index: 1;
    ${({ placement, backgroundColor }) => {
      switch (placement) {
        case 'bottom':
        case 'bottomLeft':
        case 'bottomRight':
          return css`
            border-bottom-color: ${backgroundColor};
            top: -12px;
            z-index: 9999;
          `;
        case 'top':
        case 'topLeft':
        case 'topRight':
          return css`
            border-top-color: ${backgroundColor};
            bottom: -12px;
            z-index: 9999;
          `;
        case 'left':
          return css`
            border-left-color: ${backgroundColor};
            right: -12px;
            top: 50%;
            z-index: 9999;
            transform: translateY(-50%);
          `;
        case 'right':
          return css`
            border-right-color: ${backgroundColor};
            left: -12px;
            top: 50%;
            z-index: 9999;
            transform: translateY(-50%);
          `;
        default:
          return '';
      }
    }}
  }

  &::after {
    border: 5px solid transparent;
    z-index: 2;
    ${({ placement, backgroundColor }) => {
      switch (placement) {
        case 'bottom':
        case 'bottomLeft':
        case 'bottomRight':
          return css`
            border-bottom-color: ${backgroundColor};
            top: -11px;
          `;
        case 'top':
        case 'topLeft':
        case 'topRight':
          return css`
            border-top-color: ${backgroundColor};
            bottom: -11px;
          `;
        case 'left':
          return css`
            border-left-color: ${backgroundColor};
            right: -11px;
            top: 50%;
            transform: translateY(-50%);
          `;
        case 'right':
          return css`
            border-right-color: ${backgroundColor};
            left: -11px;
            top: 50%;
            transform: translateY(-50%);
          `;
        default:
          return '';
      }
    }}
  }
  @media (max-width: 449px) {
    max-width: ${(props) => props.maxWidth || 'calc(100% - 52px)'};
  }
`;
