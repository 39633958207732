/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Header from './header';
import {
  Group,
  Container,
  Projectitem,
  Icon,
  Title,
  Text,
  Assigngroup1,
  ProjectName,
  Modalhead,
  Headline,
  Topcontent,
  Bottomcontent,
  Projectlist,
  Titletext,
  Loadingstate,
  Div,
  Emptybox,
  ShowHideText,
  Rightdiv,
  BottomHeader,
  FilterDiv,
  SVGDiv,
  InputArea,
  SearchArea,
  MoreIcon,
  MoreOptionSection,
  SVGIconDiv,
  Point
} from './styles';
import AppLayout from '../../component/appLayout';
import { useDispatch, useSelector } from 'react-redux';
import {
  addInvitedMember,
  favoriteProject,
  getProjectList,
  getProjectListUserNotIn,
  updateProjectData
} from '../../services/projectServices';
import {
  CUSTOMER_MEMBER_ROLE,
  MENU_OPTIONS,
  PROJECT_FILTER_TYPE,
  PROJECT_STATUS,
  PROJECT_STATUS_COLOR
} from '../../global/constants';
import { useHistory } from 'react-router-dom';
import { clearProjectDetail, setCurrentProject, setProjectDetail } from '../../actions/projectActions';
import Avatar from '../../component/avatar/Avatar';
import EmptyState from '../../component/emptyState';
import projectempty from '../../assets/images/emptystates/projectlightempty.svg';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import CreateNewProjectModal from '../../component/models/createNewProjectModal';
import { isEmpty } from '../../helpers/common';
import ModalCustom from '../../component/models/modal';
import projectdarkempty from '../../assets/images/emptystates/projectdarkempty.svg';
import noprojectfound from '../../assets/images/emptystates/noprojectfound.svg';
import noprojectfounddark from '../../assets/images/emptystates/noprojectfounddark.svg';
import ProjectallLoading from '../../component/loading/projectallloading';
import Favourite from './favourite';
import Projectloading from '../../component/loading/projectloading';
import { captureException } from '../../services/logService';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import Button from '../../component/button';
import { getUserPreferenceFieldData, updateUserPreference } from '../../helpers/firebaseHelper';
import Deletemodal from '../../component/models/deleteModel';
import { setErrorMessage, setSuccessMessage } from '../../actions/messageActions';
import { trackAnalyticActivity } from '../../services/analyticsService';
import { PROJECT_ANALYTICS } from '../../global/analyticsConstants';
import { Dropdown, DropdownItem } from '../../component/Dropdown';
import { rootStore } from '../../mobx/rootStore';
import { ProjectModel } from '../../mobx/models/project';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
const Project: React.FC = () => {
  // Refs
  const buttonRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  // States
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isViewAll, setIsViewAll] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isArchiveModal, setIsArchiveModal] = useState(false);
  const [isJoiningProjectId, setIsJoiningProjectId] = useState<string | null>(null);

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, project: projectSelector } = stateSelector || {};
  const { newProjectItem } = projectSelector;
  const { themeMode } = settingsSelector;
  //mobx store variables
  const { projectStore, workspaceStore } = rootStore;
  const { currentWorkspace: workspace } = workspaceStore;
  const workspace_id = workspace?.id;
  const { projects, favoriteProjects, query, filteredProjects, projectUserNotIn } = projectStore;

  // Other variables
  const history = useHistory();
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const MoremenuItems = [
    { key: MENU_OPTIONS.EDIT, label: 'Edit' },
    { key: MENU_OPTIONS.ARCHIVE, label: 'Archive' }
  ];

  const getImageSource = () => (themeMode?.theme === 'dark' ? projectdarkempty : projectempty);
  const notFoundProjectSource = () => (themeMode?.theme === 'dark' ? noprojectfounddark : noprojectfound);

  const handleViewClick = () => {
    setIsViewAll(!isViewAll);
  };

  const handleFilterClick = useCallback(async (key: string | number) => {
    key = Number(key);
    if (key === PROJECT_FILTER_TYPE.ALPHABETIC) {
      rootStore.projectStore.setSortType(PROJECT_FILTER_TYPE.ALPHABETIC);
      await updateUserPreference({ project_sorting: PROJECT_FILTER_TYPE.ALPHABETIC });
    } else {
      rootStore.projectStore.setSortType(PROJECT_FILTER_TYPE.CREATED_DATE);
      await updateUserPreference({ project_sorting: PROJECT_FILTER_TYPE.CREATED_DATE });
    }
  }, []);

  const handleSearch = useCallback((value: string) => {
    rootStore.projectStore.setQuery(value);
  }, []);

  const loadData = useCallback(async () => {
    try {
      if (workspace_id) {
        setLoading(true);
        const filterOptionLocal = (await getUserPreferenceFieldData('project_sorting')) || 1;
        rootStore.projectStore.setSortType(filterOptionLocal);
        let promises = [dispatch(getProjectList(true))];
        if (workspace?.isAdmin || workspace?.isOwner) {
          promises.push(dispatch(getProjectListUserNotIn()));
        }
        await Promise.all(promises);
      }
    } catch (e) {
      captureException(e);
      console.log('error', e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [dispatch, workspace, workspace_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onClickFavorite = useCallback(
    async (e: React.SyntheticEvent, project: ProjectModel, value: boolean) => {
      try {
        e.stopPropagation();
        project.setFavorite(value);
        await dispatch(favoriteProject(workspace_id, project?.id, value));
      } catch (e) {
        captureException(e);
        console.log('error', e);
        setLoading(false);
      }
    },
    [dispatch, workspace_id]
  );

  const onClickProjectList = useCallback(
    (item: ProjectModel) => {
      dispatch(setCurrentProject(toJS(item) as any)); //Remove any
      history.push(`/projects/details/${item?.id}/tasks`);
    },
    [dispatch, history]
  );

  const OpenModal = () => {
    setEditModal(false);
    setOpen(true);
  };

  const Cancel = useCallback(() => {
    if (editModal) {
      setConfirmationModalOpen(true);
    } else {
      setOpen(false);
      dispatch(clearProjectDetail());
    }
  }, [dispatch, editModal]);

  const onSendMail = useCallback(() => {
    const ownerUser = workspace?.users?.find((user) => user?.isOwner === true);
    const newTab = window.open(`mailto:${ownerUser?.email}`, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      alert('Please allow pop-ups for this site to open in a new tab.');
    }
  }, [workspace?.users]);

  const onClickJoin = useCallback(
    async (e: React.SyntheticEvent, project: ProjectModel) => {
      try {
        e.stopPropagation();
        setIsJoiningProjectId(project.id);
        //Changes needed
        const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
        // const updatedProject = {
        //   ...project,
        //   users: [...(project?.users || []), userDetails]
        // };
        // dispatch(setProjectList([...list, updatedProject]));
        // const updatedProjectListUserNotIn = projectListUserNotIn?.filter((item) => item?.id !== project?.id);
        // dispatch(setProjectListUserNotIn(updatedProjectListUserNotIn));
        const payload = {
          userId: userDetails?.id,
          newUserId: userDetails?.id,
          companyId: workspace_id,
          projectId: project?.id
        };
        await dispatch(addInvitedMember(payload, true, true));
        rootStore.projectStore.joinProject(project.id);
      } catch (error) {
        captureException(error);
        console.log('error', error);
      } finally {
        setIsJoiningProjectId(null);
      }
    },
    [dispatch, workspace_id]
  );

  const handleMenuOptionClick = useCallback(
    (key: number | string, projectItem: ProjectModel) => {
      dispatch(setProjectDetail(toJS(projectItem) as any)); //Remove any
      if (key === MENU_OPTIONS.EDIT) {
        setEditModal(true);
        setOpen(true);
        setIsArchiveModal(false);
      } else if (key === MENU_OPTIONS.ARCHIVE) {
        setConfirmationModalOpen(true);
        setIsArchiveModal(true);
      }
    },
    [dispatch]
  );

  const closeEditPojectModal = useCallback(() => {
    setOpen(false);
  }, []);

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const onConfirmClick = useCallback(() => {
    setConfirmationModalOpen(false);
    setOpen(false);
  }, []);

  const onArchiveProject = useCallback(async () => {
    try {
      setLoading(true);
      if (!workspace?.isAdmin) return dispatch(setErrorMessage(`You can't archive project.`));
      const updatePayload = {
        isArchived: true
      };
      const result = await dispatch(updateProjectData(newProjectItem?.id, updatePayload));
      if (result) {
        loadData();
        trackAnalyticActivity(PROJECT_ANALYTICS.DELETED);
        setConfirmationModalOpen(false);
        dispatch(setSuccessMessage('Project deleted successfully.'));
      }
    } catch (e) {
      captureException(e);
      console.log('ERROR', e);
    } finally {
      setLoading(false);
    }
  }, [dispatch, loadData, newProjectItem?.id, workspace?.isAdmin]);

  const renderMoreMenuItems = useCallback(
    (projectItem: ProjectModel) => {
      return MoremenuItems.map((item) => (
        <DropdownItem
          key={item.key}
          label={item.label}
          onClick={() => handleMenuOptionClick(item.key, projectItem)}
          tone={item.label === 'Archive' ? 'critical' : 'primary'}
        />
      ));
    },
    [MoremenuItems, handleMenuOptionClick]
  );

  const renderFilterItems = useMemo(() => {
    const menuItems = [
      { key: PROJECT_FILTER_TYPE.ALPHABETIC, label: 'Alphabetic' },
      { key: PROJECT_FILTER_TYPE.CREATED_DATE, label: 'Created date' }
    ];
    return menuItems.map((item) => (
      <DropdownItem key={item.key} label={item.label} onClick={() => handleFilterClick(item.key)} />
    ));
  }, [handleFilterClick]);
  //all project list items
  const projectListItems = useMemo(() => {
    return filteredProjects?.map((item, index) => {
      const isLast = filteredProjects?.length - 1 === index;
      const isFirst = index === 0;
      const isOnlyOneItem = filteredProjects?.length === 1;

      if (!buttonRef?.current[index]) {
        buttonRef.current[index] = React.createRef<HTMLDivElement>();
      }

      return (
        <Projectitem
          key={item?.id}
          isFirst={isFirst}
          isLast={isLast}
          isOnlyOneItem={isOnlyOneItem}
          onClick={() => onClickProjectList(item)}>
          <ProjectName>
            <Icon
              onClick={(e) => {
                onClickFavorite(e, item, !item?.isFavorite);
              }}>
              {item?.isFavorite ? (
                <SVGIcon name='fill-star-icon' viewBox='0 0 18 18' width='16' height='16' className='fill-icon' />
              ) : (
                <SVGIcon name='star-icon' viewBox='0 0 18 18' width='16' height='16' className='star-icon' />
              )}
            </Icon>
            <Div>
              <Title>{item?.name}</Title>
              {!isEmpty(item?.customer?.companyName) && <Text>for {item?.customer?.companyName}</Text>}
            </Div>
          </ProjectName>
          <Point color={PROJECT_STATUS_COLOR[item?.progressData.status || PROJECT_STATUS.NOT_STARTED]}>
            <span>
              <small></small>
              {item?.progressData.statusText || 'Not Started yet'}{' '}
              {item?.progressData.status !== PROJECT_STATUS.NOT_STARTED && `(${item?.progressData.progress || 0}%)`}
            </span>
          </Point>
          <MoreOptionSection>
            <Assigngroup1>
              <ul>
                {item?.users?.map((img, idx) => {
                  if (idx > 3) return <React.Fragment key={img?.id}></React.Fragment>;
                  return (
                    <li key={img?.id}>
                      <Avatar
                        imgSrc={img?.profile_photo || ''}
                        name={img?.name ? img.name : 'U N'}
                        size={20}
                        isMilestoneBorder
                        classname='avtar'
                      />
                    </li>
                  );
                })}
                {item?.users?.length > 4 && <li className='plus-icon'>+{item?.users?.length - 4}</li>}
              </ul>
            </Assigngroup1>
            {(workspace?.isAdmin || workspace?.isOwner) && (
              <Dropdown
                activeClassName='active'
                content={
                  <MoreIcon>
                    <SVGIcon
                      name='more-icon'
                      width='18'
                      height='18'
                      viewBox='0 0 18 18'
                      fill='none'
                      className='projectlist-more-icon'
                    />
                  </MoreIcon>
                }
                trigger='click'>
                {renderMoreMenuItems(item)}
              </Dropdown>
            )}
          </MoreOptionSection>
        </Projectitem>
      );
    });
  }, [
    filteredProjects,
    workspace?.isAdmin,
    workspace?.isOwner,
    renderMoreMenuItems,
    onClickProjectList,
    onClickFavorite
  ]);

  return (
    <>
      <AppLayout>
        <Group>
          <Header workspaceDetails={workspace} />
          {loading && (!projects || projects?.length === 0) && (
            <>
              <Loadingstate>
                <Projectloading />
                <ProjectallLoading />
              </Loadingstate>
            </>
          )}
          {!loading && (!projects || projects?.length === 0) && (
            <Emptybox>
              <EmptyState
                isProjectEmpty={true}
                header={'You have no projects yet'}
                title={
                  workspace?.customerRole === CUSTOMER_MEMBER_ROLE.PROJECT_ONLY ||
                  workspace?.customerRole === CUSTOMER_MEMBER_ROLE.FULL_ACCESS
                    ? `It appears that you haven't been invited to any projects yet. Please ask your vendor to add you to a project.`
                    : !workspace?.isAdmin && !workspace?.isOwner
                    ? `It appears that you haven't been invited to any projects yet.
                   Please ask your admin to add you to a project.`
                    : 'You can create new project by clicking the button below.'
                }
                image={getImageSource()}
                name={
                  workspace?.customerRole === CUSTOMER_MEMBER_ROLE.PROJECT_ONLY ||
                  workspace?.customerRole === CUSTOMER_MEMBER_ROLE.FULL_ACCESS
                    ? 'Request Project Invitation'
                    : 'New Project'
                }
                onButtonClick={
                  workspace?.customerRole === CUSTOMER_MEMBER_ROLE.PROJECT_ONLY ||
                  workspace?.customerRole === CUSTOMER_MEMBER_ROLE.FULL_ACCESS
                    ? onSendMail
                    : OpenModal
                }
                hideButton={
                  !workspace?.isAdmin &&
                  !workspace?.isOwner &&
                  workspace?.customerRole !== CUSTOMER_MEMBER_ROLE.PROJECT_ONLY &&
                  workspace?.customerRole !== CUSTOMER_MEMBER_ROLE.FULL_ACCESS
                }
              />
            </Emptybox>
          )}
          {projects?.length > 0 && (
            <Container>
              {favoriteProjects?.length > 0 && (
                <Topcontent>
                  <Titletext>Favourite</Titletext>
                  <Favourite
                    onClickProjectList={(project) => onClickProjectList(project)}
                    onClickFavorite={(e, project, value) => onClickFavorite(e, project, value)}
                  />
                </Topcontent>
              )}
              <Bottomcontent>
                {projects?.length > 0 && favoriteProjects?.length > 0 && (
                  <BottomHeader>
                    <Titletext>All Projects</Titletext>
                    <FilterDiv>
                      <SearchArea isSearch={isSearch}>
                        <SVGDiv isSearchInputIcon={isSearch} onClick={() => setIsSearch(true)}>
                          <SVGIcon
                            name='project-search-icon'
                            width='16'
                            height='16'
                            viewBox='0 0 16 16'
                            className={isSearch ? 'search-icon-active' : 'search-icon'}
                          />
                        </SVGDiv>
                        {isSearch && (
                          <>
                            <InputArea
                              isSearch={isSearch}
                              value={query}
                              onChange={(e) => handleSearch(e.target.value)}
                            />
                            <SVGDiv
                              isCloseInputIcon
                              onClick={() => {
                                setIsSearch(false);
                              }}>
                              <SVGIcon
                                name='close-icon'
                                width='18'
                                height='18'
                                viewBox='0 0 18 18'
                                fill='none'
                                className='svgicon'
                              />
                            </SVGDiv>
                          </>
                        )}
                      </SearchArea>
                      <Dropdown
                        activeClassName='active'
                        isMinWidth={128}
                        content={
                          <SVGIconDiv>
                            <SVGIcon
                              name='small-filters-icon'
                              width='16'
                              height='16'
                              viewBox='0 0 14 14'
                              className='filters-icon-color'
                            />
                          </SVGIconDiv>
                        }
                        trigger='click'>
                        {renderFilterItems}
                      </Dropdown>
                    </FilterDiv>
                  </BottomHeader>
                )}
                {projects && projects?.length > 0 && <Projectlist>{projectListItems}</Projectlist>}

                {/* Empty state for not found projects on search filter */}
                {filteredProjects && filteredProjects?.length === 0 && (
                  <div style={{ marginBottom: 12 }}>
                    <EmptyState
                      image={notFoundProjectSource()}
                      hideButton
                      title='No Search Result Found'
                      isAllProjectEmpty={true}
                    />
                  </div>
                )}
                {projectUserNotIn && projectUserNotIn?.length > 0 && (
                  <ShowHideText>
                    {`You're not part of ${projectUserNotIn?.length} other projects.`}
                    <span onClick={handleViewClick}> {isViewAll ? 'View them all' : 'Hide them all'}</span>
                  </ShowHideText>
                )}

                {projectUserNotIn && projectUserNotIn?.length > 0 && !isViewAll && (
                  <Projectlist>
                    {projectUserNotIn?.map((item, index: number) => {
                      let isLast = projectUserNotIn?.length - 1 === index;
                      let isFirst = index === 0;
                      let isOnlyOneItem = projectUserNotIn?.length === 1;
                      return (
                        <Projectitem
                          className='admin-project'
                          key={item?.id}
                          isFirst={isFirst}
                          isLast={isLast}
                          isOnlyOneItem={isOnlyOneItem}>
                          <ProjectName>
                            <Div>
                              <Title>{item?.name}</Title>
                              {!isEmpty(item?.customer?.companyName) && <Text>for {item?.customer?.companyName}</Text>}
                            </Div>
                          </ProjectName>

                          <Rightdiv>
                            <Assigngroup1 className='admin-assign'>
                              <ul>
                                {item?.users?.map((img, index) => {
                                  if (index > 3) return <React.Fragment key={img?.id}></React.Fragment>;
                                  return (
                                    <li key={img?.id}>
                                      <Avatar
                                        imgSrc={img?.profile_photo || ''}
                                        name={img?.name ? img.name : 'U N'}
                                        size={20}
                                        isMilestoneBorder
                                        classname='avtar'
                                      />
                                    </li>
                                  );
                                })}
                                {item?.users?.length > 4 && <li className='plus-icon'>+{item?.users?.length - 4}</li>}
                              </ul>
                            </Assigngroup1>
                            <Button
                              title='Join'
                              secondary={true}
                              isPadding={true}
                              hasNotBoxshadow
                              isLoading={isJoiningProjectId === item?.id}
                              onClick={(e: React.SyntheticEvent) => onClickJoin(e, item)}
                            />
                          </Rightdiv>
                        </Projectitem>
                      );
                    })}
                  </Projectlist>
                )}
              </Bottomcontent>
            </Container>
          )}
          <ModalCustom open={isOpen} onClose={Cancel} width={632}>
            <Modalhead>
              <Headline>{editModal ? 'Edit Project' : 'Create New Project'}</Headline>
              <Icon onClick={Cancel}>
                <SVGIcon
                  name='close-icon'
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  className='close-icon'
                />
              </Icon>
            </Modalhead>
            <CreateNewProjectModal
              handleCloseModal={closeEditPojectModal}
              onClickCancel={Cancel}
              loadProjectData={() => loadData()}
            />
          </ModalCustom>
        </Group>
        <ModalCustom open={confirmationModalOpen} onClose={closeConfirmationModal} width={334}>
          <Deletemodal
            isYes={true}
            onClose={closeConfirmationModal}
            onOk={!isArchiveModal ? onConfirmClick : onArchiveProject}
            modaltitle='Confirmation Model'
            description={
              isArchiveModal
                ? 'Confirming archive? You can restore it later if needed.'
                : 'Are you sure you want close this model?'
            }
            loading={isArchiveModal ? loading : false}
          />
        </ModalCustom>
      </AppLayout>
    </>
  );
};

export default observer(Project);
