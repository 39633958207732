import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface IconProps {
  isCardActive: boolean;
}
interface TaskButtonProps {
  isActive: boolean;
}
interface Props {
  isLeftPadding?: boolean;
  iswidth?: boolean;
  istasklist?: boolean;
}

const Container = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  background-color: var(--background-secondary);
  margin: 0 -24px 24px -24px;
  border-bottom: 0.5px solid var(--border-primary);
  ${(props: Props) =>
    props.isLeftPadding &&
    css`
      padding-left: 48px;
    `}

  .reponsivetitle {
    display: none;
  }
  @media (max-width: 449px) {
    padding: 0;
    align-items: flex-start;
    flex-direction: column-reverse;
    padding: 0 16px;
    margin: 0 -16px 12px;
    border-bottom: none;
    height: auto;

    .web {
      display: none;
    }
    .reponsivetitle {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;
      @media (max-width: 449px) {
        ${Typography.heading_lg}
      }
    }
    ${(props: Props) =>
      props.isLeftPadding &&
      css`
        padding-left: 16px;
      `}
  }
`;
const Maincontent = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 449px) {
    overflow-x: auto;
    width: 100%;
    padding: 12px 16px;
    margin: 0 -16px;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ResponsiveContent = styled.div`
  @media (max-width: 449px) {
    width: 100%;
  }
`;
const Title = styled.h1`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
`;

const Buttonblock = styled.div`
  display: flex;
  gap: 4px;
  cursor: pointer;
  align-items: center;
  position: relative;
  @media (max-width: 449px) {
    gap: 5px;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Taskbutton = styled.button<TaskButtonProps>`
  background-color: transparent;
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  padding: 3px 12px;
  border: 0.5px solid transparent;
  border-radius: 999px;
  cursor: pointer;
  height: 26px;
  :hover {
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-card);
    border-radius: 999px;
  }

  ${(props: TaskButtonProps) =>
    props.isActive &&
    css`
      background-color: var(--background-primary);
      border: 0.5px solid var(--border-primary);
      box-shadow: var(--shadow-card);
      border-radius: 999px;
      padding: 3px 12px;
      ${Typography.body_md_medium}
      color: var(--text-primary);
    `}

  @media (max-width: 449px) {
    padding: 6px 15px;
    height: auto;
  }
`;

const Options = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--background-secondary);
  border-radius: 18px;
  padding: 4px;
  @media (max-width: 449px) {
    height: 26px;
    padding: 3px;
  }
`;

const SameIcon = styled.div<IconProps>`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
  padding: 5px 14px;
  ${(props: IconProps) =>
    props.isCardActive &&
    css`
      background-color: var(--background-tertiary);
      border-radius: 14px;
      border: 0.5px solid var(--border-primary);
      box-shadow: var(--shadow-card);

      color: var(--text-primary);
      .line-fill-color {
        rect {
          fill: var(--text-primary) !important;
        }
      }
      .fill-color {
        path {
          stroke: var(--text-primary) !important;
        }
      }
      .calendar-fill-color {
        path {
          fill: var(--text-primary) !important;
        }
      }
    `}
  &.files-filter {
    padding: 6px 21px;
    @media (max-width: 449px) {
      width: 100%;
      padding: 5px 21px;
    }
  }
  @media (max-width: 449px) {
    justify-content: center;
  }
  .fill-color {
    path {
      stroke: var(--text-secondary);
    }
  }
  .line-fill-color {
    rect {
      fill: var(--text-secondary);
    }
  }
  :hover {
    ${(props: IconProps) =>
      !props.isCardActive &&
      css`
        background-color: var(--background-muted);
        border-radius: 14px;
      `}
  }
  .calendar-fill-color {
    path {
      fill: var(--text-secondary);
    }
  }
`;

const PlusButton = styled.div`
  display: flex;
  align-items: center;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  .circle {
    width: 26px;
    height: 26px;
    fill: var(--brand-primary);
  }
  @media (max-width: 449px) {
    width: 24px;
    height: 24px;
  }
`;

const FileDropdown = styled.div`
  width: 100%;
  max-width: 198px;
  min-width: 198px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-modal);
  border-radius: 8px;
`;

const FileDropContent = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 449px) {
    gap: 12px;
  }
`;

const Dropblock = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
  padding: 4px 6px;
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

const DropText = styled.p`
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0;
`;

const DropView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  border-bottom: 0.5px solid var(--border-primary);
  padding: 4px;
  padding-bottom: 7px;
`;

const DropViewer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 4px;
`;

const TextBlock = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  padding: 4px 16px 4px 12px;
  @media (max-width: 449px) {
    padding-left: 22px;
  }
`;

const Icon = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  .fillColor {
    fill: var(--text-primary);
  }
  .fill-Color {
    stroke: var(--text-primary);
  }
  .svgicon {
    path {
      stroke: var(--text-secondary);
      fill: var(--text-secondary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
  .close-icon {
    stroke: var(--text-primary);
  }
  .stroke-color {
    path {
      stroke: var(--text-primary);
    }
  }
`;

const ModalButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const SecondaryButton = styled.button`
  padding: 7px 20px;
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-button);
  border-radius: 36px;
  background-color: var(--text-white);
  ${Typography.body_md_medium}
  color: var(--text-primary);
  cursor: pointer;
  :hover {
    background-color: var(--background-primary);
  }
`;

const PrimaryButton = styled.a`
  padding: 6px 20px;
  background-color: var(--brand-primary);
  border-radius: 36px;
  border: none;
  ${Typography.body_md_medium}
  color: var(--text-white);
  cursor: pointer;
  :hover {
    background-color: var(--brand-primary-hover);
  }
  :link {
    text-decoration: none;
  }

  :visited {
    text-decoration: none;
  }

  :hover {
    text-decoration: none;
  }

  :active {
    text-decoration: none;
  }
`;

const Modalhead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 0.5px solid var(--border-primary);
`;

const Headline = styled.h6`
  ${Typography.body_lg_semibold}
  color: var(--text-primary);
  margin: 0;
`;

const Taskicons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  height: 26px;
  @media (max-width: 449px) {
    gap: 16px;
    height: 32px;
  }
`;
const LastIcon = styled.div`
  cursor: pointer;
  height: 26px;
  .circle {
    fill: var(--brand-primary);
  }
`;
const Filter = styled.div`
  cursor: pointer;
  display: inline-flex;
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 16px;
  }
  .filter {
    path {
      fill: var(--text-secondary);
    }
  }
  @media (max-width: 449px) {
    .filter {
      display: none;
    }
  }
  .responsive-filter {
    display: none;
  }
  @media (max-width: 449px) {
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    .responsive-filter {
      width: 16px;
      height: 16px;
      display: block;
      stroke: var(--text-secondary);
    }
  }
`;
const TaskDropdown = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const Taskdrop = styled.div<Props>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 12px;
  ${(props: Props) =>
    props.iswidth &&
    css`
      width: 174px;
    `}
  ${(props: Props) =>
    props.istasklist &&
    css`
      padding-bottom: 12px;
      border-bottom: 0.5px solid var(--border-primary);
    `}
`;

const Data = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  justify-content: space-between;
  gap: 10px;
  @media (max-width: 449px) {
    gap: 10px;
  }
`;

const DisplayProperty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;
  flex-flow: row wrap;
  width: 176px;
`;
const Property = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  border-top: 0.5px solid var(--border-primary);
  padding-top: 12px;
  width: 100%;

  p {
    ${Typography.body_sm}
    color: var(--text-secondary);
    margin: 0;
  }
`;
const TaskBox = styled.div`
  padding: 2px 5px;
  background: var(--border-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 3px;
  ${Typography.body_xs}
  color: var(--text-primary);
  margin: 0;
`;
const StatusBox = styled.div`
  padding: 2px 5px;
  border: 0.5px solid var(--border-primary);
  border-radius: 3px;
  ${Typography.body_xs}
  color: var(--text-primary);
  margin: 0;
`;

const Hiddennow = styled.div`
  display: none;
`;

const Status = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 7px;
  gap: 10px;
  border: 0.5px solid var(--border-primary);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  p {
    ${Typography.body_md}
    margin: 0;
    color: var(--text-primary);
  }
  @media (max-width: 449px) {
    padding: 6px 10px;
    p {
      white-space: nowrap;
    }
  }
  .fill-color {
    stroke: var(--text-primary);
  }
  .dropdown-icon {
    transform: rotate(180deg);
  }
`;

const StarWithTitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  @media (max-width: 449px) {
    display: none;
  }
`;

const ThreeDotIcon = styled.div`
  margin-left: 14px;
  height: 24px;
  width: 24px;
`;

const ResponsiveNavbarWithTitle = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
    gap: 24px;
  }
`;
const ResponsiveTopNavbar = styled.div`
  @media (max-width: 449px) {
    gap: 24px;
    width: 100%;
    display: flex;
    padding: 12px 16px;
    margin: 0 -16px;
    justify-content: space-between;
    background: var(--background-primary);
    border-bottom: 0.5px solid var(--border-primary);
    height: 31px;
    align-items: center;
  }
`;

const DIV = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 6px;
`;
const SVGDiv = styled.div`
  display: flex;
  align-items: center;
  svg {
    fill: none;
  }
  .filters-icon-color {
    fill: var(--text-secondary);
    stroke: none;
  }
`;
const TaskListName = styled.p`
  margin: 0;
  ${Typography.body_sm};
  color: var(--text-secondary);
  ${(props: IconProps) =>
    props.isCardActive &&
    css`
      color: var(--text-primary);
    `}
`;

const GroupDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  svg {
    stroke: var(--text-secondary);
  }
`;

const Switch = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--border-primary);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    top: 3px;
    left: 3px;
    background-color: var(--background-secondary);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background: var(--brand-primary);
  }

  input:checked + .slider:before {
    transform: translateX(20px);
    background-color: var(--text-white);
  }

  .slider.round {
    border-radius: 20px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

const TaskFilterDiv = styled.div`
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  gap: 12px;
`;
export {
  Container,
  Content,
  Title,
  Taskbutton,
  Buttonblock,
  Options,
  SameIcon,
  PlusButton,
  FileDropdown,
  FileDropContent,
  DropView,
  Dropblock,
  Icon,
  DropText,
  DropViewer,
  TextBlock,
  ModalButton,
  SecondaryButton,
  PrimaryButton,
  Modalhead,
  Headline,
  Taskicons,
  Filter,
  Data,
  Taskdrop,
  TaskDropdown,
  DisplayProperty,
  Property,
  TaskBox,
  StatusBox,
  Hiddennow,
  Status,
  Maincontent,
  LastIcon,
  StarWithTitle,
  ThreeDotIcon,
  ResponsiveContent,
  ResponsiveNavbarWithTitle,
  ResponsiveTopNavbar,
  DIV,
  TaskListName,
  SVGDiv,
  GroupDiv,
  Switch,
  TaskFilterDiv
};
