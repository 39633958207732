import React, { useState, useRef, useEffect } from 'react';
import { TooltipContainer, TooltipContent } from './styles';
import { useSelector } from 'react-redux';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { useMobileDevice } from '../../global/useMobile';
import { createPortal } from 'react-dom';

interface TooltipProps {
  title: React.ReactNode;
  children: React.ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right' | 'bottomRight' | 'bottomLeft' | 'topRight' | 'topLeft';
  maxWidth: string | number;
  isInModal?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({ title, children, placement = 'bottom', maxWidth, isInModal = false }) => {
  //  state variables
  const [isVisible, setIsVisible] = useState(false);
  const [arrowPosition, setArrowPosition] = useState({ left: '50%', top: '0' });
  // ref variables
  const tooltipRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  // other Variables
  const settingsSelector = useSelector((state: RootReducerInterface) => state.settings);
  const { themeMode } = settingsSelector;
  const isMobile = useMobileDevice();

  // mouse Enter and Leave will only work for desktop
  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsVisible(true);
    }
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };
  // this will only work for responsive devices
  const handleClick = () => {
    if (isMobile) {
      setIsVisible(!isVisible);
    }
  };

  useEffect(() => {
    const calculatePosition = () => {
      if (!tooltipRef.current || !targetRef.current) return;

      const targetRect = targetRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const spacing = 12;

      let top = 0;
      let left = 0;
      let arrowLeft = '50%';
      let arrowTop = '0';

      switch (placement) {
        case 'bottomRight':
          top = targetRect.bottom + 5;
          left = targetRect.right - tooltipRect.width + 33;
          // Calculate arrow position relative to tooltip
          arrowLeft = `${targetRect.right - left - targetRect.width / 2}px`;
          arrowTop = '-12px';
          break;
        case 'bottomLeft':
          top = targetRect.bottom + 5;
          left = targetRect.left - 23;
          arrowLeft = `${targetRect.left - left + targetRect.width / 2}px`;
          arrowTop = '-12px';
          break;
        case 'bottom':
          top = targetRect.bottom + 5;
          left = targetRect.left + (targetRect.width - tooltipRect.width) / 2;
          arrowLeft = '50%';
          arrowTop = '-12px';
          break;
        case 'topRight':
          top = targetRect.top - tooltipRect.height - 5;
          left = targetRect.right - tooltipRect.width + 33;
          arrowLeft = `${targetRect.right - left - targetRect.width / 2}px`;
          arrowTop = '100%';
          break;
        case 'topLeft':
          top = targetRect.top - tooltipRect.height - 5;
          left = targetRect.left - 23;
          arrowLeft = `${targetRect.left - left + targetRect.width / 2}px`;
          arrowTop = '100%';
          break;
        case 'top':
          top = targetRect.top - tooltipRect.height - 5;
          left = targetRect.left + (targetRect.width - tooltipRect.width + 2) / 2;
          arrowLeft = '50%';
          arrowTop = '100%';
          break;
        case 'left':
          top = targetRect.top + (targetRect.height - tooltipRect.height - 4) / 2;
          left = targetRect.left - tooltipRect.width - 5;
          arrowLeft = '100%';
          arrowTop = '50%';
          break;
        case 'right':
          top = targetRect.top + (targetRect.height - tooltipRect.height - 4) / 2;
          left = targetRect.right + 5;
          arrowLeft = '-12px';
          arrowTop = '50%';
          break;
      }
      // Ensure tooltip stays within viewport
      const viewport = {
        right: window.innerWidth - spacing,
        bottom: window.innerHeight - spacing
      };

      // Adjust tooltip position if it goes outside viewport
      if (left + tooltipRect.width > viewport.right) {
        // const oldLeft = left;
        left = viewport.right - tooltipRect.width;
        // Readjust arrow position
        arrowLeft = `${targetRect.left - left + targetRect.width / 2}px`;
      }
      if (left < spacing) {
        left = spacing;
        // Readjust arrow position
        arrowLeft = `${targetRect.left - left + targetRect.width / 2}px`;
      }

      tooltipRef.current.style.top = `${top}px`;
      tooltipRef.current.style.left = `${left}px`;
      setArrowPosition({ left: arrowLeft, top: arrowTop });
    };

    if (isVisible) {
      calculatePosition();
      window.addEventListener('scroll', calculatePosition);
      window.addEventListener('resize', calculatePosition);
    }

    return () => {
      window.removeEventListener('scroll', calculatePosition);
      window.removeEventListener('resize', calculatePosition);
    };
  }, [isVisible, placement]);

  const tooltipContent = (
    <TooltipContent
      ref={tooltipRef}
      placement={placement}
      arrowLeft={arrowPosition.left}
      arrowTop={arrowPosition.top}
      backgroundColor={themeMode?.theme === 'dark' ? 'var(--text-placeholder)' : 'var(--border-primary)'}
      isInModal={isInModal}
      maxWidth={maxWidth}>
      {title}
    </TooltipContent>
  );

  return (
    <TooltipContainer>
      {/* onclick will only work for responsive devices */}
      <div ref={targetRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
        {children}
      </div>
      {/* used CreatePortal for tooltips to avoid z-index and positioning issues when appliied in modal */}
      {isVisible && (isInModal ? createPortal(tooltipContent, document.body) : tooltipContent)}
    </TooltipContainer>
  );
};

export default Tooltip;
