import React, { useCallback } from 'react';
import {
  Bottom,
  BottomBox,
  BottomText,
  Description,
  IconDiv,
  Line,
  Link,
  LinkText,
  ModalBox,
  Title,
  TitleDiv
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Button from '../../button';
import copy from 'clipboard-copy';
import { setSuccessMessage } from '../../../actions/messageActions';
import { captureException } from '../../../services/logService';
import { useDispatch } from 'react-redux';
import Tooltip from '../../Tooltip';
interface Props {
  onCancel?: () => void;
  clientLoginUrl: string;
  isFromTeam?: boolean;
}
export default function SuccessfullModel(props: Props) {
  const { isFromTeam } = props;
  //Other variable
  const dispatch = useDispatch();

  const onClickCopy = useCallback(
    async (clientInviteUrl: string) => {
      try {
        if (clientInviteUrl !== undefined) {
          await copy(clientInviteUrl);
          dispatch(setSuccessMessage('Client invite url copied successfully!'));
        }
      } catch (error) {
        captureException(error);
        console.log('Failed to copy url: ', error);
      }
    },
    [dispatch]
  );

  return (
    <div>
      <ModalBox>
        <IconDiv onClick={props?.onCancel}>
          <SVGIcon name='file-close-icon' width='24' height='24' viewBox='0 0 24 24' className='fillColor' />
        </IconDiv>
        <SVGIcon
          name={'subscripton-successfull-icon'}
          width='56'
          height='56'
          viewBox='0 0 56 56'
          fill='var(--neutral-secondary)'
        />
        <BottomBox>
          <TitleDiv>
            <Title>Invitation Sent Successfully!</Title>
            <Description>
              Your {isFromTeam ? 'team' : 'client'} will receive an email with a link to join Teamcamp.
            </Description>
          </TitleDiv>
          <Line></Line>
          <Bottom>
            <BottomText>You can also share this link with them to join Teamcamp</BottomText>

            <Link>
              <Tooltip placement='bottom' maxWidth={'250px'} title={props.clientLoginUrl} isInModal={true}>
                <LinkText>{props.clientLoginUrl}</LinkText>
              </Tooltip>
              <Button
                title='Copy link'
                secondary={true}
                className='button'
                smallbutton={true}
                hasNotBoxshadow
                onClick={() => onClickCopy(props.clientLoginUrl)}
              />
            </Link>
          </Bottom>
        </BottomBox>
      </ModalBox>
    </div>
  );
}
