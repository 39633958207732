/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable max-len */
import moment from 'moment';
import {
  INVOICE_STATUS_TYPE,
  PROJECT_STATUS_COLOR,
  STATUS_TYPE,
  audioExtensions,
  imageExtensions,
  videoExtensions
} from '../global/constants';
import {
  CategorizedFiles,
  CommentFilesInterface,
  LinkObject,
  MyTaskDetailsInterface
} from '../interfaces/TaskInterface';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from '@firebase/storage';
import { app } from '../utils/firebase';
import DraftImage from '../assets/images/Draft.png';
import OverDueImage from '../assets/images/Over_due.png';
import PartiallyPaidImage from '../assets/images/Partially_Paid.png';
import PendingImage from '../assets/images/Pending.png';
import PaidImage from '../assets/images/Paid.png';
import UserPreferenceSingleton from './userPreferenceSingleton';
import { nanoid } from 'nanoid';
import { Dispatch } from 'react';
import { setUploadFilePercentage } from '../actions/projectActions';
import { Printscreen } from '../component/renderHtml/styles';
import { captureException } from '../services/logService';
import _ from 'lodash';
import { AnyAction } from 'redux';
import { setErrorMessage } from '../actions/messageActions';
import { deleteObject } from 'firebase/storage';
import { CustomWindow } from '../interfaces/OneSignalInterface';
import { RenderImagePreview } from '../component/renderImagePreview';
import { UsersWorkspaceInterface } from '../interfaces/WorkspaceInterface';

declare const window: CustomWindow;

/**
 * @desc Checks if given value is Number
 * @param {*} value // Accepts string
 */
export function isNumber(value: any) {
  const myRegEx = /^\s*\d+(\s+\d+)*\s*$/;
  const isValid = myRegEx.test(value);
  return isValid ? true : false;
}

/**
 * @desc Checks for valid email
 * @param {*} value // Accepts string
 */
export function isEmail(value: any) {
  // eslint-disable-next-line max-len
  const myRegEx =
    // eslint-disable-next-line max-len
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValid = myRegEx.test(value);
  return isValid ? true : false;
}

/**
 * @desc Checks for Empty string
 * @param {*} value // Accepts string, object
 */
export function isEmpty(value: any) {
  if (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  ) {
    return true;
  } else {
    return false;
  }
}

export const getUniqueId = () => {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase();
};

export const isElectronApp = () => {
  if (navigator.userAgent) {
    return navigator.userAgent.toLowerCase().includes('electron');
  }
  return false;
};

export const isMAC = () => {
  if (navigator.userAgent) {
    return navigator.userAgent.toLowerCase().includes('mac') || navigator.userAgent.toLowerCase().includes('macintosh');
  }
  return false;
};

export const isWindows = () => {
  if (navigator.userAgent) {
    return navigator.userAgent.toLowerCase().includes('windows');
  }
  return false;
};

export const isReactNative = () => {
  return window.isRNWebView;
};

export const isIOS = () => {
  return isReactNative() && window.platformOs === 'ios';
};

export const isIphone = () => {
  return isIOS() && !window.isIpad;
};

export const isIpad = () => {
  return isIOS() && window.isIpad;
};

export const isAndroid = () => {
  return isReactNative() && window.platformOs === 'android';
};

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    if (!isEmpty(c)) {
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c && c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return '';
};

export const isFloatOrNumber = (value: string) => {
  // Regular expression to check if the value is a float or number without alphabets
  const regex = /^[+-]?\d*(\.\d*)?$/;

  return regex.test(value);
};

export const groupByProject = (tasks: MyTaskDetailsInterface[]) => {
  const groupedMap = tasks.reduce((acc, task) => {
    if (!acc.has(task.projectId)) {
      acc.set(task.projectId, {
        project_id: task.projectId,
        tasks: [],
        title: task.projectName || ''
      });
    }
    acc.get(task.projectId)!.tasks.push(task);
    return acc;
  }, new Map<string, { project_id: string; tasks: MyTaskDetailsInterface[]; title: string }>());
  return Array.from(groupedMap.values());
};

/**
 * @desc: to group task by milestone
 */
export const groupByMilestone = (collection: any, iteratee: string) => {
  const groupResult = _.groupBy(collection, iteratee);
  return Object.keys(groupResult).map((key) => {
    return { milestone_id: key, tasks: groupResult[key] };
  });
};

/**
 * @desc: to group task by milestone
 */
export const groupByField = (collection: any, iteratee: string) => {
  const groupResult = _.groupBy(collection, iteratee);
  return Object.keys(groupResult).map((key) => {
    return { id: key, tasks: groupResult[key] };
  });
};

/**
 * @desc: to group task by status
 */
export const groupByStatus = (collection: any, iteratee: string) => {
  const groupResult = _.groupBy(collection, iteratee);
  return Object.keys(groupResult).map((key) => {
    const sortedData = groupResult[key].toSorted((a, b) => a.Index - b.Index);
    return { Type: Number(key), data: sortedData };
  });
};

/**
 * @desc check does it dev mode or live mode
 * it return false only if its a production build
 */
export const isDev = () => {
  if (process.env.NODE_ENV === 'development') {
    return true;
  }
  return false;
};

/**
 * @desc get query param by name
 */
export const getUrlParam = (query: any, name: any) => {
  let queryParams = new URLSearchParams();
  if (query) queryParams = new URLSearchParams(query);
  return queryParams.get(name);
};

export function mathRound(number: any, digit = 2) {
  try {
    if (Number(number) < 1) digit = 3;
    if (number) return Number(number).toFixed(digit);
  } catch (e) {
    captureException(e);
  }
  return Number(0).toFixed(2);
}

/**
 * @desc get formatted date
 */
export const getFormattedDate = (date: any) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return (day < 10 ? `0${day}` : day) + '/' + (month < 10 ? `0${month}` : month) + '/' + year;
  // return day + '/' + month + '/' + year;
};

export const getAPIErrorReason = (e: any) => {
  if (e) {
    if (e?.response?.status === 401) {
      UserPreferenceSingleton.getInstance().setCurrentUser(undefined);
      window.location.href = '/membership/login';
    }
    if (e.response?.data) {
      return e.response.data.reason || e.response.data.message || e.response.data.error;
    } else if (e.message) {
      return e.message;
    }
  }
};

export const currencyWithDecimal = (num: any) => {
  let returnValue = num;
  try {
    let digit = 2;
    if (num) {
      if (Number(num) < 1) digit = 3;
      if (Number(num) > 999) digit = 1;
      const num2 = Number(num).toFixed(digit);
      returnValue = num2;
    } else {
      returnValue = Number(0).toFixed(digit);
    }
  } catch (e) {
    captureException(e);
  }
  return returnValue;
};

export const getDateString = (date: any) => {
  //return yyyyMMdd
  if (date) {
    date = new Date(date);
    return date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2);
  }
  return;
};

export const getUTCDate = (date: any) => {
  if (date) date = new Date(date);
  else date = new Date();
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

export const toHHMMSS = (secs: any) => {
  const secNum = parseInt(secs.toString(), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;
  return [hours, minutes, seconds]
    .map((val) => (val < 10 ? `0${val}` : val))
    .join(':')
    .replace(/^0/, '');
};

export const calculateDay = (createdDate: any) => {
  const date = new Date();
  const oldDate = new Date(createdDate);
  const Difference_In_Time = date.getTime() - oldDate.getTime();
  const Difference_In_Days: any = Difference_In_Time / (1000 * 3600 * 24);
  if (Difference_In_Days < 1) return 'Today';
  else if (Difference_In_Days < 7) return `${Math.floor(Difference_In_Days)} days ago`;
  else if (Difference_In_Days < 30 && Math.floor(Difference_In_Days / 7) === 1) return `Week ago`;
  else if (Difference_In_Days < 30 && Math.floor(Difference_In_Days / 7) !== 1)
    return `${Math.floor(Difference_In_Days / 7)} Weeks ago`;
  const months = date.getMonth() - oldDate.getMonth() + 12 * (date.getFullYear() - oldDate.getFullYear());
  if (months < 12) return `${months} Months ago`;
  return `${Math.floor(months / 12)} Years ago`;
};

export const calculateDueTime = (inputDueDate: any) => {
  const currentDate = moment(getUTCDate(new Date()).toISOString());
  const dueDate = moment(getUTCDate(inputDueDate).toISOString());
  const diffInDays = dueDate.diff(currentDate, 'days');
  const absoluteDiffInDays = Math.abs(diffInDays);
  let result, dueParam;
  if (diffInDays === 0) {
    result = 'Today';
    dueParam = 'Today';
  } else if (diffInDays === 1) {
    result = 'Tomorrow';
    dueParam = 'Tomorrow';
  } else if (diffInDays === -1) {
    result = 'Yesterday';
    dueParam = `Yesterday`;
  } else if (diffInDays > 1 && diffInDays <= 7) {
    result = `${diffInDays}d`;
    dueParam = `in ${diffInDays} days`;
  } else if (diffInDays < -1 && diffInDays >= -7) {
    result = `${absoluteDiffInDays}d`;
    dueParam = `${absoluteDiffInDays} days ago`;
  } else if (diffInDays > 7 && diffInDays <= 30) {
    const weeks = Math.floor(diffInDays / 7);
    result = `${weeks}w`;
    dueParam = `in ${weeks} week${weeks > 1 ? 's' : ''}`;
  } else if (diffInDays < -7 && diffInDays >= -30) {
    const weeksLeft = Math.floor(absoluteDiffInDays / 7);
    result = `${weeksLeft}w`;
    dueParam = `${weeksLeft} week${weeksLeft > 1 ? 's' : ''} ago`;
  } else if (diffInDays > 30 && diffInDays <= 365) {
    const months = Math.floor(diffInDays / 30);
    result = `${months}mo`;
    dueParam = `in ${months} month${months > 1 ? 's' : ''}`;
  } else if (diffInDays < -30 && diffInDays >= -365) {
    const monthsLeft = Math.floor(absoluteDiffInDays / 30);
    result = `${monthsLeft}mo`;
    dueParam = `${monthsLeft} month${monthsLeft > 1 ? 's' : ''} ago`;
  } else if (diffInDays > 365) {
    const years = Math.floor(diffInDays / 365);
    result = `${years}y`;
    dueParam = `in ${years} year${years > 1 ? 's' : ''}`;
  } else if (diffInDays < -365) {
    const yearsLeft = Math.floor(absoluteDiffInDays / 365);
    result = `${yearsLeft}y`;
    dueParam = `${yearsLeft} year${yearsLeft > 1 ? 's' : ''} ago`;
  } else {
    const months = Math.floor(diffInDays / 30);
    result = `${months}mo`;
  }
  return { dueTime: result, overDue: diffInDays < 0, dueParam };
};

export const getDayFromDate = (dateString: string): string => {
  const date = new Date(dateString); // Convert the string to a Date object
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days[date.getDay()];
};

export const convertToUTCISO = (dateString: string, timeString: string) => {
  // Parse the date and time in IST
  const momentDate = moment.tz(`${dateString} ${timeString}`, 'DD/MM/YYYY HH:mm', 'Asia/Kolkata');

  // Convert to UTC and format as ISO
  return momentDate.utc().toISOString();
};

export const formatToDateString = (isoDateString: string) => {
  const date = new Date(isoDateString);
  return !isEmpty(isoDateString) ? date.toLocaleDateString('en-GB') : ''; // 'en-GB' format gives "DD/MM/YYYY"
};

export const floatToHHMM = (num: number) => {
  let hours = Math.floor(num);
  let minutes = Math.round((num - hours) * 60);
  return `${hours > 9 ? hours : `0${hours}`} : ${minutes > 9 ? minutes : `0${minutes}`}`;
};

export const floatToHHMMSS = (num: number) => {
  let hours = Math.floor(num);
  let minutes = Math.floor((num - hours) * 60);
  let seconds = Math.round((num - hours) * 3600 - minutes * 60);
  let timeString = '';
  if (hours > 0) {
    timeString += `${hours > 9 ? hours : `0${hours}`}h `;
  }
  timeString += `${minutes > 9 ? minutes : `0${minutes}`}m ${seconds > 9 ? seconds : `0${seconds}`}s`;
  return timeString;
};

export const getFloatTimeValue = (time: string): number => {
  const timeRef = time.split(':');
  const floatValue = Number(timeRef[0]) + Number(timeRef[1]) / 60;
  return floatValue;
};

export const getDateObjecttoDDMMM = (dateInput: any) => {
  let date = new Date(dateInput);
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const day = date.getDate();
  const monthIndex = date.getMonth();
  return `${day} ${monthNames[monthIndex]}`;
};

export const getDateObjecttoMMMDDYYYY = (dateInput: any) => {
  let date = new Date(dateInput);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const day = date.getDate();
  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  //current Date
  const currentDate = new Date();
  const curentDay = currentDate.getDate();
  const currentYear = currentDate.getFullYear();
  const currentMonthIndex = currentDate.getMonth();

  if (day === curentDay && year === currentYear && monthIndex === currentMonthIndex) return 'Today';
  return `${monthNames[monthIndex]} ${day}, ${year}`;
};

// Get Date Formate By month

export const getDateByThisMonth = () => {
  const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
  const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
  return `${startOfMonth}/${endOfMonth}`;
};

export const checkIsOverdue = (inputDateRef: Date) => {
  try {
    const inputDate = new Date(inputDateRef).getTime();
    const currentDate = new Date().getTime();
    const diffTime = Math.abs(inputDate - currentDate);
    if (diffTime >= 0) return false;
    else return true;
  } catch (e) {
    captureException(e);
  }
};

export const htmlToPlainText = (html: string) => {
  // Create a temporary DOM element
  let tempElement = document.createElement('div');

  // Set the HTML content of the temporary element
  tempElement.innerHTML = html;

  // Extract and return the text content
  return tempElement.textContent || tempElement.innerText || '';
};

export const getFileContent = (htmlSnippet: string) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlSnippet;
  const linkElement = tempElement.querySelectorAll('a');
  let content: any = tempElement.querySelectorAll('p');
  const extractedLinks: LinkObject[] = [];

  if (!content) {
    content = document.createElement('p');
    content.innerHTML = tempElement.innerHTML;
  }

  let newDesc: string = '';
  content.forEach((item: any) => {
    if (item?.getAttribute('class') !== 'p') newDesc = newDesc + item?.innerHTML;
  });

  linkElement.forEach((linkElement) => {
    const href = linkElement.getAttribute('href');
    if (href?.startsWith('https://firebasestorage') || href?.startsWith('/projects/details')) {
      const size = linkElement.getAttribute('id');
      const name = linkElement.getAttribute('name') || '';
      const parts = name?.split('.');
      const extension = parts[parts.length - 1];

      extractedLinks.push({
        href: href || '',
        name: name.replace(/\s+/g, '-'),
        size: size || '0 MB',
        file_type: extension
      });
    }
  });
  return { content: !isEmpty(newDesc) ? `<p>${newDesc}</p>` : '', uploadedFiles: extractedLinks };
};

export const generateRandomFilename = () => {
  let lastTimestamp = 0;
  let timestamp = Date.now();
  if (timestamp <= lastTimestamp) {
    timestamp = lastTimestamp + 1;
  }
  lastTimestamp = timestamp;
  return timestamp;
};

export const getFirebaseUrlFromFile = async (imageData: any, folderName: string, dispatch: Dispatch<AnyAction>) => {
  try {
    const promises = [];
    let profile;
    if (imageData) {
      const myPromise = new Promise(function (myResolve, myReject) {
        const storage = getStorage(app);
        const metadata = {
          contentType: imageData?.file?.type
        };
        const fileExtension = imageData?.file?.name.split('.').pop();
        const uniqueFilename = nanoid(8);
        const fullFilename = `${imageData?.file?.name.replace(/\.[^/.]+$/, '')}_${uniqueFilename}.${fileExtension}`;
        let pathName = '';
        if (folderName !== 'UserProfiles/') {
          const workspace = UserPreferenceSingleton.getInstance().getWorkspace();
          pathName = `workspaces/${workspace?.id}/${folderName}`;
        } else {
          pathName = folderName;
        }
        const storageRef = ref(storage, pathName + fullFilename);
        const uploadTask = uploadBytesResumable(storageRef, imageData?.file, metadata);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (dispatch) {
              dispatch(setUploadFilePercentage(progress));
            }
          },
          (error) => {
            if (dispatch) {
              dispatch(setUploadFilePercentage(0));
            }
            myReject(error);
            console.log('error', error);
            dispatch(setErrorMessage('Getting error for upload file'));
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              profile = downloadURL;
              myResolve(profile);
            });
          }
        );
      });
      promises.push(myPromise);
    }
    await Promise.all(promises);
    if (dispatch) {
      dispatch(setUploadFilePercentage(0));
    }
    return profile;
  } catch (e) {
    captureException(e);
    dispatch(setErrorMessage('Getting error for upload file'));
  }
};

// Remove file from firebase storage
export const removeFileFromFirebase = async (fileUrl: string, dispatch: Dispatch<AnyAction>) => {
  try {
    if (!fileUrl) return false;

    // Initialize Firebase storage
    const storage = getStorage();

    // Create a reference to the file to delete
    const fileRef = ref(storage, fileUrl);

    // Delete the file
    await deleteObject(fileRef);

    return true;
  } catch (error) {
    console.error('Error deleting file from Firebase Storage:', error);
    if (dispatch) {
      dispatch(setErrorMessage('Error deleting file from Firebase Storage.'));
    }
    return false;
  }
};

export const getFirebaseUrlsFromFiles = async (
  fileData: { file: File }[],
  folderName: string,
  dispatch: Dispatch<AnyAction>
): Promise<string[]> => {
  try {
    let totalBytesTransferred = 0;
    const totalBytes = fileData?.reduce((acc, fileObject) => acc + fileObject.file.size, 0);

    const promises = fileData?.map((fileObject) => {
      return new Promise<string | null>((resolve, reject) => {
        const storage = getStorage(app);
        const metadata = {
          contentType: fileObject?.file?.type
        };
        const fileExtension = fileObject?.file?.name.split('.').pop();
        const uniqueFilename = nanoid();
        const fullFilename = `${fileObject?.file?.name.replace(/\.[^/.]+$/, '')}_${uniqueFilename}.${fileExtension}`;

        let pathName = '';
        if (folderName !== 'UserProfiles/') {
          const workspace = UserPreferenceSingleton.getInstance().getWorkspace();
          pathName = `workspaces/${workspace?.id}/${folderName}`;
        } else {
          pathName = folderName;
        }

        const storageRef = ref(storage, pathName + fullFilename);
        const uploadTask = uploadBytesResumable(storageRef, fileObject?.file, metadata);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Calculate total bytes transferred so far
            totalBytesTransferred += snapshot.bytesTransferred - totalBytesTransferred;

            // Calculate overall upload progress as a percentage
            const progress = Math.round((totalBytesTransferred / totalBytes) * 100);

            // Dispatch progress to Redux or any other state management
            if (dispatch) {
              dispatch(setUploadFilePercentage(progress));
            }
          },
          (error) => {
            reject(error);
            dispatch(setErrorMessage('Error uploading file'));
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((downloadURL) => resolve(downloadURL)) // Resolve with just the URL
              .catch((error) => reject(error));
          }
        );
      });
    });

    // Wait for all uploads to finish and collect the results
    const uploadedUrls = await Promise.all(promises);

    if (dispatch) {
      dispatch(setUploadFilePercentage(100)); // Set to 100% after all uploads are complete
    }

    // Return the array of download URLs for all uploaded files
    return uploadedUrls.filter(Boolean) as string[];
  } catch (e) {
    captureException(e);
    dispatch(setErrorMessage('Error uploading files'));
    return [];
  } finally {
    if (dispatch) {
      dispatch(setUploadFilePercentage(0));
    }
  }
};

export const convertBase64ToFile = async (base64String: any, isScreenRecording?: boolean) => {
  if (base64String?.startsWith('blob:')) {
    base64String = await convertBlobUrlToDataUrl(base64String);
  }
  if (isEmpty(base64String)) return;
  const cleanedBase64String = base64String?.replace(/^data:(.*?;base64,)?/, '');
  // eslint-disable-next-line no-useless-escape
  let contentType = base64String?.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-+.]+)?;base64,(.+)$/)?.[1];
  const extension = contentType?.split('/')[1];
  const fileName = isScreenRecording ? `Screen_recording_${nanoid(8)}.mp4` : `file.${extension}`;

  try {
    const decodedString = atob(cleanedBase64String);
    const byteNumbers = new Array(decodedString.length);
    for (let i = 0; i < decodedString.length; i++) {
      byteNumbers[i] = decodedString.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });
    const file = new File([blob], fileName, { type: contentType });
    return file;
  } catch (error) {
    captureException(error);
    console.error('Error decoding base64 string: ', error);
  }
};

export const getInvoiceIcon = (type: number) => {
  switch (type) {
    case INVOICE_STATUS_TYPE.DRAFT:
      return DraftImage;
    case INVOICE_STATUS_TYPE.OVER_DUE:
      return OverDueImage;
    case INVOICE_STATUS_TYPE.PENDING:
      return PendingImage;
    case INVOICE_STATUS_TYPE.PARTIALLY_PAID:
      return PartiallyPaidImage;
    case INVOICE_STATUS_TYPE.PAID:
      return PaidImage;
    default:
      return;
  }
};

export const getIsDarkMode = () => {
  const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  return isDark;
};

export const calculateEndTime = (startTime: string, totalHours: number): string => {
  const startDate = new Date(startTime);
  const endDate = new Date(startDate.getTime() + totalHours * 60 * 60 * 1000);

  // Format the date as a string in the same format as the input
  const endTimeString = endDate.toISOString();

  return endTimeString;
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  // Format time as "7:58 pm"
  const time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

  // Format date as "Sep 29"
  const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

  return { time, formattedDate };
};

export const countDays = (dateString: string) => {
  const currentDate = moment();
  const targetDate = moment(dateString);
  const daysDifference = targetDate.diff(currentDate, 'days') + 1;
  return daysDifference;
};

export const fetchAndConvertToBase64 = async (url: string): Promise<string> => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject(new Error('Failed to read image as data URL.'));
      }
    };
    reader.onerror = () => {
      reject(new Error('Failed to read image as data URL.'));
    };
    reader.readAsDataURL(blob);
  });
};

export const convertBlobUrlToDataUrl = (blobUrl: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!blobUrl.startsWith('blob')) {
      // If it's not a Blob URL, resolve immediately with the original URL
      resolve(blobUrl);
    } else {
      // Create a new XMLHttpRequest
      const xhr = new XMLHttpRequest();
      // Set the responseType to 'blob'
      xhr.responseType = 'blob';
      // Define the onload callback function
      xhr.onload = function () {
        if (xhr.status === 200) {
          // Get the recovered Blob
          const recoveredBlob = xhr.response;

          // Create a new FileReader
          const reader = new FileReader();

          // Define the onload callback function for the FileReader
          reader.onload = function () {
            // Get the Blob as a data URL
            const blobAsDataUrl = reader.result as string;
            // Use the Blob as a regular URL
            resolve(blobAsDataUrl);
          };
          // Read the recovered Blob as a data URL
          reader.readAsDataURL(recoveredBlob);
        } else {
          // Reject the Promise if the request fails
          reject(new Error(`Failed to fetch Blob: ${xhr.status}`));
        }
      };
      // Open the XMLHttpRequest with the Blob URL
      xhr.open('GET', blobUrl);
      // Send the XMLHttpRequest
      xhr.send();
    }
  });
};

export const extensionName = (item: any) => {
  const filename = item?.name;
  const parts = filename?.split('.');
  const extension = parts?.length > 1 ? parts[parts?.length - 1] : '';
  return `.${isEmpty(extension) && !isEmpty(item?.file_type) ? item?.file_type : extension}`;
};

export const extensionResource = (item: any) => {
  const filename = item?.Label;
  const parts = filename?.split('.');
  const extension = parts?.length > 1 ? parts[parts?.length - 1] : '';
  return `.${isEmpty(extension) && !isEmpty(item?.FileType) ? item?.FileType : extension}`;
};

export const renderPrntImageFile = (imageUrl: string, imageName: string) => {
  return (
    <>
      <Printscreen>
        <div className='prnt-screen-box'>
          <div className='prnt-screen-top'>
            <div className='prnt-screen-header'>
              <img
                src={
                  'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2FLightShot.png?alt=media&token=05979875-3f7c-4836-8242-7b10a8a12cf1'
                }
                alt='Figma Image'
                width='20px'
                height='20px'
              />
              <div className='prnt-screen-text'>
                <a href={imageUrl} target='_blank' rel='noreferrer' className='prnt-url-link'>
                  {imageUrl}
                </a>
              </div>
            </div>
          </div>
          <div className='figma-image-container'>
            <RenderImagePreview imageUrl={imageName} altText={'prnt image'} />
          </div>
        </div>
      </Printscreen>
    </>
  );
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const categorizeFiles = (files: CommentFilesInterface[]) => {
  const categories: CategorizedFiles = {
    imageFiles: [],
    videoFiles: [],
    audioFiles: [],
    otherFiles: []
  };

  files?.forEach((file) => {
    const ext = extensionName(file).toLowerCase();

    if (imageExtensions.includes(ext)) {
      categories.imageFiles.push(file);
    } else if (videoExtensions.includes(ext)) {
      categories.videoFiles.push(file);
    } else if (audioExtensions.includes(ext)) {
      categories.audioFiles.push(file);
    } else {
      categories.otherFiles.push(file);
    }
  });

  return categories;
};

export const getFirebaseUniqueUserId = () => {
  const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
  const workspace = UserPreferenceSingleton.getInstance().getWorkspace();
  if (userDetails?.id && workspace?.id) {
    const uniqueUserId = workspace?.id + '_' + userDetails?.id;
    return { uniqueUserId, userId: userDetails?.id, workspaceId: workspace?.id };
  }
  return { uniqueUserId: '', userId: userDetails?.id, workspaceId: workspace?.id };
};

export const getCurrentDomain = () => {
  return window.location.hostname;
};

export const formatTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds) % 60;
  let timeString = '';
  if (hours > 0) {
    timeString += `${hours.toString().padStart(2, '0')}h `;
  }
  timeString += `${minutes.toString().padStart(2, '0')}m ${remainingSeconds.toString().padStart(2, '0')}s`;
  return timeString;
};

export const getColorWithOpacity = (color: string) => {
  const rgbValues = color.match(/[0-9a-f]{2}/gi);
  if (rgbValues && rgbValues.length === 3) {
    // Convert RGB values to rgba format with opacity 0.9
    return `rgba(${parseInt(rgbValues[0], 16)}, ${parseInt(rgbValues[1], 16)}, ${parseInt(rgbValues[2], 16)}, 0.9)`;
  } else {
    // If the color format is not supported or invalid, return null or original color
    return null;
  }
};

export const isMediaRecordingSupported = () => {
  return navigator.mediaDevices && typeof navigator.mediaDevices.getDisplayMedia === 'function';
};

export const calculateColor = (status: number) => {
  if (status >= 1 && status <= 4) return PROJECT_STATUS_COLOR[status];
  else return 'transparent'; // Default color
};

export const deleteFile = async (filePath: string): Promise<void> => {
  const storage = getStorage();
  const fileRef = ref(storage, filePath);

  try {
    await deleteObject(fileRef);
  } catch (error: any) {
    if (error.code === 'storage/object-not-found') {
      console.warn('File does not exist.');
    } else if (error.code === 'storage/unauthorized' || error.code === 'storage/permission-denied') {
      console.warn('User does not have permission to delete this file.');
    } else {
      console.warn('An unexpected error occurred while deleting the file.');
    }
  }
};

export const getMonthRange = (startDate: Date | string, endDate: Date | string) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const months = [];

  let current = new Date(start);
  current.setDate(1); // Set to the first day of the month

  while (current <= end) {
    const month = (current.getMonth() + 1).toString().padStart(2, '0');
    const year = current.getFullYear();
    months.push(`${month}-${year}`);
    current.setMonth(current.getMonth() + 1);
  }

  return months;
};

/**
 * Checks if a phone number is valid.
 *
 * @param phoneNumber - The phone number string to validate.
 * @returns A boolean indicating whether the phone number is valid.
 */
export const isPhoneNumberValid = (phoneNumber: string): boolean => {
  // Remove spaces, dashes, and parentheses
  const cleanedNumber = phoneNumber.replace(/\D/g, '');

  // Define a regular expression for exactly 10 digits (no extra characters allowed)
  const phoneRegex = /^\d{10}$/;

  // Check if the cleaned number matches the pattern
  return phoneRegex.test(cleanedNumber);
};

const convertBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      const item = {
        fileContent: fileReader.result,
        fileType: file?.type,
        name: file?.name,
        size: file?.size,
        file: file
      };
      resolve(item);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const handleFileRead = async (file: File, dispatch: Dispatch<AnyAction>) => {
  if (file?.size > 2.5e8) {
    dispatch(setErrorMessage('Please upload a file smaller than 250 MB.'));
    return false;
  }
  const newPromise = convertBase64(file);
  try {
    const value = await Promise.all([newPromise]);
    return value[0];
  } catch (error) {
    captureException(error);
    console.log('error', error);
    return null;
  }
};

export const handleFileUpload = async (
  uploadedFiles: CommentFilesInterface[],
  fileArray: File[],
  dispatch: Dispatch<AnyAction>,
  ref?: any,
  isVideoAsFile?: boolean
) => {
  // Validation: Remove duplicate files based on name and size
  const existingFileNames = new Set(uploadedFiles?.map((file) => file?.name));
  const uniqueFiles = fileArray?.filter((file) => !existingFileNames.has(file?.name));

  if (uniqueFiles?.length === 0) {
    dispatch(setErrorMessage('All selected files are already uploaded.'));
    return;
  }

  // Handle Files with `handleFileRead` for further processing
  const processedFiles = await Promise.all(
    uniqueFiles?.map(async (file) => {
      const base64 = await handleFileRead(file, dispatch);
      return base64 ? file : null; // Exclude invalid files
    })
  );

  const validFiles: File[] = processedFiles?.filter((file): file is File => !!file);

  if (validFiles?.length === 0) {
    dispatch(setErrorMessage('No valid files to upload.'));
    return;
  }

  // Map validFiles to the required format [{ file: File }]
  const fileData = validFiles?.map((file) => ({ file }));
  // Upload files and update task input
  const uploadedUrls = await getFirebaseUrlsFromFiles(fileData, 'files/', dispatch);

  // Process Uploaded File
  const updatedFiles = uploadedUrls
    ?.map((url: string, index: number) => {
      const file = validFiles[index];
      const sizeInMB = file?.size / (1024 * 1024);
      const sizeValue = sizeInMB >= 1 ? `${sizeInMB.toFixed(2)} MB` : `${(file?.size / 1024).toFixed(2)} KB`;

      if (['image/jpeg', 'image/jpg', 'image/png'].includes(file?.type) && ref) {
        ref?.current?.addImage(url);
      } else if (['video/mp4', 'video/mov', 'video/mkv', 'video/webm'].includes(file?.type) && !isVideoAsFile && ref) {
        ref?.current?.addRecordingCard(url, file?.name);
      } else {
        return {
          name: file?.name,
          href: url,
          size: sizeValue,
          file_type: file?.type.split('/')[1]
        };
      }
      return null; // No object to add for inline content types
    })
    .filter((fileObj) => fileObj !== null); // Remove null entries

  return updatedFiles;
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const isEmptyMessage = (htmlString: string): boolean => {
  const strippedContent = htmlString.replace(/<\/?[^>]+(>|$)/g, '').trim();
  const hasImage = /<img\b[^>]*>/i.test(htmlString);
  return strippedContent.length === 0 && !hasImage;
};

export const renderStatusIconName = (type: number) => {
  switch (type) {
    case STATUS_TYPE.BACKLOG:
      return 'task-status-icon';
    case STATUS_TYPE.UNSTARTED:
      return 'task-todo-icon';
    case STATUS_TYPE.IN_PROGRESS:
      return 'task-inprogress-icon';
    case STATUS_TYPE.IN_REVIEW:
      return 'task-inreview-icon';
    case STATUS_TYPE.COMPLETED:
      return 'task-completed-icon';
    case STATUS_TYPE.CANCELLED:
      return 'task-cancelled-icon';
    default:
      return undefined;
  }
};

// Function to get text as per date selection range
export const getTextForDateRange = (startDate: string, endDate: string): string => {
  // Parse dates once to avoid repeated parsing
  const start = moment(startDate);
  const end = moment(endDate);

  // Pre-calculate commonly used values
  const today = moment();
  const yesterday = moment().subtract(1, 'day');
  const startOfThisWeek = today.clone().startOf('week');
  const startOfLastWeek = today.clone().subtract(1, 'week').startOf('week');
  const endOfLastWeek = today.clone().subtract(1, 'week').endOf('week');
  const startOfThisMonth = today.clone().startOf('month');
  const startOfLastMonth = today.clone().subtract(1, 'month').startOf('month');
  const endOfLastMonth = today.clone().subtract(1, 'month').endOf('month');
  const startOfLast7Days = today.clone().subtract(7, 'days').startOf('day');
  const startOfLast30Days = today.clone().subtract(30, 'days').startOf('day');

  // Map of conditions to labels
  const conditions: { label: string; check: boolean }[] = [
    { label: 'Today', check: start.isSame(today, 'day') && end.isSame(today, 'day') },
    { label: 'Yesterday', check: start.isSame(yesterday, 'day') && end.isSame(yesterday, 'day') },
    { label: 'This Week', check: start.isSame(startOfThisWeek, 'day') && end.isSame(today, 'day') },
    { label: 'Last Week', check: start.isSame(startOfLastWeek, 'day') && end.isSame(endOfLastWeek, 'day') },
    { label: 'Last 7 Days', check: start.isSame(startOfLast7Days, 'day') && end.isSame(today, 'day') },
    { label: 'This Month', check: start.isSame(startOfThisMonth, 'day') && end.isSame(today, 'day') },
    { label: 'Last Month', check: start.isSame(startOfLastMonth, 'day') && end.isSame(endOfLastMonth, 'day') },
    { label: 'Last 30 Days', check: start.isSame(startOfLast30Days, 'day') && end.isSame(today, 'day') }
  ];

  // Find the first matching condition
  const matchedCondition = conditions.find((condition) => condition.check);
  return matchedCondition ? matchedCondition.label : 'Custom';
};

export const getObjectFromArray = (array: any[], key: string) =>
  array.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});

export const getUserObject = (type: string): UsersWorkspaceInterface | undefined => {
  switch (type) {
    case 'sentry':
      return {
        avatar: {
          avatar: '',
          avatar_120: ''
        },
        email: 'sentry@gmail.com',
        id: 'sentry',
        name: 'Sentry',
        phone: '',
        profile_photo: '',
        isAdmin: false,
        isOwner: false
      };
    default:
      return undefined;
  }
};
